.keytakeaways {
    margin: 50px 100px;
    h3 {
        margin-bottom: 15px;
    }
    ul {
        @include list-reset;
        li {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 20px;
            &:before {
                content: url('../../Images/Base/dist/chevron.svg');
                margin-right: 10px;
            }
            .takeaway-wrap {
                span {
                    display: block;
                }
            }
        }
    }
}

@import "keytakeaway-mobile";