.membership-application-wrapper {
    .active-license-container {
        .button {
            @include primary-button;
            display: inline-block;
        }

        .alt-button {
            @include secondary-button;
            display: inline-block;
            margin: 10px 0;
        }

        .license-required {
            margin: 10px 0 0 0;
            color: red;
        }
    }

    .board-credentials-container {
        label.memberjoin {
            display: inline;
        }
    }
}
