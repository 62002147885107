.taxonomy-list {
    list-style: none;
    margin: 0;
    padding: 0;
    column-count: 4;
    column-gap: 40px;
    margin: 10px 0;
    li {
        background-color: $white;
        padding: 10px 30px 10px 15px;
        margin: 5px 0;
        display: inline-block;
        width: 100%;
        line-height: 1.2;
        font-weight: $bold;
        letter-spacing: 1.4px;
        text-transform: uppercase;
    }
}

.taxonomy-list-form {
    list-style: none;
    margin: 0;
    padding: 0;
    column-count: 3;
    column-gap: 40px;
    margin-top: 1em !important;

    li {
        display: inline-block;
        width: 100%;

        .field-wrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            input[type="checkbox"] {
                margin: 0 10px 0 0;
            }

            label {
                font-weight: $regular;
            }

            margin-bottom: 5px !important; // *Todo
        }
    }

    &.fop {
        margin-bottom: 0;
    }

    &.jobRole {
        margin-bottom: 0;
    }

    &.jobFunction {
        margin-bottom: 0;
    }

    &.employmentStatus {
        margin-bottom: 0;
    }
}