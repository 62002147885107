@media (max-width: 1399px) {
    .more-menu {
        font-size: 17px;
        .collapsible-toggle:after {
            top: 3px;
        }
    }
    
    .header-layout__logo {
        max-width: 12rem;
    }

}

@media (max-width: 991px) {
    .more-menu {
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    .more-menu {
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        background: #0a1980;
        margin: 33px -15px 0;
        padding: 12px 15px;
        box-sizing: content-box;
        z-index: 9;
        font-size: 1.35rem;
        line-height: 2.5rem;
    }

    .more-menu .collapsible-toggle {
        display: none;
    }

    .more-menu .collapsible-panel {
        position: static;
        width: 100%;
        padding: 0;
        display: block !important;
        font-size: 1.35rem;
        line-height: 2.5rem;
    }

    .more-menu .collapsible-panel li {
        margin-bottom: 0;
        padding: 0 15px;
    }

    .more-menu__item {
        margin: 0;
        padding: 0 15px;
    }

    .header-layout {
        position: relative;
    }
}
