.splash-menu {
    height: 74px;
    z-index: 4;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $light-grey;
    margin: 0 auto;

    .container {
        max-width: unset;
        border-bottom: 2px solid $blue;
    }

    .content {
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 768px) {
            max-width: 768px;
        }

        @media (min-width: 1024px) {
            max-width: 1024px;
        }

        @media (min-width: 1190px) {
            max-width: 1200px;
        }

        @media (min-width: 1400px) {
            max-width: 1440px;
        }
    }

    ul {
        @include list-reset;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        width: auto;
        height: 74px;

        li {
            margin-right: 2px;

            a, a:visited {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 74px;
                padding: 0 20px;
                background-color: transparent;
                font-size: 18px;
                letter-spacing: 0.5px;
                line-height: 1.2;
                color: $black;
                font-weight: $bold;

                &:after {
                    content: url('../../Images/Base/dist/icon-arrow-right.svg');
                    transition: all 200ms ease-in-out;
                    padding-left: 20px;
                    position: relative;
                    top: 1px;
                    right: 5px;
                }

                &:hover, &:focus {
                    text-decoration: none;
                    background-color: $light-purple;

                    &:after {
                        position: relative;
                        top: 1px;
                        right: -5px;
                    }
                }
            }
        }
    }

    &.stick {
        position: fixed;
        top: 50px;
    }
}

@import "featuredlinks-mobile";