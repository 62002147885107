.newsarticle {
    .ad-slot {
        width: auto;
        float: right;

        .iframe-wrap {
            margin: 15px;
        }
    }

    .category {
        display: block;
        font-size: 14px;
        margin-bottom: 20px;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        font-weight: $bold;
        color: $blue;
    }

    h1 {
        margin-bottom: 40px;
    }

    .titles {
        margin-bottom: 40px;

        h1 {
            margin-bottom: 5px;
        }

        p {
            &.subtitle {
                font-weight: 700;
                font-size: 18px;
                line-height: 32px;
            }
        }
    }

    .date {
        display: block;
        font-weight: $light;
        margin-bottom: 40px;
    }

    p {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .boilerplate {
        margin-top: 80px;

        h4 {
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 15px;
        }
    }

    .tags {
        @include list-reset;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 50px 0;
        flex-wrap: wrap;

        li {
            // background-color: $blue;
            // padding: 5px 10px;
            // color: $white;
            color: $blue;
            text-transform: uppercase;
            // font-size: 12px;
            font-size: 14px;
            font-weight: $bold;
            letter-spacing: 1.4px;
            // margin-right: 20px;
            margin-right: 30px;
            margin-bottom: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@import "newsarticle-mobile";