/* ================================================================
   marketingS
   ================================================================ */

.marketing {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a:visited {
        color: $black;
    }

    .marketing__media {
        position: relative;
        background-color: #333333;
        display: inline-block;
    }

    .marketing__button {
        transition: all 200ms ease-in-out;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: $bold;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        color: $black;
        border: 1px solid $light-yellow;
        background-color: $light-yellow;
        padding: 10px 15px;
        max-width: 100%;
    }

    &:hover .marketing__button {
        text-decoration: none;
        background-color: $yellow;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    }

    &:active .marketing__button {
        text-decoration: none;
        background-color: $yellow;
        box-shadow: none;
        outline: none;
    }

    .marketing__headline {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #FFF;
        width: 100%;
    }

    .marketing__content {
        position: absolute;
        top: 0;
        left: 0;
        color: #FFF;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        width: 100%;
        height: 100%;
    }


    .marketing__media-bg {
        opacity: 0.4;
    }


    @media (min-width: 60em) {
        .marketing {
            display: -ms-flexbox;
            display: flex;
        }
    }
    /* Vertical Alignment marketing */
    .vertical-alignment .marketing__media {
        max-width: 100%;
    }

    @media (min-width: 60em) {
        .vertical-alignment.marketing {
            display: block;
        }

        .vertical-alignment .marketing__content {
            width: 100%;
        }
    }

    @include max-screen(sm) {
        .marketing__headline {
            font-size: 16px;
            line-height: normal;
        }

        .marketing__button {
            font-size: 12px;
        }
    }
}
