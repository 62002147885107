// BREAKPOINTS (Media Queries) =============================================== *
$breakpoints: (
  // Matched to Bootstrap $grid-breakpoints variables -------- *
  // Extra small screen / phone
  xs: 640px,
  // Small screen / phone
  sm: 768px,
  // Medium screen / tablet
  md: 1024px,
  // Medium screen / bootstrap breakpoint
  bootstrap-md: 1023px,
  // Large screen / desktop
  lg: 1190px,
  // Extra large screen / wide desktop
  xl: 1400px
);


// COLORS =============================================== *

// Base -----------
$black: #000000;
$light-grey: #EDECEC;
$lighter-grey: #F8F8F8;
$grey: #979797;
$dark-grey: #4C4B4B;
$blue: #1226AA;
$dark-blue: darken($blue, 10%);
$purple: #8A84D6;
$light-purple: #B6B8DD;
$ivory: #F4F4F4;
$white: #FFFFFF;
$red: #ce0f0f;

// Secondary -----------
$pale-blue: #007CBA;
$cyan: #00ABC8;
$light-cyan: #99D9D9;
$orange: #F18A00;
$yellow: #F3C300;
$light-yellow: #F8DF8D;

// Sponsored -----------
$light-green: #D4E6AB; // Black Text
$middle-green: #B9DA7F; // Black Text
$bright-green: #096633; // White Text

// Brands
$facebook-blue: #3B5998;
$linked-in-blue: #0077B5;
$twitter-blue: #1DA1F2;
$twitter-X-black: #000000;
$icon-grey: #3F3F3F;

// TYPOGRAPHY =============================================== *
// Font Family ----------
$lato: 'Lato', sans-serif;

// Font Weight ----------
$light: 300;
$regular: 400;
$bold: 700;
$extra-bold: 900;