.product-box {
    background-color: $white;
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    border: {
        top: 1px solid $light-grey;
        right: 1px solid $light-grey;
        bottom: 2px solid $light-purple;
        left: 1px solid $light-grey;
    }

    transition: all 200ms ease-in-out;

    &:hover, &:focus {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }

    a.product-image, a.product-image:visited {
        display: block;
        position: relative;
        height: 230px;

        img {
            width: 100%;
            transition: all 200ms ease-in-out;
        }

        .quickview {
            display: none;
        }

        &:hover, &:focus {
            img {
                width: 100%;
                opacity: 0.2;
            }

            .quickview {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: $bold;
                letter-spacing: 1.4px;
                color: $black;
                text-transform: uppercase;

                &:before {
                    content: url('../../Images/Base/dist/icon-quickview.svg');
                    margin-right: 10px;
                    position: relative;
                    top: 2px;
                    display: inline-block;
                    width: 30px;
                    height: 35px;
                }
            }
        }
    }

    a, a:visited {
        display: block;

        h4 {
            margin: 20px 0;
            line-height: 1.3;
            transition: all 200ms ease-in-out;
        }

        &:hover, &:focus {
            text-decoration: none;

            h4 {
                color: $blue;
            }
        }
    }

    .cost-ribbon {
        position: relative;
        left: 0;
        right: 0;
        background-color: $ivory;
        padding: 10px 30px;
        margin: 0 -30px;
        margin-top: auto;

        table {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            font-size: 14px;
            font-weight: $bold;
            letter-spacing: 1.4px;
            text-transform: uppercase;

            td {
                padding-right: 20px;

                &.on-sale {
                    text-decoration: line-through;
                }

                &.sale-price {
                    color: $red;
                }
            }
        }
    }

    .designations {
        margin: 20px 0 0 0;

        ul {
            @include list-reset;
            display: flex;
            flex-wrap: wrap;

            li {
                font-size: 10px;
                font-weight: $bold;
                letter-spacing: 1.4px;

                &.time-to-complete {
                    text-transform: uppercase;
                }

                &.claim-credits-by {
                    text-transform: uppercase;
                }

                &:after {
                    content: ",";
                    margin-right: 5px;
                }

                &:last-child {
                    &:after {
                        content: "";
                    }
                }
            }
        }
    }
}

@import "productbox-mobile";