/* Tools and Resources page */
/*Thumbnail*/
.thumbnail {
    display: block;
    padding: 4px;
    margin-bottom: 20px;
    line-height: 1.42858;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out
}

.thumbnail > img, .thumbnail a > img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto
}

.thumbnail .caption {
    padding: 9px;
    color: #333
}

a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active {
    border-color: #0785a4
}

.hover-zoom:hover img {
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

/*Well*/
.well-lg {
    margin: 0 0 40px 0;
    border-radius: 0;
    border-radius: 0;

    .thumbnail {
        color: #00447b;
        padding: 15px 4px;
        border: solid 2px #72cdf4;
        border-radius: 0;


        .h6 {
            padding-right: 10px;
        }

        .h6:after {
            content: ">";
            padding-left: 6px;
        }

        img {
            margin: 15px auto 20px auto;
            max-height: 114px;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

@media (max-width:767px) {
    .well-lg {
        .row {
            margin: 0;
        }
    }
}

@media (min-width:768px) {
    .well-lg {
        .row {
            margin: 0 -1%;

            .col-sm-4.thumbnail {
                -ms-flex: 0 0 31.33333%;
                flex: 0 0 31.33333%;
                max-width: 31.33333%;
                margin: 0 1% 15px;
                min-height: 232px;

                .thumbnail__img-container {
                    min-height: 150px;
                }
            }
        }
    }
}

@media (min-width:992px) {
    .well-lg {
        .row {
            h2 {
                font-size: 40px;
            }

            .col-sm-4.thumbnail {
                margin: 15px 1%;
            }
        }
    }
}

@media (min-width:1200px) {
    .well-lg {
        .row {
            h2 {
                font-size: 40px;
            }

            .col-sm-4.thumbnail {
                margin: 15px 1%;
                min-height: 212px;
            }
        }
    }
}
