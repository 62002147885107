@include max-screen(xl) {
    .bottomcallout {
        .articlebox-image {
            height: 240px;
        }
    }
}

@include max-screen(md) {
    .bottomcallout {
        .articlebox-image {
            margin-bottom: 30px;
        }
        .articlebox-content-wrap {
            .articlebox-content {
                padding: 0 30px 30px 30px;
                .subhead {
                    margin-bottom: 10px;
                }
                .text-wrap {
                    h3 {
                        margin-bottom: 15px;
                    }
                    p {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

@include max-screen(sm) {
    .bottomcallout {
        .articlebox-image {
            height: auto;
        }
    }
}