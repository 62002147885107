.coupon-desc {
        max-width: 5%;
    }

.descriptionmodal {

    .modal-header {
        border-bottom: none;
        padding: 1rem 1rem !important;

        .close {
            padding: 1rem 1rem !important;
        }
    }

    .modal-body {
        padding: 10px 50px;
        position: relative;

        .alert-message {
            display: block;
            text-align: left;
            line-height: 16px;
            letter-spacing: 1.4px;
            margin-bottom: 10px;
        }

        h3 {
            margin-bottom: 15px;
        }

        p {
            text-align: left;
            line-height: 1.3;
            font-size: 16px;
            margin-bottom: 10px;
        }

        .modal-footer {
            border-top: none;
            display: flex;
            justify-content: center;
            margin-bottom: 50px;

            .cta {
                &.cancel {
                    font-size: 16px;
                    padding: 10px 15px;
                    border: 1px solid #4C4B4B;
                    background-color: white;
                }
            }
        }
    }
}
