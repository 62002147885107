.promo-display {
    background-color: #e4590c;
    padding: 10px 0;
    top: inherit;
    left: 0;
    right: 0;
    z-index: 99;

    .promo-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;

        a {
            color: $white;
            border-bottom: 2px solid $white;
            padding: 0 2px 2px 2px;
            font-size: 16px;
            line-height: 22px !important;

            &:hover {
                color: $black;
                background-color: $white;
                text-decoration: none;
            }

            &:last-child {
                border: none;
                outline: none;
                background-color: transparent;
                cursor: pointer;
                color: $white;
                padding: inherit;

                :hover {
                    border-bottom: none;
                    background-color: #e4590c;
                    text-decoration: none;
                }
            }
        }

        span {
            color: $white;
            padding: 1px 6px;
        }

        button.close {
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            color: $white;
        }
    }
}

@import "promotions-mobile";