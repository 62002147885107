@include max-screen(md) {
    .thankyou {
        margin-top: 30px;
        .sidebar {
            margin-top: 20px;
            h4 {
                margin-bottom: 20px;
            }
        }
        .order-summary {
            .item, .total {
                .cost {
                    span {
                        justify-content: flex-start;
                    }
                }
            }
            // .payment-info {
            //     span {
            //         &.line2 {
            //             margin-bottom: 50px;
            //         }
            //     }
            // }
        }
    }
}