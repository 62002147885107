.mega-menu {
    .rfl_link {
        display: none;
    }
}

@include max-screen(lg) {
    .reshape-splash-menu {
        position: relative;
    }
}

@include max-screen(md) {
    .reshape-splash-menu {
        display: none;
    }

    .mega-menu {
        .rfl_link {
            display: block;
        }
    }
}

@include max-screen(md) {
    .mega-menu {
        .rfl_link {
            a, a:visited {
                border-top: 1px solid #979797;
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                padding: 1rem 2rem;
                background-color: transparent;
                font-size: 16px;
                line-height: 1.2;
                color: $black;
                font-weight: $bold;

                &:after {
                    content: url('../../Images/Base/dist/icon-arrow-right.svg');
                    transition: all 200ms ease-in-out;
                    padding-left: 15px;
                    position: relative;
                    top: 1px;
                    right: 5px;
                }

                &:hover, &:focus {
                    text-decoration: none;

                    &:after {
                        position: relative;
                        top: 1px;
                        right: -5px;
                    }
                }
            }

            &:last-child {
                a {
                    padding-bottom: 50px;
                }
            }
        }
    }
}