﻿.general-article .committee-roster-links,
.committee-roster-links {
    padding-top: 30px;

    h4 {
        color: #00447b;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        color: #4C4B4B;

        li {
            margin-bottom: 2px;
        }
    }

    ul.committee-chair-list {
        margin-bottom: 20px;
        color: #000;
    }
}

.general-article .committee-links,
.committee-links {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        color: #4C4B4B;

        li {
            margin-bottom: 2px;
        }
    }
}