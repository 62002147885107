.tweetableexcerpt {
    margin: 20px 0 50px 0;
    a, a:visited {
        @include link;
        font-size: 18px;
        font-weight: $bold;
        &:after {
            content: url('../../Images/Base/dist/icon-sm-twitter.svg');
            margin-left: 10px;
        }
    }
}