/* -----------------------------------------------------------------------------
Import BASE
----------------------------------------------------------------------------- */
@import "variables";
@import "mixins";

html {
    scroll-padding-top: 157px;
    @include max-screen(md) {
        scroll-padding-top: 120px;
    }
}

body {
    font: {
        family: $lato;
        size: 16px;
    }
    color: $black;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    @include max-screen(sm) {
        size: 18px;
    }    
}

a.skipper-anchor {
    display: none !important; //needed to hide unwanted whitespace in Firefox
}

// PARAGRAPHS & LISTS =============================================== *

p, ul, ol, table { 
	font: {
        family: $lato;
        weight: $regular;
        size: 16px;
    }
    @include max-screen(sm) {
        font-size: 18px;
    }
}

p {
    color: $dark-grey;
    line-height: 26px;
    a, a:visited {
        @include link;
        color: $dark-grey;
    }
    margin-bottom: 40px;
    &.subhead {
        font-size: 20px;
        font-weight: $light;
        line-height: 1.5;
        margin: 25px 0 30px 0;
        width: 75%;
    }
}

ul {
    color: $dark-grey;
}

span.subhead {
    font-size: 20px;
    font-weight: $light;
    line-height: 1.5;
    margin: 25px 0 30px 0;
    width: 75%;
}

p.small {
    color: $dark-grey;
    font-size: 14px;
    line-height: 24px;
}


// HEADLINES =============================================== *

h1, h2, h3, h4 {
    font: {
        family: $lato;
    }
    color: $black;
}

h1 {
    font: {
        weight: $light;
        size: 42px;
    }
    line-height: 45px;
    letter-spacing: 0.35px;
    @include max-screen(sm) {
        font-size: 36px;
        line-height: 39px;
    }
}

h2 {
    font: {
        weight: $light;
        size: 36px;
    }
    line-height: 39px;
    letter-spacing: 0.3px;
    @include max-screen(sm) {
        font-size: 32px;
        line-height: 35px;
    }
}

h3 {
    font: {
        weight: $bold;
        size: 24px;
    }
    line-height: 36px;
    @include max-screen(sm) {
        font-size: 24px;
        line-height: 36px;
    }
}

h4 {
    font: {
        weight: $bold;
        size: 18px;
    }
    line-height: 32px;
    @include max-screen(sm) {
        font-size: 20px;
        line-height: 24px;
    }
}

// LINKS =============================================== *

a, a:visited {
    transition: all 200ms ease-in-out;
    color: $black;
}

a:empty {
    visibility: hidden;
    display: inline-block;
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    border-width: 0px;
}


// FORM =============================================== *

label {
    display: block;
    margin: 5px 0;
    font-size: 16px;
    line-height: 1.2;
    margin-right: 20px;
}
input, input[type="text"], input[type="tel"], input[type="date"], textarea, input[type="password"], select {
    border: 1px solid rgba($color: $light-grey, $alpha: 0.5); // Validation Border Placeholder
    background-color: rgba($color: $light-grey, $alpha: 0.5);
    padding: 10px;

    font: {
        size: 14px;
        weight: $regular;
    }

    color: $dark-grey;

    &:focus {
        outline: 1px solid $blue;
        color: $blue;
    }
}
input[type="checkbox"],
input[type="radio"] {
    margin: 0 10px 0 0;
    height: auto;

    &:focus {
        outline: none;
    }
}