.lds-dual-ring {
    display: inline-block;
    width: 160px;
    height: 160px;
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-20%, -50%);
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 128px;
    height: 128px;
    margin: 16px;
    border-radius: 50%;
    border: 8px solid #000000;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.NPIcheckmark{
    margin-left: 15px;
    height: 24px;
    width: 24px;
    border: 2px solid #008000;
    border-radius: 50%;
    padding: 2px;
}