.alert-display {
    background-color: $blue;
    padding: 10px 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    .container {
        .row {
            border-bottom: 1px solid white;
            padding-bottom: 5px;
            margin-bottom: 5px;
        }

        div:last-child {
            border-bottom: none;
            margin-bottom: 0px;
            padding-bottom: 0px;
        }
    }

    .alert-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;


        a {
            color: $white;
            border-bottom: 2px solid $white;
            padding: 0 2px 2px 2px;

            &:hover {
                color: $blue;
                background-color: $white;
                text-decoration: none;
            }
        }

        span {
            color: $white;
        }

        button.close {
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            color: $white;
        }
    }
}
