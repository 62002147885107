@include max-screen(xl) {
    .listing {
        .articlebox-image {
            height: 212px;
            overflow: hidden;
        }
    }
}
@include max-screen(lg) {
    .listing {
        .articlebox-image {
            height: 178px;
            overflow: hidden;
        }
    }
}
@include max-screen(md) {
    .listing {
        .articlebox-image {
            margin-bottom: 30px;
            height: auto;
            overflow: auto;

            .left-side {
                border-bottom: 90px solid $white;
            }

            .right-side {
                border-right: 30vw solid transparent;
                border-bottom: 90px solid #fff;
            }
        }

        .articlebox-content {
            min-height: 0;
            margin-bottom: 60px;

            .subhead {
                height: auto;
            }

            .cta {
            }
        }
    }
}

@include max-screen(sm) {
    .listing .articlebox-image {
        .left-side {
            border-bottom: 50px solid $white;
        }

        .right-side {
            border-right: 25vw solid transparent;
            border-bottom: 50px solid #fff;
        }
    }
}

