@include max-screen(md) {
    .newsarticle {
        margin-top: 50px;

        .ad-slot {
            float: none;

            .iframe-wrap {
                margin: 0 0 20px 0;
            }
        }
    }
}