.hide-sorts .search-frame .CoveoSort.coveo-selected {
    display: none;
}

.filter-count-label {
    margin: 0 0 auto auto;
}

.sort-selection-label {
    margin: 0 0 auto auto;
}

.search-frame {
    margin-top: 20px;

    .search-bar {
        .CoveoSearchbox {
            background-color: rgba($color: $ivory, $alpha: 0.5);
        }
    }

    .search-bottom-controls {
        border-top: 1px solid $light-grey;
        display: flex;
        justify-content: space-between;

        .CoveoQuerySummary {
            margin-top: 27px;

            .coveo-query-summary-no-results-string,
            .coveo-query-summary-cancel-last,
            .coveo-query-summary-search-tips-info,
            ul {
                display: none;
            }
        }
    }

    .search-top-controls {
        clear: both;
    }

    .search-facets-container {
        width: 100%;

        .search-facets {
            margin-top: 50px;
            display: flex;
        }
    }

    .search-sorts {
        display: block;

        .coveo-sort-container {
            display: block;
        }

        .coveo-sort-container + .coveo-sort-container {
            margin-left: 10px;
        }
    }

    .CoveoSearchInterface {
        // Facets Section Container
        .coveo-facet-column {
            width: 100%;
            margin: 0;
            padding: 0;
            float: none;
        }
    }
    // Facets Sort Container
    .search-sort-section {
        display: block;
        text-align: left;
    }

    .search-sort-label {
        margin: 25px 0 8px;
        font-size: 16px;
    }

    .search-sort-buttons {
        &.desktop {
            display: flex;
        }
    }

    .CoveoSort {
        /*display: flex;
        align-items: center;
        box-shadow: none;
        position: relative;
        z-index: 1;
        display: inline-block;
        margin-left: 10px;*/
        @include secondary-button;

        &.coveo-selected {
            @include primary-button;
        }
    }
    // Accordion
    .CoveoFacet, .CoveoFacetRange { // .accordionItem
        border: none;
        margin: 0 30px 0 0;
        min-width: 162px;
        flex-grow: 1;
        display: block;
        max-width: 250px;

        &.coveo-facet-empty {
            display: none;
        }

        &.open, &.close {
            margin: 0 30px 0 0;
            border: none;
        }

        .coveo-facet-header { // .accordionItemHeading
            display: flex;
            align-items: center;
            background-color: $white;
            padding: 10px 0;
            width: 100%;

            border: {
                top: 1px solid transparent;
                right: 1px solid transparent;
                bottom: 1px solid $dark-grey;
                left: 1px solid transparent;
            }

            outline: none;
            transition: 200ms;
            opacity: 1;
            cursor: pointer;

            &:after {
                content: url('../../Images/Base/dist/icon-caret-black.svg');
                margin-left: 10px;
                position: relative;
                display: inline-block;
                width: 9px;
                height: 22px;
            }

            .coveo-facet-header-title-section {
                .coveo-facet-header-title {
                    font-size: 18px;
                    color: $black;
                    font-weight: $bold;
                    width: 100%;
                }
            }
        }
        // Coveo Overrides
        .coveo-facet-header-settings-section {
            display: none;
        }

        .coveo-facet-footer {
            display: none;
        }
    }

    .CoveoFacet, .CoveoFacetRange, .close {
        //float: none;
        &.CoveoFacet, &.CoveoFacetRange { // .accordionItem
            opacity: 1;
        }

        .coveo-facet-values {
            position: absolute;
            height: auto;
            z-index: 10;
            transition: height 1s ease-in-out;
            transform: scaleY(0);
            display: block;
            border: 1px solid $grey; // placeholder to avoid shift on click
            width: 100%;
            min-width: 230px;
        }
        // Coveo Overrides
        ul.coveo-facet-values {
            list-style: none;
        }

        .coveo-facet-search {
            display: none;
        }
    }

    .open {
        &.CoveoFacet, &.CoveoFacetRange { // .accordionItem
            opacity: 1;

            .coveo-facet-header { // .accordionItemHeading
                &:after {
                    content: url('../../Images/Base/dist/icon-caret-black.svg');
                    transform: rotate(180deg);
                    position: relative;
                    top: 5px;
                    display: inline-block;
                    width: 9px;
                    height: 22px;
                }
            }
        }

        .coveo-facet-values { // .accordionItemContent
            transform: scaleY(1);
            transform-origin: top;
            transition: transform 200ms ease-in-out;
            list-style: none;
            background-color: $white;
            border: 1px solid $grey;
            padding: 0;
            width: 100%;
            margin-top: -2px;
            max-height: 400px;
            overflow-y: auto;

            li.coveo-facet-search-button {
                display: none;
            }

            li.coveo-facet-value {
                font-size: 16px;

                &:hover {
                    background-color: $light-grey;
                }
            }

            li.coveo-facet-selectable {
                padding: 0;
                border-bottom: 1px solid $grey;

                &:last-child {
                    border-bottom: none;
                }

                .coveo-facet-value-label {
                    margin: 0;
                    padding: 15px 10px;

                    .coveo-facet-value-label-wrapper {
                        display: flex;
                        //flex-direction: row-reverse;
                        // Checkbox
                        .coveo-facet-value-checkbox {
                            order: 1;
                            min-width: 14px;
                            width: 14px;
                            height: 14px;
                            margin-right: 5px;

                            svg.coveo-facet-value-checkbox-svg {
                                width: 14px;
                                height: 14px;
                            }
                        }
                        // Item Count
                        .coveo-facet-value-count {
                            color: $blue;
                            font-weight: $bold;
                            font-size: 14px;
                            position: relative;
                            top: 2px;
                            order: 3;
                            margin: 0;
                            margin-left: auto;
                        }
                        // Item Name
                        .coveo-facet-value-caption {
                            order: 2;
                            margin-right: 10px;
                            white-space: normal;
                        }
                    }
                }
            }
        }

        .coveo-facet-search, .coveo-facet-footer {
            display: none;
        }
    }
    // Summary Text
    .CoveoQuerySummary {
        color: $black;
        margin: 25px 0;
        display: block;
        clear: both;
    }
    // Results Container
    .CoveoResultList {
        margin: 0;
    }
    // Breadcrumb (filter) Items
    .CoveoBreadcrumb {
        width: 100%;
        border-bottom: none;
        padding: 0;

        .coveo-breadcrumb-items {
            padding: 0;
            margin-top: 20px;
            -webkit-flex-grow: 0;
            flex-grow: 0;
            -moz-flex-grow: 0;
            -ms-flex-positive: 0;

            &:nth-child(even) {
                display: none;
            }

            .coveo-facet-breadcrumb.coveo-breadcrumb-item {
                margin: 0;
                display: inline;


                .coveo-facet-breadcrumb-title {
                    display: none;
                }

                .coveo-facet-breadcrumb-values {

                    .coveo-facet-breadcrumb-value.coveo-selected {
                        background-color: $light-yellow;
                        text-transform: uppercase;
                        letter-spacing: 0.5px;
                        font-size: 14px;
                        font-weight: $bold;
                        padding: 10px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        display: inline-block;

                        .coveo-facet-breadcrumb-caption {
                            color: $black;
                        }

                        .coveo-exclusion-svg {
                            fill: $black;

                            &:hover, &:focus {
                            }
                        }

                        &:hover, &:focus {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .coveo-breadcrumb-clear-all {
            background-color: $white;
            border: 1px solid $black;
            border-radius: 0;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-size: 14px;
            font-weight: $bold;
            padding: 10px;
            margin-top: 20px;

            .coveo-icon.coveo-breadcrumb-clear-all-icon {
                display: none; //hide the X svg icon

                .coveo-breadcrumb-clear-all-svg {
                    .coveo-exclusion-svg {
                        fill: $black;

                        &:hover, &:focus {
                        }
                    }
                }
            }

            div {
                color: $black;
            }
        }
    }
    // Coveo Result List (Overrides)
    .list-view & {
        .coveo-list-layout.CoveoResult {
            border-bottom: 0;
            padding: 0;
        }
    }

    .grid-view & {
        .CoveoResult {
            padding: 0 15px 30px;
            border-bottom: 0;
        }
    }
    // Coveo Pager (Overrides)
    .CoveoPager {
        display: flex;
        align-items: center;
        margin: 0 0 100px 0;
        padding-top: 20px;

        .coveo-pager-list {
            .coveo-pager-list-item {
                border: none;
                border-radius: 0;
                margin: 0;
                padding: 5px 10px;
                color: $black;
                font-size: 16px;

                &:hover, &:focus {
                    background-color: $white;

                    a, a:visited {
                        color: $blue;
                    }
                }

                &.coveo-active {
                    background-color: $white;

                    a, a:visited {
                        color: $blue;
                        font-weight: $bold;
                    }
                }
            }
        }
    }
}

@import "searchframe-mobile";
