/* -----------------------------------------------------------------------------
Import PRINT
----------------------------------------------------------------------------- */
@media print {
    .header-wrapper {
        position: absolute;
    }

    header.desktop, header.mobile {

        .top-nav {
            .hamburger {
                display: none;
            }

            .logo .white {
                display: none;
            }

            .logo .color {
                display: block;
                width: 260px;
            }
        }

        .utility-nav {
            display: none;
        }
    }

    .splashmain {
        .angle-overlay {
            display: none;
        }
    }

    footer {
        display: none;
    }

    /* additional Print styles */
    .gradient-overlay,
    .shapes-overlay,
    .angle-overlay,
    .right-side,
    .left-side {
        display: none;
    }

    #lhnHocButton,
    #lhnHelpOutCenter,
    #lhnHocInvite,
    .lhnHocChatTxtCont {
        display: none;
    }

    .breadcrumbs {
        display: none;
    }

    .featuredcontent {
        margin: 20px 0;
    }

    .newsrollup {
        margin: 20px 0;
    }

    .education {
        margin: 0;
        padding: 20px 0;
    }

    .education .product-box {
        padding: 0px;
    }

    .education,
    .newsrollup,
    .featuredcontent,
    .eventsrollup {
        page-break-before: always;
    }

    .row {
        display: block;
    }

    .calltoactiontile .tile-wrap.blue .circle{
        display: none;
    }

    .ad-slot.mobile {        
        display: none;
    }

    /* Coveo results additional Print styles */
    .search-bar,
    .search-top-controls,
    .search-mobile-accordions,
    .search-summary,
    .search-results .load-more {
        display: none;
    }
    .search-bottom-controls {
        display: none !important;
    }
}