.eventmeetingdetail {
    margin-bottom: 100px;
    span {
        display: block;
        margin-bottom: 20px;

        &.date {
            display: inline;
        }
    }

    .featuredevent-details {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 40px 0;

        .date {
            height: 100%;
            min-height: 115px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;
            margin-right: 100px;

            .month {
                display: block;
                font-size: 14px;
                font-weight: $bold;
                color: $black;
                letter-spacing: 1.5px;
                text-transform: uppercase;
                margin: 0;
                line-height: 1;
            }

            .day {
                display: block;
                font-size: 100px;
                font-weight: $light;
                color: $black;
                margin: 0;
                line-height: 1.2;
            }
        }

        .event {
            height: 100%;
            min-height: 115px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;

            .tag {
                display: block;
                font-size: 14px;
                font-weight: $extra-bold;
                color: $purple;
                letter-spacing: 1.5px;
                text-transform: uppercase;
            }

            .name {
                display: block;
                font-size: 28px;
                line-height: 1.3;
                letter-spacing: 0.5px;
                font-weight: $extra-bold;
                color: $black;
                text-transform: uppercase;
            }

            .whenwhere {
                .dates {
                    display: inline-block;
                    font-size: 18px;
                    color: $dark-grey;
                    margin-right: 10px;
                    margin-bottom: 0;
                }

                .location {
                    font-size: 18px;
                    color: $dark-grey;
                    //margin-left: 10px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .course-credits {
        font-size: 16px;
        letter-spacing: 1.4px;
        color: $black;
        font-weight: $bold;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    .course-education-track {
        font-size: 16px;
        letter-spacing: 1.4px;
        color: $black;
        font-weight: $bold;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .tags {
        @include list-reset;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 50px 0;
        flex-wrap: wrap;

        li {
            color: $blue;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: $bold;
            letter-spacing: 1.4px;
            margin-right: 30px;
            margin-bottom: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.eventcontactdetail {
    padding: 30px 30px 40px 30px;
    background-color: $light-grey;
    margin-bottom: 100px;
    margin-top: 40px;
    h3 {
        margin-bottom: 20px;
    }

    span {
        display: block;
        margin-bottom: 0;

        a, a:visited {
            @include link;
            padding-left: 0;
        }
    }
}

@import "eventmeetingdetail-mobile";
