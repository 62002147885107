.member-application {
    margin-top: 30px;
    margin-bottom: 100px;

    &.ui-is-disabled {
        pointer-events: none;

        input, select {
            opacity: 0.5;
        }

        .change-step-container {
            button.disabled {
                color: $light-grey;

                &:before {
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border: 3px solid lighten($grey, 25%);
                    border-top: 3px solid lighten($light-grey, 15%);
                    border-radius: 100%;
                    transition: all 200ms ease-in-out;
                    animation: loading 2s 0.25s linear infinite;
                }

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    color: $grey;

                    &:before {
                        display: none;
                    }
                }

                @keyframes loading {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }

    .step-container {
        margin-bottom: 30px;

        .panel {
            background-color: rgba($color: $grey, $alpha: 0.06);
            width: 100%;
            padding: 20px 25px;
            // margin-top: 15px;
            margin: 0 0 20px 0;

            .edit {
                margin: 20px 0;
                padding: 30px;
                background-color: $white;
                border: 1px solid $grey;
            }

            .cc-expire-info {
                padding-top: 10px;
            }
        }

        .field-wrap {
            width: 100%;
            margin-bottom: 20px;

            label {
                display: inline;
                margin-right: 0;
            }

            input[type="text"], input[type="tel"], input[type="date"], input[type="password"], select {
                height: 42px;
            }

            input, input[type="text"] {
                width: 100%;
            }

            input[type="checkbox"] {
                &:nth-of-type(1) {
                    margin-left: 0;
                }
            }

            input[type="checkbox"] + label, input[type="radio"] + label {
                display: inline;
                font-size: 14px;
                margin-right: 20px;

                &:first-of-type {
                    margin-right: 0;
                }
            }

            select {
                width: 100%;
                //max-width: 400px;
            }

            textarea {
                width: 100%;
                //height: 300px;
            }
        }

        span.selected {
            font-weight: $bold;
        }

        span.get-info {
            font-size: 14px;
            margin-left: 12px;
            font-weight: $light;
            cursor: pointer;

            &:before {
                content: url('../../Images/Base/dist/icon-info.svg');
                position: relative;
                top: 2px;
                width: 16px;
                display: inline-block;
                margin-right: 5px;
            }
        }

        .validation-input {
            position: relative;

            &.has-error {
                select, input[type="text"], input[type="date"] {
                    border: 1px solid $red;
                    color: $red;
                }

                &.unused {
                    select, input[type="text"], input[type="date"] {
                        border: none;
                        color: $dark-grey;
                    }
                }
            }

            .has-error {
                width: auto;
                max-width: 100%;
                font-size: 12px;
                line-height: 1.3;
                color: $white;
                font-weight: $bold;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                margin: 10px 10px 10px 0;
                position: absolute;
                z-index: 1;
                top: 25px;
                left: 0;
                display: block;
                background-color: $red;
                padding: 8px 10px;

                &.unused {
                    color: $red;
                    background-color: transparent;
                    padding: 8px 2px;
                }
            }

            &.validation-radio-buttons {
                .has-error.unused {
                    position: relative;
                    top: -5px;
                    margin: 0;
                }
            }
        }

        .warning {
            width: auto;
            max-width: 100%;
            font-size: 12px;
            line-height: 1.3;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            margin: 10px 10px 10px 0;
            position: absolute;
            z-index: 1;
            top: 48px;
            left: 15px;
            display: block;
            background-color: $yellow;
            padding: 8px 10px;
            margin-right: 5px;

            a, a:visited {
                color: $black;
            }
        }

        .field-group-wrap {
            margin-bottom: 30px;

            label {
                font-weight: $bold;
            }
        }
    }

    .cart-container {
        padding: 30px;

        .item, .total {
            border-bottom: 1px solid $light-grey;
            padding: 20px 0;

            .desc {
                a, a:visited {
                    //@include headline-link;
                }

                span, label {
                    display: block;

                    &.line1 {
                        font-weight: $bold;

                        &.state-component-label {
                            margin-right: 0;
                        }
                    }

                    &.line2 {
                        font-weight: $regular;
                    }
                }
            }

            .qty {
                span {
                    display: flex;
                    justify-content: flex-start;
                    font-weight: $regular;
                }
            }

            .cost {
                span {
                    display: flex;
                    justify-content: flex-end;
                    font-weight: $bold;
                }
            }

            &.light-grey {
                background-color: $lighter-grey;
                margin: 0 -15px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .total {
            border-bottom: none;

            .desc {
                span {
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }
            }
        }

        .change-step-container {
            margin-top: 0;
            margin-bottom: 30px;
        }
    }

    .state-component-opt-out {
        input {
            vertical-align: middle;
        }

        label {
            &.opt-out-label {
                display: inline;
            }
        }
    }
}

.credentials-container {
    .creds-edit {
        color: $blue;

        span {
            color: $blue;
            margin-left: 3px;

            &:after {
                content: url('../../Images/Base/dist/icon-close.svg');
                margin: 0 3px;
                position: relative;
                top: 2px;
                cursor: pointer;
            }
        }
    }
}


.Select-menu-outer {
    border: 1px solid $light-grey;
    padding-bottom: 5px;
}

.Select-menu {
    max-height: 300px;
    overflow-y: auto;
}

.Select-option {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid $lighter-grey;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding-left: 10px;

    &.is-focused {
        background-color: $lighter-grey;
        font-weight: $bold;
    }

    &:last-child {
        border-bottom: none;
    }
}

.opted-out {
    text-decoration: line-through;
}

.purchase-information-container {
    .date {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.3px
    }

    .purchase-item {
        display: flex;
        justify-content: space-between;
    }

    .purchase-total {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .item, .cost {
            font-weight: $bold;
        }
    }
}

@import "membershipapplication-mobile.scss";
