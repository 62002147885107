@include max-screen(md) {
    .marketingcallout {
        .articlebox-image {
            margin-bottom: 30px;
        }
        .articlebox-content-wrap {
            .articlebox-content {
                padding: 0 40px 40px 40px;
                .subhead {
                    margin-bottom: 10px;
                }
                .text-wrap {
                    h3 {
                        margin-bottom: 15px;
                    }
                    p {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}