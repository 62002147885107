@media (max-width: 1024px) {
    .mega-menu {
        position: absolute;
        width: 100%;
        left: 0;
        padding: 0;
        box-sizing: content-box;
        z-index: 1000;
        font-size: 1.35rem;
        line-height: 2.5rem;
        display: block;
        background: $white !important;
        box-shadow: 14px 5px 20px rgba(0, 0, 0, 0.1);
        overflow-y: scroll;
        max-height: calc(100vh - 120px);
        display: none;

        &__item {
            margin: 0;
            flex: 0 1 0px;

            &:not(:first-child) {
                border-top: 1px solid rgba($grey, 20%);
            }
        }

        &__link,
        .collapsible-toggle {
            padding: 1rem 2rem;
            display: block;
            position: relative;
            color: #000;
           
            &.active {
                background: #1226AA;
                color: white;
            }
        }

        &__link:visited {
            color: #000;
        }

        .collapsible-toggle {
            &::after {
                content: "";
                position: absolute;
                right: 2rem;
                top: 50%;
                transform: translateY(-50%) rotate(-45deg);
                transition: all 0.2s;
                display: block;
                width: 0.5em;
                height: 0.5em;
                border: 1px solid $black;
                border-left: 0;
                border-top: 0;
            }
        }

        .collapsible-panel {
            position: static;
            width: 100%;
            padding: 0;
            display: none;
            font-size: 1.35rem;
            line-height: 2.5rem;
            box-shadow: none;
            visibility: visible;
            transition-delay: 0s;
            opacity: 1;

            li {
                margin: 0;
                // padding: 0;
                line-height: 1.2;
                padding: 8px 0;
                display: block;
            }

            &__nav-wrapper {
                margin: 0;
            }

            &__title-wrapper,
            &__section-image {
                display: none;
            }

            &__nav-items,
            &__item-links {
                display: block;
            }

            &__nav-items {
                padding: 0 3rem;
            }

            &__subtitle {
                margin-bottom: 0;
            }
        }

        .collapsible-item {
            &.active {
                .collapsible-panel {
                    padding-bottom: 2rem;
                    transition-delay: 0s;
                    visibility: visible;
                    opacity: 1;
                }

                .collapsible-toggle {
                    &::after {
                        transform: translateY(-50%) rotate(45deg);
                    }
                }
            }
        }

        .header-layout {
            position: relative;
            padding: 1.2rem 0;
        }

        .hide {
            display: block;
        }
    }
  .mega-menu-more-items-wrapper,
  .mega-menu_item.priority {
    display: none;
    &.active {
      display: none;
    }
  }

    .mega-menu-wrapper {
        left: 0;
        width: 100%;
        background: transparent !important;


        .slice__content {
            padding: 0;
        }

        .header-layout__navigation,
        .site-nav {
            width: auto;
        }

        .site-nav {
            max-height: 70px;
            background-color: $light-grey;
        }

        .header-layout {
            padding: 0;
        }

        .collapsible-toggle-mobile {
            display: none;
        }

        .label {
            display: block;
            font-size: 10px;
            font-weight: 900;
            letter-spacing: 2px;
            color: $blue;
            text-transform: uppercase;
            margin-top: 2px;
        }

        .hamburger-icon {
            width: 100%;
            height: 4px;
            background: $blue;
            margin: .4rem 0;
            display: block;
            position: relative;
            &::before,
            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 4px;
                background: #1226aa;
                position: absolute;
            }
            &::before {
                top: -.5rem;
            }
            &:after {
                bottom: -.5rem;
            }
        }
    }

  .mega-menu-wrapper-preview {
    left: 0;
    width: 100%;
    background: transparent !important;
    top: 120px;
    

    .slice__content {
      padding: 0;
    }

    .header-layout__navigation,
    .site-nav {
      width: auto;
    }

    .site-nav {
      max-height: 70px;
      background-color: $light-grey;
    }

    .header-layout {
      padding: 0;
    }

    .collapsible-toggle-mobile {
     display: none;
    }

    .label {
      display: block;
      font-size: 10px;
      font-weight: 900;
      letter-spacing: 2px;
      color: $blue;
      text-transform: uppercase;
      margin-top: 2px;
    }
    
    .hamburger-icon {
      width: 100%;
      height: 4px;
      background: $blue;
      margin: .4rem 0;
      display: block;
      position: relative;
      &::before,
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 4px;
        background: #1226aa;
        position: absolute;
        }
        &::before {
          top: -.5rem;
        }
        &:after {
          bottom: -.5rem;
        }
    }
  }


}

@media (min-width: 769px) {
  .collapsible-toggle-mobile {
    display: none;
  }
}

