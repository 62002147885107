.membership-application-wrapper {
    .step-1-container {
        .step1 {
            label {
                font-size: 18px;
                line-height: 1.9;
                display: inline;
                margin: 0;
            }

            span {
                font-size: 18px;
                line-height: 1.9;
                display: inline-flex;
                align-items: center;
            }

            .workLocation {
                margin-left: 0;
                display: flex;
                align-items: center;
            }

            .workLocationChild {
                margin-left: 15px;
            }

            .workLocationGrandChild {
                margin-left: 30px;
            }

            .workLocationGrandChildSuboption {
                margin-left: 45px;
            }
        }
    }
}
