.membership-application-wrapper {

    .Select-value-icon, .Select-clear {
        font-size: 21px;
        color: $grey;
        margin: 0 8px;
        position: relative;
        top: 2px;
        cursor: pointer;
    }

    .Select-input {
        input {
            width: 150px !important;
            margin: 5px 10px 5px 0;
        }
    }

    .step-2-container {
        .credentials-container {
            .get-info {
                margin-left: 0;
            }
        }

        .email-password-container {
            .field-wrap {

                .show-password-wrapper {
                    float: right;

                    input.show-password {
                        width: auto;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

@import "step2-mobile.scss";