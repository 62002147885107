.createaccount {
    .edit {
        display: block;
    }
    input[type="password"] {
        width: 100%;
    }
    hr.divider {
        margin: 30px 0;
        &.mobile {
            display: block;
        }
    }
    .checkbox-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        input[type="checkbox"] {
            margin-bottom: 3px;
        }
        label {
            line-height: 1.3;
        }
        a.link {
            text-transform: none;
            letter-spacing: 0;
        }
    }
    .save-button {
        width: auto;
        cursor: pointer;
    }
}


.basemodal {
    .modal-header {
        border-bottom: none;
    }
    .modal-body {
        padding: 10px 50px;
        position: relative;
        .alert-message {
            display: block;
            text-align: center;
            font: {
                weight: $bold;
                size: 14px;
            }
            line-height: 16px;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            margin-bottom: 10px;
        }
        h3 {
            margin-bottom: 15px;
        }
        p {
            text-align: left;
            line-height: 1.3;
            margin-bottom: 10px;
        }
        .modal-utility {
            @include list-reset;
            display: inline-flex;
            li {
                margin-left: 20px;
                a, a:visited {
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
            position: absolute;
            top: 15px;
            right: 50px;
        }

        button.cta {
            padding: 0 2px 2px 2px;
            border-top: 2px solid transparent;
            border-right: 2px solid transparent;
            border-bottom: 2px solid #F3C300;
            border-left: 2px solid transparent;
            color: #4C4B4B;
            background-color: transparent;

            &:focus {
                border-color: transparent;
                border: 2px solid #F3C300;
            }

            &:hover {
                text-decoration: none;
                background-color: #F8DF8D;
                border: 2px solid transparent;
            }
        }

        .login-message {
            width: auto;
            background-color: #F3C300;
            padding: 8px 10px;
            margin: 3px 0 0 0;
            display: none;

            a {
                color: #FFF;
                text-decoration: underline;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    .modal-footer {
        border-top: none;
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        .cta {
            &.cancel {
                @include secondary-button;
                font-size: 12px;
            }
            &.save {
                @include primary-button;
                font-size: 12px;
            }
        }
    }
}