.article-summary-wrap {
    display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
    flex-direction: column;
    margin-bottom: 30px;

    .summary-text {
        width: 100%;
        //margin-right: 15px;
    }

    .summary-image {
        width: 100%;
        position: relative;
        //margin-left: 15px;
        img {
            width: 100%;
        }
        .gradient-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(180deg, transparent 0%, rgba($color: $blue, $alpha: 0.2) 70%, rgba($color: $blue, $alpha: 0.4) 100%);
        }
    }
}

@import "articlesummary-mobile";
