/* ================================================================
   BANNERS
   ================================================================ */
.banner__media {
    position: relative;
    max-width: 1070px;
    width: 100%;
    height: 0;
    padding-top: 56%;
    overflow: hidden;
    background: #fff;
}

.banner__media.swap-divs {
    order: 1;
}

@media (min-width: 960px) {
    .banner__media {
        padding-top: 34%;
    }

    .vertical-alignment .banner__media {
        padding-top: 56%;
    }
}

@media (min-width: 1010px) {
    .banner__media {
        padding-top: 35.5%;
    }
}

@media (min-width: 1070px) {
    .banner__media {
        padding-top: 37%;
    }
}

@media (min-width: 1200px) {
    .banner__media {
        padding-top: 40%;
    }
}

.banner__media-img {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;

    &.edit-mode {
        position: relative;
        opacity: 1;
        width: 100%;
        height: 100%
    }
}

.banner__media-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner__title-text {
    font-size: 40px;
    font-weight: 700;
    display: block;
}
@media (max-width: 959px) {
    .banner__title-text:first-child {    
        margin-top: 10px;
    }
}

.banner__title-category {
    display: block;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
}

.banner__description {
    margin-bottom: 40px;

    p:last-child {
        margin-bottom: 0;
    }
}

@media (min-width: 60em) {
    .banner {
        display: -ms-flexbox;
        display: flex;
    }

    .banner__content {
        padding: 20px;
        width: 460px;
    }
}

/* Vertical Alignment Banner */
.vertical-alignment .banner__media {
    max-width: 100%;
}

@media (min-width: 60em) {
    .vertical-alignment.banner {
        display: block;
    }

    .vertical-alignment .banner__content {
        width: 100%;
    }
}

@include max-screen(sm) {
    .banner__title-text {
        font-size: 30px;
    }
}