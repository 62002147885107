/* Banner section */
.banner-section {
    position: relative;

    .banner__media {
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: 100%;
        background: #1226aa;
        background: rgba(18,38,170,0.9);
        padding: 0;

        .banner__media-bg {
            background-position: 50%;
        }
    }

    .slice {
        position: relative;
        color: #fff;

        &.edit-mode {
            width: 800px;
            margin: 0 auto;
        }
    }

    .slice__title {
        font-weight: 300;
        margin-bottom: 10px;
    }
}
