@include max-screen(sm) {
    .course-callouts {
        ul {
            flex-direction: column;
            align-items: flex-start;
            li {
                margin-bottom: 15px;
            }
        }
    }
}
