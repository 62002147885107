@include max-screen(xl) {
    .eventsrollup .articlebox-image {
        height: 383px;
    }
    .eventsrollup .events-list-wrap .events-list {
        height: 383px;
    }
}

@include max-screen(lg) {
    .eventsrollup .articlebox-image {
        height: 325px;
    }
    .eventsrollup .events-list-wrap .events-list {
        height: 325px;
        .articlebox-content-wrap {
            .event-date {
                .day {
                    font-size: 60px;
                }
            }
            .articlebox-content {
                .subhead {
                    margin: 10px 0;
                }
            }
        }
    }
}

@include max-screen(md) {
    .eventsrollup .articlebox-image {
        height: auto;

        .left-side {
            border-bottom: 90px solid $white;
        }

        .right-side {
            border-right: 30vw solid transparent;
            border-bottom: 90px solid #fff;
        }
    }

    .eventsrollup .events-list-wrap .events-list {
        height: auto;
    }

    .eventsrollup .articlebox-content-wrap {
        margin-bottom: 50px;
    }
}

@include max-screen(sm) {
    .eventsrollup .articlebox-image {
        .left-side {
            border-bottom: 50px solid $white;
        }

        .right-side {
            border-right: 25vw solid transparent;
            border-bottom: 50px solid #fff;
        }
    }
}