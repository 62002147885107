.member-resources {
    margin: 0 0 100px 0;
    h2 {
        margin-bottom: 30px;
    }

    .resources-box {
        background-color: $light-grey;
        width: 100%;
        padding: 40px 30px;
        margin: 0 0 30px 0;
        min-height: 160px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        overflow: hidden;
        z-index: 0;

        &.locked, &.unlocked {
            background-color: $white;

            &:before, &:after {
                content: '';
                position: absolute;
                left: 0;
                z-index: -1; // underlay beneath content
                background-color: $light-grey;
                border-color: $light-grey;
            }

            &:before {
                bottom: 0;
                right: 0;
                top: 50px; // == border-bottom-width of :after
            }

            &:after {
                top: 0;
                right: -12px; // + some px to mask rounding errors during scale
                bottom: auto;
                border-style: solid;
                border-width: 0 80px 60px 0; // Angle
                background-color: transparent;
                border-right-color: transparent; // Angle
            }
        }

        &.locked {
            .unlocked-content {
                display: none;
            }
            .locked-content {
                display: block;
                position: absolute;
                top: 0;
                right: 15px;
            }
        }

        &.unlocked {
            .locked-content {
                display: none;
            }
            .unlocked-content {
                display: block;
                position: absolute;
                top: 0;
                right: 15px;
            }
        }

        .pdf {
            width: 48px;
            margin-right: 15px;
        }

        h4 {
            line-height: 1.2;
        }

        .locked-content {
            display: none;
        }
    }

    span {
        display: block;
        font-size: 14px;
        margin: 20px 0;
    }
}
