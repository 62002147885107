.accordionItem {
    .accordionItemHeading {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: $white;
        font: {
            family: $lato;
            weight: $bold;
            size: 24px;
        }
        color: $black;
        text-align: left;
        padding: 15px 0;
        border: none;
        border-bottom: 1px solid $light-grey;
        width: 100%;
        margin: 10px 0 30px 0;
        outline: none;
        transition: 200ms;
        opacity: 1;
        cursor: pointer;
    }
}
.close {
    float: none;
    &.accordionItem {
        opacity: 1;
        button.accordionItemHeading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.has-dropdown {
                &:after {
                    content: url('../../Images/Base/dist/icon-plus-dark.svg');
                    margin-left: 30px;
                    display: inline-block;
                    width: 12px;
                    height: 36px;
                }
            }
            &:focus {
                text-decoration: underline;
            }
        }
    }
    .accordionItemContent {
        height: 0px;
        transform: scaleY(0);
        opacity: 0;
        float: left;
        display: block;
    }
}
.open {
    &.accordionItem {
        opacity: 1;
        button.accordionItemHeading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.has-dropdown {
                &:after {
                    content: url('../../Images/Base/dist/icon-minus-dark.png');
                    margin-left: 30px;
                    display: inline-block;
                    width: 12px;
                    height: 36px;
                }
            }
        }
    }
    .accordionItemContent {
        transform: scaleY(1);
        transform-origin: top;
        transition: transform 200ms ease-in-out;
        opacity: 1;
        transition: opacity 500ms ease-in-out;
        margin-bottom: 40px;
    }
}

@import "accordion-mobile";