﻿.animated-bg {
    position: relative;

    &__content {
        position: relative;
        height: 50%;
    }

    &__items {
        position: absolute;
        inset: 0;
    }

    &__item {
        position: absolute;
        top: 0;
        animation: animbgfloat ease-in-out infinite alternate;
    }
}

@keyframes animbgfloat {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}
