.featuredcontent {
    margin: 50px 0;

    h2 {
        margin-bottom: 30px;
    }

    .articlebox-image {
        margin-bottom: 30px;

        img {
            width: 100%;
        }

        width: 100%;
        //height: 100%;
        background-color: transparent;
        position: relative;
        z-index: 0;
        height: 329px;
        overflow: hidden;

        .gradient-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-125deg, transparent 0%, transparent 40%, $blue 100%);
        }

        .shapes-overlay {
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: url('../../Images/Base/dist/splash-shapes.svg') no-repeat -590px -260px; // L/R, UP/DN
        }

        .angle-overlay {
            position: absolute;
            z-index: 3;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-155deg, transparent 0%, transparent 83%, white 83%, white 100%);
        }
    }

    .articlebox-content {
        width: 100%;
        min-height: 210px;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;

        h4 {
            line-height: 1.2;
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 20px;
        }

        .subhead {
            width: 100%;
            height: 18px;
            margin-bottom: 15px;

            span {
                display: block;
                font-size: 12px;
                font-weight: $extra-bold;
                letter-spacing: 1.6px;
                text-transform: uppercase;
                color: $blue;

                &.hide {
                    display: none;
                }
            }
        }
    }

    .cta {
        @include cta-button-underline;
        margin-top: auto;
    }
}

.home-page {
    .featuredcontent {
        margin-top: 0;
    }
}

@import "featuredcontent-mobile";
