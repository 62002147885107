.calltoactions {
    margin: 30px 0;
    background-color: $light-grey;
    padding: 30px;

    h3 {
        color: $black;
    }

    .ctas {
        @include list-reset;
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
            width: 100%;

            a, a:visited {
                @include cta-button-outline;
                margin: 0;
                margin-right: 10px;
            }
        }
    }
}

@import "calltoactions-mobile";
