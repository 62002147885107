@include max-screen(md) {
    .member-application {
        .cart-container {
            padding: 15px;

            .item, .total {
                .cost {
                    span {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}

@include max-screen(sm) {
    .purchase-information-container {
        hr.divider {
            margin: 15px 0 20px 0;
        }

        .purchase-item {
            flex-direction: column;
        }

        .purchase-total {
            flex-direction: column;
        }
    }
}
