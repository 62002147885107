.reports-link {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: $bold;
    color: $grey;
    &:last-child {
        margin-bottom: 0;
    }
    a, a:visited {
        @include link;
    }
}

span.get-info {
    font-size: 14px;
    margin-left: 12px;
    font-weight: $light;
    cursor: pointer;

    &:before {
        content: url('../../Images/Base/dist/icon-info.svg');
        position: relative;
        top: 2px;
        width: 16px;
        display: inline-block;
        margin-right: 5px;
    }
}