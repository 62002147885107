.nextprevnav {
    margin: 100px 0;
    ul {
        @include list-reset;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        li {
            &.prev {
                &:before {
                    content: url('../../Images/Base/dist/icon-arrow-left.svg');
                    margin-right: 15px;
                }
            }
            &.next {
                &:after {
                    content: url('../../Images/Base/dist/icon-arrow-right.svg');
                    margin-left: 15px;
                }
            }
            a, a:visited {
                font-weight: $bold;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
        }
    }
}

@import "nextprevnav-mobile";