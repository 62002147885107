﻿/*.dataTables_paginate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    border-radius: .25rem;
    margin-top: .5rem;
    margin-bottom: .5rem;



    a {
        position: relative;
        display: block;
        padding: .5rem .75rem;
        margin-left: -1px;
        line-height: 1.25;
        background-color: #fff;
        border: 1px solid #dee2e6;
        cursor: pointer;



        &:hover,
        &:focus {
            text-decoration: none;
            background-color: #F8DF8D;
            border-color: #F8DF8D;
            color: #000;
        }



        &:focus {
            z-index: 2;
            outline: 0;
        }



        &.current {
            z-index: 1;
            color: #fff;
            background-color: #0a1980;
            border-color: #0a1980;
        }
    }



    > a {
        &:first-child {
            margin-left: 0;
            border-radius: .25rem 0 0 .25rem;
        }



        &:last-child {
            border-radius: 0 .25rem .25rem 0;
        }
    }



    > span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.dataTables_info{
    text-align: center;
}*/

.rowContentComponent {
    margin: 0 16px;
}
