@include max-screen(xl) {
    .container {
        .row {
            // Shopping Cart ======================================== /*
            .productControlSection {
                display: flex;
                flex-direction: column;

                input[type="button"].btn, input.btn {
                    margin: 0 0 10px 0;
                }
            }

            td.quantity {
                display: block;
            }

            td.quantity input.update {
                margin: 10px 0 10px 0;
            }
        }
    }
}

@include max-screen(lg) {
    .container {
        .row {
            td.quantity {
                display: block;
            }
        }
    }
}

@include max-screen(md) {
    .container {
        .row {
            td.quantity {
                display: flex;
            }
            // Shopping Cart ======================================== /*
            .productControlSection {
                width: 250px !important;
            }
            // Purchase History ======================================= /*
            div#ctl02_ctl00_ShowRow {
                display: block;
                justify-content: flex-start;
            }

            .RadComboBoxDropDown li.rcbItem {
                font-size: 100% !important;
            }

            .RadAjaxPanel {
                #content_0_twocolumnleft_1_ctl00_ShowRow {
                    flex-direction: column;
                    align-items: flex-start !important;
                }

                .btnFilterSearch {
                    @include primary-button;
                    cursor: pointer;
                    margin: 10px 0;
                }

                .btnFilterClear {
                    @include secondary-button;
                }
            }
            // Transcripts ======================================== /*
            .formRow.ctrl-date-select {
                .ctrl-filter-from, .ctrl-filter-to {
                    flex-direction: column;
                }
            }

            .filterSearchButtons .btn-primary {
                margin-right: 10px !important;
            }
            // Shopping Cart ============================================ /*
            .contentSection {
                flex-direction: column;

                .centerSection {
                    width: 100%;
                }

                .productControlSection {
                    flex-direction: row;
                    justify-content: flex-start;
                    margin: 30px 0;

                    input[type="button"].btn, input.btn {
                        margin: 0 10px 0 0;
                    }
                }
            }

            input#content_0_singlecolumn_0_ctl00_OrderItemList_ShoppingCartItemsRepeater_ctl00_InputQty {
                width: 25%;
            }

            .backToCartDiv.buttonRow {
                margin: 20px 0 30px 0;
                width: 100%;
            }
            .panel-expand-link {
                color:#000;
            }

            p, ul, ol, table {
                font-size: 16px !important;
            }           
        }
    }
}

@include max-screen(sm) {

    select#ctl02_ctl00_memberRepeater_renewCCDropdown_0 {
        width: 100%;
        height: 120px;
        white-space: normal;
    }

    .container {
        .row {
            // Shopping Cart ============================================= /*
            .segmentsListPanel .RadListBox .rlbGroup ul li.rlbSelected, .controlPanel.segmentsListPanel a.action-hyperlink.controlButtonLink.continueShopping {
                @include primary-button;
                background-color: $light-yellow !important;
                padding: 10px 15px !important;
                text-shadow: none !important;
                margin-top: 20px;

                &:before {
                    content: none;
                }

                .rlbText {
                    text-shadow: none !important;
                    font-size: 100% !important;
                    color: $black !important;
                }
            }

            input[type=button].continueShopping {
                width: 100%;
                margin: 20px 0;
            }

            .contentSection {
                .productControlSection {
                    flex-direction: column;


                    input[type="button"].btn, input.btn {
                        margin: 0 0 20px 0;
                    }
                }
            }

            .rlbItem.rlbSelected {
                &:before {
                    content: none !important;
                }
            }

            #content_0_singlecolumn_1_ctl00_PanelShippingEstimates {
                .item {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }

            .btn-default.estimate {
                margin-left: 0;
            }

            .ShoppingCartSummaryControl_PersonifyDefault {
                background-color: transparent;
                padding: 0;
            }

            .checkout-panel {
                background-color: transparent;
                border: none !important;

                .btn.btn-primary.controlbutton.processOrder.purchaseButton {
                    border-radius: 0;
                    width: 100%;
                }
            }
            // Checkout (Step 1 - Address Information) ====================================================== /*
            .addressButtonsDiv.buttonRow {
                flex-direction: column;

                .addressButton {
                    margin: 10px 0;
                }
            }

            .prevNextCancelButtons.buttonRow {
                .next, #NextButton {
                    font-size: 100% !important;
                    border-radius: 0;
                    padding: 10px 15px !important;
                    margin-bottom: 0;
                    margin-right: 10px;
                }
            }
            // Checkout (Step 2 - Item Summary) ======================================= /*
            div#content_0_singlecolumn_0_ctl00_CheckoutItemSummaryControl_ShoppingCartSummaryControl_PanelShippingPreferences {
                flex-direction: column;
            }

            .prevNextCancelButtons.buttonRow {
                #PreviousButton {
                    font-size: 100% !important;
                    border-radius: 0;
                    padding: 10px 15px !important;
                    margin-bottom: 0;
                    margin-right: 10px;
                }
            }

            .RadWindow_PersonifyDefault.rwNormalWindow.rwTransparentWindow .rwWindowContent div {
                width: 100% !important;
            }

            .RadWindow_PersonifyDefault .rwControlButtons a.rwCloseButton {
                padding: 10px;
            }

            div#RadWindowWrapper_ctl02_ctl00_CheckoutItemSummaryControl_CheckoutCancelPopup_wndCancelConfirmation {
                width: 100% !important;
                max-width: 300px !important;
            }
            // Checkout (Step 3 - Payment) ======================================= /*
            .mainCheckoutDiv .leftPane {
                padding: 0;
            }

            .summaryTopDiv {
                margin: 0;
                border: none;
                background-color: transparent;
                padding: 0;
                text-shadow: none;

                .name {
                    font-size: 100%;
                }
            }

            .checkoutItemName {
                font-size: 1em !important;
            }

            div#content_0_singlecolumn_0_ctl00_CheckoutPaymentInformationControl_ShoppingCartSummaryControl_PanelShippingPreferences {
                flex-direction: column;
            }

            .productbuttons-mobile {
                display: block !important;
            }

            .prod-detl-mobile-zone1 {
                display: block !important;
                padding: 0px;
                margin: 0px;
                width: 100%;
            }

            .mobile-product-actions {
                display: block !important;
                max-width: 100%;
                width: 100% !important;
                float: none;
                margin: 15px;
                padding: 0px;

                .info .PriceDisplayControl_PersonifyDefault {
                    max-width: inherit;
                    width: 100%;
                }

                .PriceListUL {
                    padding: 20px;
                    background-color: #F8F8F8;
                }

                .PriceListUL li {
                    max-width: 100%;
                }

                .quantityContainer label {
                    font-size: 1.05em;
                    margin-right: 0px;
                }

                input.quantity {
                    font-size: inherit;
                }

                .options {
                    -moz-border-bottom-colors: none;
                    -moz-border-image: none;
                    -moz-border-left-colors: none;
                    -moz-border-right-colors: none;
                    -moz-border-top-colors: none;
                    background: none repeat scroll 0 0 #F6F6F6;
                    border-color: #CCCCCC;
                    border-style: solid;
                    border-width: 1px 0;
                    padding: 15px 5px 5px 15px;
                }

                fieldset legend {
                    position: initial;
                }

                .OptionDiv .RadComboBox {
                    width: 100% !important;
                }
            }

            .mobile-product-detail-block-cell {
                display: none;
            }

            .RadComboBox .rcbReadOnly .rcbInputCellLeft {
                font-size: initial;
            }

            .rcbSlide .RadComboBoxDropDown.RadComboBoxDropDown_PersonifyDefault {
                top: 0px !important;
                width: 100% !important;
            }

            .rcbSlide .RadComboBoxDropDown.RadComboBoxDropDown_PersonifyDefault .rcbScroll {
                height: auto !important;
            }

            .rcbSlide .RadComboBoxDropDown.RadComboBoxDropDown_PersonifyDefault li {
                padding: 5px 2px;

                .rcbImage {
                    display: none;
                }
            }

            .rcbSlide .RadComboBoxDropDown.RadComboBoxDropDown_PersonifyDefault li.rcbHovered {
                font-size: 100%;
            }

            .ProductDetailControl_PersonifyDefault .productbuttons {
                margin: 0px auto;

                .mobile-detail-add-to-cart-first {
                    width: 100%;
                    margin-right: 10px;
                }
            }
        }
    }
}

@include max-screen(xs) {
    .container {
        .row {

            .ProductDetailControl_PersonifyDefault .productbuttons {
                margin: 0px auto;

                &:last-child {
                    padding: 0px;
                }

                .btn {
                    margin: 0px;
                    width: 100%;
                    padding: 10px 5px !important;
                }

                .mobile-detail-add-to-cart-first {
                    width: 100%;
                    margin-right: 5px;
                }
            }

            div#ctl02_ctl00_Cvv2Row {
                margin-top: -30px !important;
            }
        }
    }

    select#ctl02_ctl00_memberRepeater_renewCCDropdown_0 {
        width: 100%;
        height: 120px;
        white-space: normal;
    }
}

@media screen and (max-width:42em) {
    #ctl02_ctl00_ProductGenericBuyBoxControl_ProductDetailActionButtonControl1_AddToCartWindow_PopupWindow_C_Container.ShoppingCartAddedItemsPopupControl_PersonifyDefault.Base_PersonifyDefault.Base_PersonifyDefault {
        width: 500px;
        transform: none;
        z-index: 3002;
        visibility: visible;
        left: 0;
        top: 94px;
        position: fixed;
        margin-top: 26px;
        background-color: white; 
    }

    #ctl02_ctl00_ProductGenericBuyBoxControl_ProductDetailActionButtonControl1_AddToCartWindow_PopupWindow_C.RadTouchExtender::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 4000px;
        opacity: .5;
        z-index: 3000;
        background-color: rgba(0, 0, 0, 0.5);
    }

    #ctl02_ctl00_ProductGenericBuyBoxControl_ProductDetailActionButtonControl1_AddToCartWindow_PopupWindow_C_CancelButtonMobile.mobile-rwCloseButton {
        display: none;
    }

    .ShoppingCartAddedItemsPopupControl_PersonifyDefault .panelActionButtons .checkoutButton, .panelShoppingCartAddedItems .panelActionButtons .checkoutButton, .ShoppingCartAddedItemsPopupControl_PersonifyDefault .panelActionButtons .viewCartButton, .panelShoppingCartAddedItems .panelActionButtons .viewCartButton {
        background-color: #F8DF8D;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.4px;
    }
}
