.fasa-member-box {
    background-color: #fff;
    padding: 30px;
    height: 100%;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    border-top: 1px solid #EDECEC;
    border-right: 1px solid #EDECEC;
    border-bottom: 2px solid #0A1980;
    border-left: 1px solid #EDECEC;
    transition: all 200ms ease-in-out;

    &:hover, &:focus {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }

    .fasa-name {
        font-size: 20px;
        font-weight: 500;
        color: #0A1980;
        line-height: 130%;
        display: block;
    }

    .fasa-credentials {
        font-family: "Lato",sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #4C4B4B;
        line-height: 1.4;
        text-transform: uppercase;
    }
}

@import "fasamemberbox-mobile";