.editorialcallout {
    border: 2px solid $cyan;
    padding: 40px;
    .articlebox-image {
        img {
            width: 100%;
        }
        width: 100%;
        background-color: $light-grey;
        position: relative;
        z-index: 0;
        //height: 463px; // Match Events List Height
        overflow: hidden;
        .shapes-overlay {
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: url('../../Images/Base/dist/splash-shapes.svg') no-repeat -590px -260px; // L/R, UP/DN
        }
    }
    .articlebox-content-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: space-between;
        height: 100%;
        .articlebox-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            .subhead {
                width: 100%;
                height: 18px;
    
                span {
                    display: block;
                    font-size: 12px;
                    font-weight: $extra-bold;
                    letter-spacing: 1.6px;
                    text-transform: uppercase;
                    color: $cyan;
    
                    &.hide {
                        display: none;
                    }

                    &.sponsored {
                        background-color: $blue;
                        padding: 3px 10px;
                        color: $white;
                        font-size: 9px;
                        font-weight: $bold;
                        text-transform: uppercase;
                        letter-spacing: 1.2px;
                        display: inline-block;
                    }
                }
            }
            .text-wrap {
                h3 {
                    line-height: 1.3;
                    margin-bottom: 20px;
                }
                p {
                    margin-bottom: 0;
                }
            }
            .cta {
                @include cta-button-outline;
                margin: 0;
                border: none;
                padding: 0;
                display: inline;
                color: $black;
                &:hover, &:focus {
                    margin: 0;
                    border: none;
                    padding: 0;
                    color: $black;
                }
            }
        }
    }
}

// Element Query
@element .editorialcallout and (max-width: 600px) {
    :self {
        .row {
            flex-direction: column;

            .col-md-6, .col-md-4 {
                max-width: 100%;
            }
        }

        .articlebox-image {
            margin-bottom: 30px;
        }

        .articlebox-content-wrap .articlebox-content .subhead {
            margin-bottom: 10px;
        }

        .articlebox-content-wrap .articlebox-content .text-wrap {
            p {
                font-size: 18px;
                margin-bottom: 30px;
            }
        }
    }
}

@import "editorialcallout-mobile.scss";