@include max-screen(md) {
    footer {
        background-color: $blue;
        padding: 50px 0;

        .rich-text {
            padding-bottom: 40px;

            img {
                display: block;
                margin: 30px auto 30px auto;
            }
        }

        .links-list {
            &.desktop {
                display: none;
            }

            &.mobile {
                display: block;
                width: 100%;

                .accordionItem {
                    .accordionItemHeading {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: $white;
                        background-color: $blue;
                        font-size: 16px;
                        line-height: 1.2;
                        padding: 15px 0;
                        margin: 0;
                        border: none;
                        border-bottom: 1px solid rgba($color: $white, $alpha: 0.5);
                        width: 100%;
                        outline: none;
                        transition: 200ms;
                        opacity: 1;
                        cursor: pointer;

                        &.has-dropdown {
                            position: relative;
                            // &:after {
                            //     content: url('');
                            // }
                        }
                    }
                }

                .close {
                    float: none;

                    &.accordionItem {
                        opacity: 1;

                        button.accordionItemHeading {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            &.has-dropdown {
                                &:after {
                                    content: url('../../Images/Base/dist/icon-plus.svg');
                                    position: relative;
                                    top: 2px;
                                }
                            }
                        }
                    }

                    .accordionItemContent {
                        height: 0px;
                        transform: scaleY(0);
                        opacity: 0;
                        float: left;
                        display: block;
                    }
                }

                .open {
                    &.accordionItem {
                        opacity: 1;

                        button.accordionItemHeading {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            &.has-dropdown {
                                &:after {
                                    content: url('../../Images/Base/dist/icon-minus.svg');
                                    position: relative;
                                    top: 2px;
                                }
                            }
                        }
                    }

                    .accordionItemContent {
                        transform: scaleY(1);
                        transform-origin: top;
                        transition: transform 200ms ease-in-out;
                        opacity: 1;
                        transition: opacity 500ms ease-in-out;

                        .links {
                            @include list-reset;
                            padding: 0;
                            margin: 25px 0;

                            li {
                                font-size: 14px;
                                margin-bottom: 10px;

                                a, a:visited {
                                    color: $white;
                                }

                                .locked::after {
                                    @include icon-lock;
                                }
                            }
                        }
                    }
                }
            }
        }

        .locations-column {
            float: none;
            margin-right: 0;

            .location {
                margin: 25px 0;

                .title, .address {
                    font-size: 12px;

                    a[href^=tel] {
                        color: inherit;
                        text-decoration: none;
                    }
                }
            }

            .companion-sites {
                margin: 30px 0;

                .accordionItem {
                    .accordionItemHeading {
                        width: 100%;
                        margin: 0;
                    }
                }

                .close {
                    &.accordionItem {
                        width: 100%;
                    }
                }
            }
        }

        .logo-bar {
            margin: 0;

            .logo-wrap {
                display: none;
            }

            .social-links {
                margin-bottom: 20px;
            }
        }

        .footer-base {
            display: flex;
            flex-direction: column-reverse;

            .small {
                font-size: 10px;
                line-height: 1.5;
            }

            .bottom-links {
                flex-direction: column;
                margin-bottom: 40px;

                li {
                    padding: 0;
                    line-height: 2.5;
                    border-right: none;
                }
            }
        }
    }
}
