.more-menu {
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.more-menu__item {
    margin: 0 .5em;
}


.more-menu {
    font-size: 1.5rem;
}

.more-menu .collapsible-item {
    position: relative;
}

.more-menu .collapsible-toggle,
.collapsible-toggle-mobile {
    position: relative;
    padding-right: 30px;
}

.more-menu .collapsible-toggle {
    padding-left: .5em;
}

.more-menu .collapsible-toggle:after,
.collapsible-toggle-mobile:after {
    content: "";
    position: absolute;
    right: 0;
    top: 7px;
    display: block;
    width: 12px;
    height: 12px;
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.collapsible-toggle-mobile {
    font-size: 1.5rem;
}

.site-nav {

    li {
        .CoveoSearchbox {
            width: 170px !important;
            height: 20px !important;
        }
    }

    .CoveoSearchInterface {
        background-color: #FFF;
        min-width: 170px !important;
        float: right;
        margin-left: 60px;
        height: 48px !important;
        min-height: unset !important;
    }
}

    @media (max-width: 768px) {
        .site-nav {
            li {
                .CoveoSearchbox {
                    width: 100% !important;
                    height: 20px !important;
                    float: left;
                    }
            }

            .CoveoSearchInterface {
                min-width: 90% !important;
                float: left;
                margin-left: 15px;
            }
        }
    }

    @media (min-width: 1200px) {
        .collapsible-toggle-mobile {
            display: none;
        }

        .more-menu {
            display: flex !important;
        }
    }


    @media (min-width: 1399px) {
        .more-menu {
            font-size: 1.3rem;
        }
    }


    .more-menu .collapsible-panel {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        width: 200px;
        list-style: none;
        padding: 20px;
        margin: 5px 0 0;
        font-size: 1.5rem;
        line-height: 1;
    }

    .more-menu .active .collapsible-panel {
        display: block;
    }

    .more-menu .collapsible-panel li {
        margin-bottom: 15px;
    }


    @import "micrositenavigation-mobile";