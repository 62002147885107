footer {
    background-color: $blue;
    padding: 140px 0 120px 0;

    .rich-text {
        h3, p {
            color: $white;
            text-align: center;
        }

        img {
            margin: 0 50px 50px 50px;
        }
    }

    .links-list {
        &.mobile {
            display: none;
        }

        &.desktop {
            display: block;

            h4 {
                text-transform: uppercase;
                color: $white;
                letter-spacing: 2px;
            }

            .links {
                @include list-reset;

                li {
                    margin-bottom: 10px;

                    a, a:visited {
                        color: $white;
                    }

                    .locked::after {

                        @include icon-lock;
                    }
                }
            }
        }
    }

    .locations-column {
        float: right;
        margin-right: 10px;
        display: flex;
        flex-direction: column-reverse;

        .location {
            display: block;
            margin-bottom: 20px;

            .title, .address {
                display: block;
                font-size: 14px;
                color: $white;
            }

            .title {
                font-weight: $bold;
                margin-bottom: 10px;
            }

            .address {
                font-weight: $regular;
                font-style: italic;
                line-height: 24px;
            }
        }

        .companion-sites {
            margin: 0 0 40px 0;

            .accordionItem {
                .accordionItemHeading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid $white;
                    width: 210px;
                    padding: 12px;
                    margin: 0;
                    color: $white;
                    background-color: $blue;
                    font-size: 14px;
                    font-weight: $bold;
                    line-height: 1;
                    cursor: pointer;
                    transition: all 200ms ease-in-out;

                    &:focus {
                        outline: none;
                    }
                }
            }

            .close {
                float: none;

                &.accordionItem {
                    opacity: 1;
                    width: 210px;

                    button.accordionItemHeading {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &.has-dropdown {
                            &:after {
                                content: url('../../Images/Base/dist/icon-caret-white.svg');
                                position: relative;
                                bottom: 2px;
                                display: inline-block;
                                width: 9px;
                                height: 14px;
                            }
                        }
                    }
                }

                .accordionItemContent {
                    height: 0px;
                    transform: scaleY(0);
                    opacity: 0;
                    float: left;
                    display: block;
                }
            }

            .open {
                &.accordionItem {
                    opacity: 1;
                    position: relative;

                    button.accordionItemHeading {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        background-color: $light-grey;
                        border: 1px solid $blue;
                        color: $blue;

                        &.has-dropdown {
                            &:after {
                                content: url('../../Images/Base/dist/icon-caret-blue.svg');
                                position: relative;
                                transform: rotate(180deg);
                                top: 2px;
                                display: inline-block;
                                width: 9px;
                                height: 14px;
                            }
                        }
                    }
                }

                .accordionItemContent {
                    transform: scaleY(1);
                    transform-origin: top;
                    opacity: 1;
                    transition: opacity 500ms ease-in-out;
                    position: absolute;
                    z-index: 2;
                    width: 100%;

                    .links {
                        @include list-reset;
                        border: 1px solid $blue;
                        border-top: none;
                        background-color: $white;
                        width: 100%;
                        height: 100%;
                        max-height: 500px;
                        overflow: scroll;

                        li {
                            padding: 10px 12px;

                            a, a:visited {
                                display: block;
                                width: 100%;
                                height: 100%;
                                color: $blue;
                                font-size: 14px;
                                line-height: 1.2;

                                &:hover, &:focus {
                                    text-decoration: none;
                                }
                            }

                            &:hover, &:focus {
                                background-color: $light-grey;
                            }
                        }
                    }
                }
            }
        }
    }

    .logo-bar {
        margin: 40px 0 30px 0;
        display: flex;
        justify-content: space-between;

        .logo-wrap {
            display: block;
            width: 300px;
            height: 74px;

            img {
                width: 243px;
            }
        }

        .social-links {
            @include list-reset;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            li {
                margin-right: 10px;

                a, a:visited {
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    .small {
        color: $white;
    }

    .bottom-links {
        @include list-reset;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;

        li {
            padding: 0 10px;
            line-height: 1;
            border-right: 1px solid $white;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border-right: none;
            }

            a, a:visited {
                color: $white;
            }
        }
    }

    .full-width-background {
        max-width: 100%;
        background-color: #5f59aa;
    }
}

.row-custom-bg {
    margin: auto auto 30px auto;
    justify-content: center;
}

.linked-img-row {
    text-align: center;
    color: $white;

    img {
        max-width: 150px;
        margin: 0px 50px 50px 50px;
    }

    h3 {
        color: #fff;
        padding-top: 30px;
    }

    p {
        margin-bottom: 40px;
        color: #fff;
    }

    .linked-img-images {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

@import "footer-mobile";
