.education {
    margin: 50px auto;
    padding: 40px 0;
    background-color: $light-grey;

    h2 {
        margin-bottom: 30px;
    }

    a, a:visited {
        display: inline;

        &:hover, &:focus {
            text-decoration: none;
        }
    }

    .product-box {
        background-color: $white;
        padding: 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        border: {
            top: 1px solid $light-grey;
            right: 1px solid $light-grey;
            bottom: 2px solid $cyan;
            left: 1px solid $light-grey;
        }

        transition: all 200ms ease-in-out;

        img {
            width: 100%;
            margin-bottom: 30px;
        }

        .tag {
            display: block;
            font-size: 12px;
            font-weight: 900;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            color: #4F05B5;
            margin: 4px 0px 0px 0px;

            &.hide {
                display: none;
            }
        }

        h4 {
            margin: 5px 0 10px 0;
            line-height: 1.3;
        }

        p {
            margin-bottom: 20px;
        }

        .cta {
            @include cta-button-outline;
            margin: 0;
            border: none;
            padding: 0;
            color: $black;
            background-color: transparent;
            margin-top: auto;

            &:hover, &:focus {
                margin: auto 0 0 0;
                border: none;
                padding: 0;
                color: $black;
                background-color: transparent;
                text-decoration: none;
                outline: none;
            }

            &:after {
                content: url('../../Images/Base/dist/icon-arrow-right-purple.svg');
            }
        }

        &:hover, &:focus {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            cursor: pointer;

            a h4, a:visited {
                color: $black;
            }
        }
    }

    .cta-wrap {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        a, a:visited {
            @include cta-button-outline;
            border: none;
            padding: 0;
            color: $black;
            margin: 50px 15px 20px 0;

            &:hover, &:focus {
                margin: 50px 15px 20px 0;
                color: $black;
                border: none;
                padding: 0;
            }

            &:after {
                content: url('../../Images/Base/dist/icon-arrow-right-purple.svg');
            }
        }
    }
}

@import "productrollup-mobile";