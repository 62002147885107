@include max-screen(sm) {
    .calltoactions {
        h3 {
            text-align: center;
            width: 100%;
            margin-bottom: 20px;
        }
        .ctas {
            flex-direction: column;
            li {
                a, a:visited {
                    width: 100%;
                    margin-bottom: 20px;
                    margin-right: 0;
                }
            }
        }
    }
}