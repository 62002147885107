.progress-bar {
    @include list-reset;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 50px 0;

    a, a:visited {
        width: 100%;
        margin: 0 1px;

        li {
            width: 100%;
            padding: 20px 15px;

            border: {
                top: 1px solid $light-grey;
                right: 1px solid $light-grey;
                bottom: 3px solid $black;
                left: 1px solid $light-grey;
            }

            .step, .step-number {
                display: inline;
            }
            .step {
                margin-right: 5px;
            }

            .step-desc {
                display: block;
            }

            .step, .step-number {
                font-weight: $bold;
                text-transform: uppercase;
                letter-spacing: 0.3px;
            }
        }

        &:hover {
            text-decoration: none;

            li {
                transition: all 200ms ease-in-out;
                background-color: $yellow;

                border: {
                    top: 1px solid $yellow;
                    right: 1px solid $yellow;
                    bottom: 3px solid $yellow;
                    left: 1px solid $yellow;
                }
            }
        }

        &.active {
            li {
                transition: all 200ms ease-in-out;
                background-color: $light-yellow;

                border: {
                    top: 1px solid $light-yellow;
                    right: 1px solid $light-yellow;
                    bottom: 3px solid $yellow;
                    left: 1px solid $light-yellow;
                }
            }
        }

        &.disabled {
            li {
                transition: all 200ms ease-in-out;

                border: {
                    top: 1px solid $light-grey;
                    right: 1px solid $light-grey;
                    bottom: 3px solid $grey;
                    left: 1px solid $light-grey;
                }

                .step, .step-number, .step-desc {
                    color: $grey;
                }

            }
            &:hover {
                cursor: default;
                li {
                    background-color: transparent;
                }
            }
        }
    }
}

@import "progressbar-mobile";