﻿/* -----------------------------------------------------------------------------
Overrides
----------------------------------------------------------------------------- */
@import "variables";
@import "mixins";

.search-frame {

    .CoveoResultList {
        .CoveoResult {
            a.CoveoResultLink.cta {
                color: $black;

                &:hover, &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    .coveo-pager-next-icon-svg, .coveo-pager-previous-icon-svg {
        height: 12px !important;
    }

    .member-application {
        .close.accordionItem button.accordionItemHeading:focus {
            text-decoration: none;
        }
    }

    .coveo-facet-value.coveo-selected .coveo-facet-value-checkbox svg {
        bottom: 5px;
    }

    .open .coveo-facet-values li.coveo-facet-selectable .coveo-facet-value-label .coveo-facet-value-label-wrapper .coveo-facet-value-caption {
        margin-right: 15px;
    }

    .coveo-facet-breadcrumb-value + .coveo-facet-breadcrumb-value, 
    .coveo-facet-slider-breadcrumb-value + .coveo-facet-breadcrumb-value, 
    .coveo-facet-breadcrumb-value + .coveo-facet-slider-breadcrumb-value, 
    .coveo-facet-slider-breadcrumb-value + .coveo-facet-slider-breadcrumb-value {
        margin: 0;
    }

    .search-frame .CoveoBreadcrumb .coveo-breadcrumb-items .coveo-facet-breadcrumb.coveo-breadcrumb-item {
        display: inline;
    }
}