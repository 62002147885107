.email-panel {
    .panel .edit {
        input[type="checkbox"] + label.my-account {
            font-weight: $regular;
        }

        .field-wrap {
            margin-bottom: 0;

            input[type="checkbox"] + label, input[type="radio"] + label {
                font-weight: $regular;
            }
        }
    }
}

@import "email-mobile";