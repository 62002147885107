.profile-picture {
    &.data-block {
        margin-top: 0;
    }
    .picture-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end; 
        img {
            width: 125px;
            margin-right: 20px;
        }
        .add-button, .done-editing {
            @include primary-button;
            min-width: 200px;
            margin-right: 20px;
        }
        .done-editing {
            margin-top: 15px;
        }
        input#add-photo-hidden {
            display: none;
        }
    }
    .croppie-container {
        margin-top: 30px;
    }
}

@import "profilepicture-mobile";