.edit.areas-of-interest {
    display: block;
}
.edit.areas-of-interest.fop {
    display: none;
}

.taxonomy-list-form {
    column-count: 3;
}

.areas-of-interest-buttons {
    background-color: #dee2e6;
    padding-bottom:  10px;
}

.areas-of-interest-buttons-flex {
    display: flex;
    padding: 15px 0px;
}

.areas-of-interest-buttons-flex .save-button + .save-button {
    margin-left: 10px;
}
