.moreabout {
    margin: 30px 0;

    h2 {
        margin-bottom: 40px;
    }

    .moreabout-article {
        span {
            display: block;
            margin-bottom: 8px;

            font: {
                size: 12px;
                weight: $bold;
            }

            color: $blue;
            letter-spacing: 1.7px;
            text-transform: uppercase;
        }

        h4 {
            margin: 0 20px 40px 0;
            font-size: 21px;
            line-height: 1.2;
        }

        a, a:visited {
            color: $black;

            &:hover, &:focus {
                color: $black;
            }
        }
    }
}

@import "moreabout-mobile";
