/* -----------------------------------------------------------------------------
Import MOTION
----------------------------------------------------------------------------- */
@keyframes fadeEffect {
    from { 
        opacity: 0; 
    }
    to { 
        opacity: 1; 
    }
}