@include max-screen(md) {
    .search-frame {
        .search-bottom-controls {
            flex-direction: column;

            .CoveoQuerySummary {
                margin-bottom: 0;
            }
        }

        .search-breadcrumb {
            display: none;
        }

        .search-top-controls {
        }

        .coveo-topSpace { // Test
            width: 100%;
            display: inline-block;
        }

        #filter-by {
            display: none;
        }

        .accordionItemContent {
            margin-bottom: 20px !important;
            // Accordion
            .CoveoFacet, .CoveoFacetRange { // .accordionItem
                border: none !important;
                margin: 0 !important;
                min-width: 0;
                max-width: 100%;

                &.open, &.close {
                    margin: 0 !important;
                    border: none;
                }

                .coveo-facet-header { // .accordionItemHeading
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: $light-grey;
                    padding: 10px 20px;
                    width: 100%;

                    border: {
                        top: 1px solid transparent;
                        right: 1px solid transparent;
                        bottom: 1px solid transparent;
                        left: 1px solid transparent;
                    }

                    outline: none;
                    transition: 200ms;
                    opacity: 1;
                    cursor: pointer;

                    &:after {
                        content: url('../../Images/Base/dist/icon-caret-black.svg');
                        margin-left: 10px;
                        position: relative;
                    }

                    .coveo-facet-header-title-section {
                        .coveo-facet-header-title {
                            font-size: 18px;
                            color: $black;
                            font-weight: $bold;
                            width: 100%;
                        }
                    }
                }
                // Coveo Overrides
                .coveo-facet-header-settings-section {
                    display: none;
                }
            }

            .CoveoFacetRange, .CoveoFacet, .close {
                //float: none;
                &.CoveoFacet, &.CoveoFacetRange { // .accordionItem
                    opacity: 1;
                }

                .coveo-facet-values {
                    height: 0px;
                    transition: height 1s ease-in-out;
                    transform: scaleY(0);
                    float: left;
                    display: block;
                    border: 1px solid $grey; // placeholder to avoid shift on click
                    width: 100%;
                    position: static;
                }
                // Coveo Overrides
                ul.coveo-facet-values {
                    list-style: none;
                }

                .coveo-facet-search {
                    display: none;
                }
            }

            .open {
                &.CoveoFacet { // .accordionItem
                    opacity: 1;

                    .coveo-facet-header { // .accordionItemHeading
                        &:after {
                            content: url('../../Images/Base/dist/icon-caret-black.svg');
                            transform: rotate(180deg);
                            position: relative;
                            top: 5px;
                        }
                    }
                }

                .coveo-facet-values { // .accordionItemContent
                    transform: scaleY(1);
                    transform-origin: top;
                    transition: transform 200ms ease-in-out;
                    list-style: none;
                    height: 100%;
                    background-color: $light-grey;
                    border: none;
                    padding: 0 10px;
                    border-top: none;
                    width: 100%;

                    li.coveo-facet-search-button {
                        display: none;
                    }

                    li.coveo-facet-value {
                        font-size: 16px;

                        &:hover {
                            background-color: $light-grey;
                        }
                    }

                    li.coveo-facet-selectable {
                        border-bottom: none;

                        &:last-child {
                            border-bottom: none;
                        }

                        .coveo-facet-value-label {
                            margin: 0;

                            .coveo-facet-value-label-wrapper {
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                //flex-direction: row-reverse;
                                // Checkbox
                                .coveo-facet-value-checkbox {
                                    order: 1;
                                    width: 14px;
                                    height: 14px;
                                    margin-right: 5px;

                                    svg.coveo-facet-value-checkbox-svg {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                                // Item Count
                                .coveo-facet-value-count {
                                    color: $blue;
                                    font-weight: $bold;
                                    font-size: 14px;
                                    position: relative;
                                    top: 2px;
                                    order: 3;
                                    margin: 0;
                                    margin-left: auto;
                                }
                                // Item Name
                                .coveo-facet-value-caption {
                                    order: 2;
                                    margin-right: 10px;
                                    white-space: normal;
                                }
                            }
                        }
                    }
                }

                .coveo-facet-search, .coveo-facet-footer {
                    display: none;
                }
            }
            // Coveo Sort
            .coveo-sort-container {
                .CoveoSort {
                    display: inline-block;
                    width: 100%;
                    margin: 20px 0 10px 0;
                    @include secondary-button;

                    &.coveo-selected {
                        @include primary-button;
                        width: 100%;
                    }
                }
            }
        }
    }
}
