.title-with-print-button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sociallinks {
        position: static;
        width: auto;
        margin: 0;
        text-align: right;

        label {
            display: inline-block;
            margin: 0 3px 0;
        }

        input[type="checkbox"] {
            margin: 0;
        }
    }

    @include min-screen(md) {
        h1 {
            padding-right: 20px;
        }
    }
}
