.faculty-bio {
    padding-top: 10px;

    &:first-child {
        padding-top: 0;
    }

    img {
        width: 125px;
        float: left;
        margin-right: 15px;
    }

    .bio-title {
        margin-bottom: 15px;
    }
}

@import "facultycredentials-mobile";