@include max-screen(md) {
    .spotlight {
        .article-summary-wrap {
            flex-direction: column;

            .summary-text {
                margin-right: 0;
            }

            .summary-image {
                margin-left: 0;
            }
        }
    }
}
