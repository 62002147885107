﻿@include max-screen(md) {
    .donor_list {
        margin-top: -50px;
        margin-left: -40px;

        ul {
            padding: 0;
            margin: 0;
            list-style: none !important;

            li {
                font-size: 18px;
                color: black;
            }
        }
    }

}
