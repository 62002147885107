@include max-screen(xl) {
    .featuredcontent {
        .articlebox-image {
            height: 320px;
            overflow: hidden;
        }
    }
}
@include max-screen(lg) {
    .featuredcontent {
        .articlebox-image {
            height: 275px;
            overflow: hidden;
        }
    }
}
@include max-screen(md) {
    .featuredcontent {
        .articlebox-image {
            margin-bottom: 30px;
            height: auto;
            overflow: auto;
        }

        .articlebox-content {
            min-height: 0;
            margin-bottom: 80px;

            .subhead {
                height: auto;
            }

            .cta {
            }
        }
    }
}