@include max-screen(md) {
    .coi-form {
        padding-left: 0;
        padding-right: 0;

        h1 {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .coi-frame {
            textarea {
                width: 100%;
                height: 20em;
            }

            .question-wrapper {
                margin: 0;
                padding: 0;
            }

            .save-button {
                width: 100%;
            }
        }
    }
}
