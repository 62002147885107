.container {
    .row {
        // Purchase History ======================================= /*
        .Base_PersonifyDefault {
            font-family: $lato;
            font-size: 16px;
            margin-bottom: 15px;

            .panel-expand-link, .panel-collapsed-link {
                padding-left: 25px !important;
            }

            .divTableCell.expand-down-arrow {
                width: 50px;
            }

            .print-order-summary {
                margin-left: -15px !important;
            }

            .credit-card-tooltip {
                max-width: 400px;
                left: 295px;
                max-height: fit-content;
            }

            .RadComboBox .rcbInput[type="text"] {
                padding: 0px !important;
                width: 75.8px;
            }

            .expiration-date-row {
                label {
                    display: inline-block;
                }
            }

            .panel-add-card-expire .expire-dates-container {
                margin-right: 34.5px !important;
            }

            .autoRenewCheckBoxContainer {
                label {
                    display: inline !important;
                    margin-left: 10px !important;
                }
            }

            .tab-link {
                border-bottom: 0px !important;
                text-decoration: underline !important;
                text-underline-position: under !important;
                text-decoration-thickness: 2px !important;
            }

            .action-hyperlink {
                text-decoration-color: #F3C300 !important;
            }

            fieldset.panel-add-card-form {
                margin-top: -10px !important;
            }

            .pnlSaveCreditCard.panel-add-card-form {
                margin-bottom: -55px !important;
            }


            .buttonRow {
                a {
                    display: flex;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: 1.4px;
                    text-transform: uppercase;
                    color: #000;
                    background-color: #fff;
                    border: 1px solid #4C4B4B;
                    padding: 10px 15px;
                    margin-right: 15px;
                }
            }

            .cellProductName.ctrl-cell-b.mobile-way {
                width: 55% !important;
            }

            .cellAdditionalInfo.mobile-way.ctrl-cell-c {
                width: 10% !important;
            }

            span#ctl02_ctl00_ProductUMBBuyBoxControl_ProductPreviouslyPurchasedMessage
                {
                font-weight:bold;
                font-size:18px!important;
            }
        }

        input#ctl02_ctl00_KeywordSearch {
            border-radius: 0;
            border: 0;
            background-color: #FFF;
        }

        div#ctl02_ctl00_ShowRow {
            display: flex;
            justify-content: flex-start;
        }

        .RadAjaxPanel {
            &:last-child {
                margin-bottom: 100px;
            }

            .heading3 {
                @extend h1;
                margin-bottom: 30px;
            }

            .heading3.bord-low {
                border-bottom: none;
            }

            .ctrlContent {
                padding: 20px;
                background-color: rgba($color: $light-grey, $alpha: 0.5);

                .exp-col-title-text {
                    @extend h4;
                }
            }

            #content_0_twocolumnleft_1_ctl00_ShowRow.formRow, .formRow.checkBoxRow {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                label {
                    width: auto;
                }

                .formLayout .formRow input[type="checkbox"] {
                    &:focus {
                        border: none; // TODO
                    }
                }
            }

            .btnFilterSearch {
                @include primary-button;
                cursor: pointer;
                margin: 0 10px 0 30px;
            }

            .btnFilterClear {
                @include secondary-button;
                display: inline-flex;
            }

            .dateRangeContainer {
                margin: 20px 0;
                display: flex;
                align-items: center;
            }

            .utility {
                margin-bottom: 30px;
                display: inline-flex;

                &.showHideAllContainer {
                    margin-right: 20px;

                    span {
                        margin: 0 10px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }

            .ctrl-form-row {
                font-size: 16px;
            }

            .exp-col-container.purchase-hist-order-detail {
                margin: 20px 0 40px 0;

                .exp-col-title {
                    .exp-col-title-text {
                        @extend h4;
                    }
                }

                .exp-col-content {
                    background-color: rgba($color: $light-grey, $alpha: 0.5);
                    padding: 8px 12px;

                    .ctrl-form-row {
                        display: flex;
                        justify-content: space-between;

                        .ctrl-row-item {
                            width: 100%;
                        }
                    }
                }
            }

            .not-paid.ctrl-row-value, .not-paid .ctrl-row-item {
                color: $red;
            }
        }
        // Transcripts ======================================= /*
        .rcbSlide {
            .RadComboBoxDropDown.RadComboBoxDropDown_PersonifyDefault {
                width: 225px !important;
            }

            .RadComboBoxDropDown_Default {
                width: 225px !important;
            }
        }

        h4.heading-legend.heading4.h-color-secondary {
            @extend h3;
        }

        .formLayout {
            margin-bottom: 40px;

            .filter {
                background-color: rgba($color: $light-grey, $alpha: 0.5);
                padding: 20px;
            }
        }

        .formRow.ctrl-date-select {
            display: block;

            .filterGroup {
                @extend h4;
            }

            .ctrl-filter-from, .ctrl-filter-to {
                display: flex;

                label {
                    width: 80px;
                }

                .ResponsiveDatePicker_PersonifyDefault {
                    display: flex;

                    .responsive-dp-year, .responsive-dp-months, .responsive-dp-day {
                        margin-right: 40px;
                    }
                }
            }
        }

        .formLayout .formRow .ResponsiveDatePicker_PersonifyDefault.Profile_PersonifyDefault .responsive-dp-year select, .formLayout .formRow .ResponsiveDatePicker_PersonifyDefault.Profile_PersonifyDefault .responsive-dp-months select, .formLayout .formRow .ResponsiveDatePicker_PersonifyDefault.Profile_PersonifyDefault .responsive-dp-day select {
            max-width: 100px;
        }

        .TranscriptsControl_PersonifyDefault .formLayout .filter .filterGroup {
            float: none;
            display: block;
            width: auto;
        }

        .TranscriptsControl_PersonifyDefault.Profile_PersonifyDefault .ctrl-filter-to {
            margin-left: 0;
        }

        fieldset.filter .formRow.credit-type-ctrl .RadComboBox.RadComboBox_PersonifyDefault {
            margin-left: 0;
            width: 225px !important;
        }

        div#ctl02_ctl00_pnlContainerPanel {
            margin-bottom: 50px;
        }

        .buttonRow {
            display: inline-flex;
        }

        .btn-primary {
            @include primary-button;
            cursor: pointer;
            margin-right: 10px;

            &.linked-product-btn {
                display: table;
            }
        }

        .btn.cancel {
            @include secondary-button;
            cursor: pointer;
        }

        .flex-container-ctrl {
        }

        .exp-col-container {
            margin: 20px 0 40px 0;

            .exp-col-title {
                .exp-col-title-text {
                    @extend h4;
                }
            }

            .exp-col-content {
                padding: 8px 12px;

                .ctrl-form-row {
                    display: flex;
                    justify-content: space-between;
                    /*.ctrl-row-item {
                        width: 100%;
                    }*/
                }
            }
        }

        .totalCredits {
            font-size: 16px;
            font-weight: $bold;
            color: $blue;
        }
        // Shopping Cart ======================================== /*
        .RadSocialShare {
            display: none;
        }

        .ProductDetailLeftCell {
            width: 100%;
            margin: 15px;
            display: block;
        }

        .segmentsListPanel .controlButtonLink {
            position: relative;
            top: 0px;
            right: 0px;
        }

        .ProductDetailControl_PersonifyDefault .productbuttons {
            background-color: transparent;
        }

        .ProductDetailControl_PersonifyDefault .productbuttons:last-child {
            display: flex;
            justify-content: flex-start;
            padding: 20px;
        }

        .ProductDetailControl_PersonifyDefault .productbuttons .wishList {
            margin-bottom: 0;
            margin-top: 0;
        }

        .ProductDetailControl_PersonifyDefault .productActions {
            width: 100%;
            max-width: none;
            position: relative;
            border: none;
        }
        // List Price
        .productActions .PriceListUL {
            padding: 20px;
            background-color: $lighter_grey;
        }

        .PriceValueLabel {
            font-weight: $bold;
        }

        div#ctl02_ctl00_ProductGenericBuyBoxControl_QuantityContainer {
            display: flex;
        }

        .ctl02_ctl00_ProductGenericBuyBoxControl_QuantityContainer {
            label {
                text-transform: uppercase;
                letter-spacing: 0.2px;
                font-weight: $bold;
            }
        }
        // Quantity
        .ProductDetailControl_PersonifyDefault .productActions input.quantity {
            border: 1px solid #555;
            padding: 5px;
            width: auto;
            height: 40px;
            max-width: 100px;
        }

        .ProductDetailControl_PersonifyDefault .quantityContainer {
            padding: 20px;
            height: auto;
        }

        .savingsHeader {
            position: relative;
            margin-top: 8px;
            height: 26px;
            padding-top: 0;
            padding-left: 10px;
            color: #ffffff;
            background: $blue;
            font-weight: normal;
            margin: 10px 5px;
        }

        .savingsHeader .decore {
            position: absolute;
            left: 0;
            top: 0px;
            width: 17px;
            height: 26px;
            display: inline;
            background-image: none;
            background-repeat: no-repeat;
        }

        .savingsValue {
            font-size: 0.9em;
            color: $blue;
            width: 50%;
            text-align: center;
        }

        .ShoppingCartSavingsControl_PersonifyDefault {
            border: none;
        }
        // Cart Modal
        .RadWindow .rwTitleRow em {
            font: normal bold 12px "Segoe UI",Arial;
            color: inherit;
            padding: 5px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            float: left;
            font-size: 18px !important;
            letter-spacing: 0.5px;
            font-weight: 400;
        }

        .ShoppingCartAddedItemTemplateControl_PersonifyDefault .productName {
            color: inherit;
            font-family: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            white-space: normal;
        }

        .RadWindow .rwWindowContent {
            padding: 20px;
            margin: 0 auto;
        }

        .RadWindow .rwWindowContent > div {
            width: auto !important;
            height: auto !important;
        }

        .segmentsListPanel .RadListBox {
            width: 100%;
            margin-bottom: 20px;
        }

        .segmentsListPanel .RadListBox .rlbGroup ul li.rlbSelected, .segmentsListPanel .RadListBox .rlbGroup ul li.rlbItem {
            background: transparent;
            outline: none;
            text-decoration: underline;
            padding: 0;

            &:hover, &:focus {
                text-shadow: none;
            }
        }

        div.segmentsListPanel .RadListBox .rlbGroup ul li .rlbText {
            position: relative;
            top: -5px;
            color: $blue;
        }

        .segmentsListPanel .RadListBox .rlbGroup ul li.rlbHovered {
            .rlbText {
                color: $blue;
                text-decoration: none;
            }
        }

        .segmentsListPanel .controlButtonLink {
            position: initial;
        }

        ul.rlbList {
            list-style: none;
            margin: 0 0 20px 0;
            padding: 0;
            color: $blue;
        }

        a.continueShopping {
            @include primary-button;
            font-family: $lato;
            display: inline-flex;
            margin-right: 10px;
            text-decoration: none;
        }

        input[type=button].continueShopping {
            @include secondary-button;
            display: inline;
        }

        .controlPanel.segmentsListPanel {
            border-bottom: 1px solid $light-grey;
            padding-bottom: 30px;
        }

        .contentSection {
            display: flex;
            justify-content: space-between;
            margin: 30px 0;
            border-bottom: 1px solid $light-grey !important;
            padding-bottom: 30px;
        }

        .lineItemTotals {
            width: 100%;
            order: 2;
        }

        .productDescriptionSection {
            font-size: 16px;
            margin: 10px 0;
        }
        // .Base_PersonifyDefault {
        //     font-size: 14px;
        // }
        .productControlSection {
            display: flex;
            justify-content: flex-end;
            height: 100%;
            margin: 10px 30px;
            position: initial;
            bottom: 0;
            left: 0;
            width: 250px;
            background: transparent;
            border: none;
            border-bottom: none;
            border-left: none;
            padding: 0;
        }

        .ShoppingCartItemListControl_PersonifyDefault .productImage, .CheckoutItemSummaryControl_PersonifyDefault .productImage {
            width: 100%;
            height: 100%;
            max-width: 150px;
            visibility: visible;
        }

        a#ctl03_ctl00_OrderItemList_ShoppingCartItemsRepeater_ShoppingCartProductImageLink_0 {
            display: block;

            img#ctl03_ctl00_OrderItemList_ShoppingCartItemsRepeater_ShoppingCartProductImage_0 {
                width: 100%;
                height: 100%;
            }
        }

        .ShoppingCartItemListControl_PersonifyDefault .productImageSection, .CheckoutItemSummaryControl_PersonifyDefault .productImageSection {
            visibility: initial;
            padding: 0;
        }

        .productDescriptionSection {
            padding: 0;
        }

        .ShoppingCartItemListControl_PersonifyDefault .productDescriptionSection, .CheckoutItemSummaryControl_PersonifyDefault .productDescriptionSection {
            width: 100%;
            min-width: 200px;
        }

        a.productName {
            color: $black;
            font-family: $lato;
            font-size: 16px;
            font-style: normal;
            font-weight: $regular;
            text-decoration: none;
            white-space: normal;
        }

        .linkButton {
            @include secondary-button;
            display: inline;
            cursor: pointer;
            margin: 0 10px 0 0;
            width: auto;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background: $white !important;
            box-shadow: none;
            text-decoration: none;

            &:hover {
                background-color: #F4F4F4 !important;
            }
        }

        .centerSection {
            min-width: 120px;
            margin-right: initial;
            min-height: 0;
            border: none;
            position: initial;
            border-bottom: none;
            border-left: none;
            border-top: none;
            background: #ffffff;
        }

        table.totals {
            .borderTop {
                border-top: none;
            }
        }

        td.quantity {
            display: inline-flex;
            margin-right: 30px;
            width: 100%;
            height: 100%;
        }

        td.quantity input.update {
            @include secondary-button;
            display: inline-flex;
            margin-left: 40px;
            cursor: pointer;
        }

        input#ctl03_ctl00_OrderItemList_ShoppingCartItemsRepeater_ctl00_InputQty,
        .RadInput.RadInput_PersonifyDefault input.riEnabled,
        .RadInput.RadInput_PersonifyDefault input.riEnabled.riHover,
        .RadInput.RadInput_PersonifyDefault.RadInputFocused input.riEnabled,
        .RadInput.RadInput_PersonifyDefault.RadInputFocused input.riEnabled.riHover,
        .riTextBox.riEnabled.quantityInput,
        .RadInput.RadInput_PersonifyDefault.RadInputFocused input.riTextBox.riEnabled.riFocused.quantityInput,
        .RadInput.RadInput_PersonifyDefault.RadInputFocused input.riTextBox.riEnabled.riFocused.riHover.quantityInput,
        .RadInput_PersonifyDefault .riFocused,
        html body .riSingle .riTextBox,
        html body .riSingle .riTextBox.riFocused,
        html body .riSingle .riTextBox[type="text"],
        html body .riSingle .riTextBox[type="text"]:hover,
        html body .riSingle .riTextBox[type="text"]:focus,
        html body .riSingle .riTextBox[type="text"]:active {
            font-family: $lato;
            font-size: 16px !important;
            border: none !important;
            background-color: $light-grey !important;
            padding: 10px !important;
            width: 100% !important;
            min-width: 60px !important;
            height: 100%;
        }

        span#ctl03_ctl00_OrderItemList_ShoppingCartItemsRepeater_ctl03_InputQty_wrapper {
            input#ctl03_ctl00_OrderItemList_ShoppingCartItemsRepeater_ctl04_InputQty {
                font-family: $lato;
                font-size: 16px !important;
                border: none !important;
                background-color: $light-grey !important;
                padding: 10px !important;
                width: 100% !important;
                min-width: 60px !important;
                height: 100% !important;
            }
        }


        .ShoppingCartSummaryControl_PersonifyDefault {
            background-color: rgba($color: $light-grey, $alpha: 0.5);
            padding: 30px;
        }

        .lineItemTotals {
            .totals {
                .borderTop {
                    .quantity {
                        span {
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
        /*span#ctl02_ctl00_CheckoutItemSummaryControl_CheckoutItemsControl_ShoppingCartItemsRepeater_ctl00_labelQty {
            margin: 0 auto;
        }*/
        div#ctl04_ctl00_PanelHeader.header {
            background-color: transparent;
            color: $black;
            padding: 0;
            border: none;
        }

        div#ctl04_ctl00_PanelUpdate.summaryData {
            border: none;
            background: transparent;
        }

        .shippingEstimates {
            max-width: 450px;

            .item {
                display: block !important;
            }
        }

        .RadComboBox.RadComboBox_PersonifyDefault table {
        }

        .RadComboBox .rcbReadOnly .rcbInputCellLeft {
            background-position: 0 0;
        }

        .btn-default {
            @include secondary-button;
            font-family: $lato;
            display: inline;
            cursor: pointer;
            text-shadow: none !important;
            padding: 10px 15px !important;
            border-radius: 0;

            &.estimate {
                margin-left: 10px;
            }
        }

        .shippingEstimates .riTextBox.postal {
            font-family: "Lato",sans-serif;
            font-size: 16px;
            border: none;
            background-color: #EDECEC;
            padding: 10px !important;
            min-width: 150px;
            width: 100%;
            height: 46px;
        }

        .summary .RadInput {
            width: inherit !important;
        }

        .ShoppingCartSummaryControl_PersonifyDefault.ShoppingCart_PersonifyDefault.Base_PersonifyDefault {
            .checkout-panel {
                border: none;
                background: transparent;
                padding: 0;
            }
        }

        div#content_0_singlecolumn_1_ctl00_PanelHeader {
            span {
                @extend h4;
            }
        }

        .summary {
            .item {
                margin: 20px 0;
                display: flex;
                width: 100%;
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: center;

                &:after {
                    content: none;
                }

                .result {
                    font-size: 16px;
                }
                /* this is a last resort to target a specific element */
                &#ctl02_ctl00_CheckoutPaymentInformationControl_ShoppingCartSummaryControl_PanelShippingPreferences {
                    display: block;
                }

                span {
                    max-width: 200px;
                }
            }
        }

        #content_0_singlecolumn_1_ctl00_PanelShippingEstimates {
            .item {
                flex-direction: row;
                justify-content: flex-start;
            }
        }

        .summaryTotal {
            //color: $blue;
            .couponsAndDiscountsNote {
                display: block;
                color: $black;
                margin-bottom: 20px;
            }

            .dvTotal {
                span {
                    font-size: 16px;
                    font-weight: $bold;
                }
            }
        }
        // Checkout (Step 1 - Address Information) ======================================= /*
        .divWarning {
            background-color: #F3C300 !important;
            padding: 2px 5px;
        }

        .lblWarning {
            color: #000;
        }

        .backToCartDiv.buttonRow {
            margin: 20px 0 30px 0;

            .btn.cancel {
                height: 46px;
                align-items: center;
            }
        }

        .pnlShipToMultipleAddressesDisabled .name {
            font-size: 20px;
            color: inherit;
            text-shadow: none;
        }

        #content_0_singlecolumn_0_ctl00_WizardHeaderControl_ContainerPanel {
            margin-bottom: 50px;
        }

        div.WizardHeaderControl_PersonifyDefault span.step {
            background-color: $white;

            border: {
                top: 1px solid $light-grey;
                right: 1px solid $light-grey;
                left: 1px solid $light-grey;
                bottom: 3px solid $black;
            }

            padding: 20px 15px;
            width: 33%;
            text-align: left;
        }

        div.WizardHeaderControl_PersonifyDefault span {
            color: $black;
            font-weight: $regular;
            font-size: 18px;
            letter-spacing: 0.2px;

            span {
                width: auto;
                font-size: 18px;
            }
        }

        div.WizardHeaderControl_PersonifyDefault .step.active, div.afmWizardHeader .step {
            background-color: $light-yellow;
            border-bottom: 3px solid $yellow;
            padding: 20px 15px;
        }

        div.WizardHeaderControl_PersonifyDefault span.step3 {
            border-right: 1px solid $light-grey !important;
        }

        div.WizardHeaderControl_PersonifyDefault span.active span {
            color: $black;
            font-weight: $regular;
            font-size: 18px !important;
            letter-spacing: 0.2px;
            padding: 0 !important;
        }

        .CheckoutControl_PersonifyDefault .step-number {
            display: none;
        }

        h2.checkoutHeader.heading2.h-color-primary {
            @extend h3;
        }
        // div#checkout-cancel-dialog-modal {
        //     margin: 30px 0;
        // }
        .CheckoutAddressInformationControl_PersonifyDefault {
            max-width: none;
        }

        .billingAddress {
            margin: 0;
            font-size: 20px;
        }

        span#ctl02_ctl00_CheckoutAddressInformationControl_BillingAddressChooserControl_lblCurrentlySelectedAddressCustomer {
            font-size: 20px;
        }

        .billingAddress .instruction {
            font-size: 16px !important;
            line-height: 1.2;
            color: $blue;

            &:before {
                content: "! ";
                color: $blue;
                font-weight: $regular;
                font-size: 16px;
                text-shadow: none;
                line-height: 1.2;
            }
        }

        .billingAddress p, .billingAddress .name, .billingAddress address, .pnlShipToMultipleAddressesDisabled address {
            font-size: initial;
            color: $black;
            text-shadow: none;
        }

        .addressButtonsDiv .addressButton {
            color: $black !important;
            width: auto;
            background: $white !important;
            box-shadow: none;

            &:hover {
                background-color: $light-grey; // ?
            }
        }

        .prevNextCancelButtons {
            border-top: none;
        }

        input#NextButton {
            height: 46px;
        }
        // Checkout (Step 2 - Item Summary) ======================================= /*
        div#ctl02_ctl00_CheckoutItemSummaryControl_ShoppingCartSummaryControl_Container,
        div#ctl02_ctl00_CheckoutPaymentInformationControl_ShoppingCartSummaryControl_ContainerPanel {
            .header {
                padding: 0;
                color: inherit;
                background: transparent;
                border: none;
                font-weight: 700;
                font-size: 1.2em;
            }
        }

        div#ctl02_ctl00_CheckoutItemSummaryControl_ShoppingCartSummaryControl_PanelUpdatePanel,
        div#ctl02_ctl00_CheckoutPaymentInformationControl_ShoppingCartSummaryControl_PanelUpdatePanel {
            .summaryData {
                padding: 0;
                border-left: none;
                border-right: none;
                background: transparent;
            }
        }

        div#ctl02_ctl00_CheckoutItemSummaryControl_ShoppingCartSummaryControl_PanelShippingPreferences {
            display: block;
        }

        .summaryControl {
            margin-left: 30px;
            margin-top: 30px;
            margin-bottom: 30px;
            float: right;
            display: block;
            width: auto;
            max-width: 360px;
        }

        div#ctl02_ctl00_CheckoutItemSummaryControl_ShoppingCartSummaryControl_PanelAddCoupon {
            flex-direction: row;

            .coupon .couponInput {
                background-color: white;
            }
        }

        div#content_0_singlecolumn_0_ctl00_CheckoutItemSummaryControl_ShoppingCartSummaryControl_PanelHeader {
            span {
                @extend h3;
            }
        }

        div#content_0_singlecolumn_0_ctl00_CheckoutItemSummaryControl_ShoppingCartSummaryControl_PanelShippingPreferences {
            flex-direction: row;
            justify-content: flex-start;
        }

        span#content_0_singlecolumn_0_ctl00_CheckoutItemSummaryControl_ShoppingCartSummaryControl_lblShippingNotesMessage {
            margin: 30px 0;
            display: block;
        }

        div#content_0_singlecolumn_0_ctl00_CheckoutItemSummaryControl_ShoppingCartSummaryControl_PanelAddCoupon {
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 0;
        }

        div#ctl02_ctl00_CheckoutItemSummaryControl_CheckoutCancelPopup_wndCancelConfirmation_C {
            height: 200px !important;
        }

        div#ctl03_ctl00_OrderItemList_DeleteWindow_DeleteRadWindow_C {
            height: 200px !important;
        }
        // Checkout (Step 3 - Payment) ======================================= /*
        .leftPane {
            max-width: 730px;

            .summaryTopDiv {
                float: left;
                margin-top: 1em;
                margin-bottom: 2em;
                border: none;
                background-color: transparent;
                border-radius: 0;

                .summaryTopColumn address {
                    font-size: 16px !important;
                    line-height: 1.2 !important;
                }
            }

            .summaryTopDiv .summaryTopColumn {
                width: 100%;
            }
        }

        h3.summaryTopHeader.heading3.h-color-secondary {
            @extend h3;
        }

        h3.control-title.heading3.h-color-secondary {
            @extend h3;
        }

        div#content_0_singlecolumn_0_ctl00_CheckoutPaymentInformationControl_ShoppingCartSummaryControl_PanelHeader {
            span {
                @extend h3;
            }
        }

        div#content_0_singlecolumn_0_ctl00_CheckoutPaymentInformationControl_ShoppingCartSummaryControl_PanelShippingPreferences {
            flex-direction: row;
        }

        .prevNextCancelButtons.buttonRow {
            margin-bottom: 50px;
            display: inline-flex;
            width: 100%;

            #PreviousButton {
                color: $black;
                text-shadow: none;
                height: 46px;
            }
        }

        fieldset.panel-add-card-form input[type="text"] {
            border: none;
            border-radius: 0;
            padding: 10px;
            min-height: 0;

            .combobox {
                .rcbReadOnly {
                    input[type="text"] {
                        padding: 0;
                    }
                }
            }
        }

        input#ctl02_ctl00_CustomerPaymentsControl_cbMonth_Input {
            padding: 0;
        }

        input#ctl02_ctl00_CustomerPaymentsControl_cbYear_Input {
            padding: 0;
        }

        input#ctl02_ctl00_cbMonth_Input {
            padding: 0;
        }

        input#ctl02_ctl00_cbYear_Input {
            padding: 0;
        }



        input#ctl02_ctl00_CheckoutPaymentInformationControl_CustomerPaymentsControl_cbMonth_Input {
            padding: 0;
        }

        input#ctl02_ctl00_CheckoutPaymentInformationControl_CustomerPaymentsControl_cbYear_Input {
            padding: 0;
        }


        #ctl02_ctl00_rptrCreditCards_ctl00_AddressControl_EditButton {
            position: relative;
            margin-left: 15px;
        }


        .validatedMessage {
            background-color: transparent;
        }
        //Open Orders ================================================== /*
        .ctrl-form-row.ctrl-pay-open-oder .ctrl-row-item.ctrl-row-label.not-paid {
            color: inherit;
        }

        .ctrl-form-row.ctrl-pay-open-oder .ctrl-profile-row-header.ctrl-row-label {
            max-width: 80px;
        }

        .ctrl-row-item.ctrl-row-value a {
            color: $blue;
        }

        .balanceContainer {
            color: inherit;
        }
        // Donation History =============================================== /*
        .DonationHistoryControl_PersonifyDefault.Profile_PersonifyDefault.Base_PersonifyDefault {
            // .printPreview {
            //     margin-bottom: 30px;
            // }
            .exp-col-container .exp-col-content {
                padding: 0;
            }

            .formRow {
                display: flex;
                flex-direction: column;

                label#content_0_twocolumnleft_1_ctl00_lblFilterDateFrom,
                label#content_0_twocolumnleft_1_ctl00_lblFilterDateTo {
                    padding: 0;
                }
            }

            .buttonRow.filterSearchButtons {
                margin-top: 50px;

                .btn.btnFilterSearch {
                    margin: 0;
                }
            }

            .ctrl-form-row.ctrl-row-header-colored {
                margin-top: 30px;
            }
        }

        .RadWindow {
            position: fixed !important;
            top: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
            right: 0 !important;
            margin: auto !important;
        }

        .RadWindow_PersonifyDefault {
            border: none;
        }
    }
}

#ctl03_ctl00_LoadingPanelIdctl03_ctl00_PanelShoppngCart {
    display: none !important;
}

.RadWindow_PersonifyDefault {
    &.shopping-cart-item-edit-control {
        .rwTable {
            .rwWindowContent {
                #ctl03_ctl00_OrderItemList_ItemEditControl_EditItemWindow_C {
                    width: auto !important;
                    height: auto !important;
                }

                #ctl03_ctl00_OrderItemList_ItemEditControl_EditItemWindow_C_Container {
                    width: auto !important;
                }
            }
        }
    }
}

.option {
    .umbrellaOptionPicker {
        .rlbGroup {
            .rlbList {
                display: flex !important;
                flex-wrap: wrap !important;
            }
        }
    }
}

/*.row {
    .mobile-way {
        display: block;
    }
}*/

#gritter-notice-wrapper {
    background-color: #F7F7F7;
    border-radius: 0.3rem;
    border: 1px solid #4C4B4B;
}

.gritter-item p {
    color: #4C4B4B;
    text-align: center;
}

.gritter-title {
    text-align: center;
    color: #4C4B4B;
    text-shadow: none !important;
}

.ctrl-form-row {
    border-bottom: 1px solid rgb(234, 234, 234);
    padding: 0.5em 0.3em;
    position: relative;
    width: 100%;
    display: inline-block !important; /* This is to avoid rendering bug with float:right in Chrome */
}

.renewCCCheckbox label {
    display: inline !important;
}

.printPopup .printLink {
    justify-content: center;
    font-size: 14px !important;
    font-weight: 700 !important;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    border: 1px solid #F8DF8D;
    background-color: #F8DF8D;
    padding: 10px 15px;
    margin: 10px 10px 10px 30px;
}

.printPopup .closeLink {
    justify-content: center;
    font-size: 14px !important;
    font-weight: 700 !important;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    background-color: #fff;
    border: 1px solid #4C4B4B;
    padding: 10px 15px;
    display: inline-flex;
    margin: 10px 10px 10px 30px;
}

h3.checkoutHeader.orderNumber.heading3.h-color-default {
    color: #069;
}

.RadAjax_PersonifyDefault .raColor {
    background-color: transparent !important;
}

@import "personifytheme-mobile";

@media (min-width: 1025px) {
    .container .row .lineItemTotals {
        width: 35%;
        flex: 0 0 auto;
    }

    .container .row .centerSection {
        min-width: auto;
        width: 25%;
        flex: 0 0 auto;
    }

    .container .row .productControlSection {
        width: 40% !important;
        flex: 0 0 auto;
    }
}

#RadWindowWrapper_ctl02_ctl00_CheckoutPaymentInformationControl_CustomerPaymentsControl_AddressControlPaypal_EditWindow {
    position: absolute !important;
    height: 0px !important;
}