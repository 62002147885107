@include max-screen(md) {
    .featuredresultstemplate {
        .articlebox-image {
            height: 100px;

            .shapes-overlay {
                background: url('../../Images/Base/dist/splash-shapes-alt2.svg') no-repeat -80px -240px; // L/R, UP/DN
            }
        }
    }
}
