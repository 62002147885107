.reshape-splash-menu {
    z-index: 4;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    margin: 0 auto;
    border-bottom: 1px solid #C5C5C5;

    .container {
        max-width: unset;
    }

    .content {
        padding-left: 15px;
        padding-right: 15px;
        margin-right: auto;

        @media (min-width: 768px) {
            max-width: 768px;
        }

        @media (min-width: 1024px) {
            max-width: 1024px;
        }

        @media (min-width: 1190px) {
            max-width: 1200px;
        }

        @media (min-width: 1400px) {
            max-width: 1440px;
        }
    }

    ul {
        @include list-reset;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        justify-content: left;

        li:first-child {
            text-transform: uppercase;
            font-weight: bold;
            color: #5f59aa;
        }

        li {
            margin-right: auto;

            a, a:visited {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 15px 20px;
                background-color: transparent;
                font-size: 18px;
                line-height: 1.2;
                color: $black;

                &:after {
                    content: url('../../Images/Base/dist/icon-arrow-right.svg');
                    transition: all 200ms ease-in-out;
                    padding-left: 15px;
                    position: relative;
                    top: 1px;
                    right: 5px;
                }

                &:hover, &:focus {
                    text-decoration: none;

                    &:after {
                        position: relative;
                        top: 1px;
                        right: -5px;
                    }
                }
            }
        }
    }

    &.stick {
        position: fixed;
        top: 50px;
    }
}

@import "reshapefeaturedlinks-mobile";