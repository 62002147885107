.introsplash {
    background-color: $blue;
    min-height: 220px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    .content-wrap {
        margin: 15px 0;

        h1, span {
            color: $white;
            text-align: center;
            display: block;
        }

        h1 {
            margin-bottom: 20px;
        }

        span {
            font-size: 18px;
            font-weight: $bold;
        }
    }

    .shapes-overlay {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // background: url('../../Images/Base/dist/patterngradient.png') no-repeat 200px -260px; // L/R, UP/DN
        background: url('../../Images/Base/dist/patterngradient-full.png') no-repeat center -200px; // L/R, UP/DN
    }
}
