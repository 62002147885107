.calltoactiontile {
    margin: 0 auto 50px auto;

    .tile-wrap {
        margin: 45px 0;
        min-height: 380px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .circle {
            background-color: $light-grey;
            border-radius: 50%;
            width: 110px;
            height: 110px;
            position: relative;
            margin-bottom: 30px;

            .icon {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 65%;
                }
            }
        }

        h3 {
        }

        span {
            display: block;
            font-size: 18px;
            font-weight: $bold;
            margin-bottom: 30px;
        }

        .cta {
            @include cta-button-outline;
            display: inline-block;
            color: $black;
            margin: auto 0 60px 0;

            &:hover, &:focus {
                color: $black;
            }
        }

        hr {
            border-top: 1px solid $light-grey;
            width: 100%;
            max-width: 550px;
            margin-left: 0px;
            height: auto;
        }

        &.blue {
            .circle {
                background-color: $blue;
            }

            span {
                color: $blue;
                display: block;
                width: 100%;
            }

            .cta {
                border: 1.2px solid $blue;
            }

            hr {
                border-top: 1.2px solid $blue;
            }
        }

        &.aqua {
            .circle {
                background-color: $cyan;
            }

            span {
                color: $cyan;
            }

            .cta {
                border: 1.2px solid $cyan;

                &:after {
                    content: url('../../Images/Base/dist/icon-arrow-right-cyan.svg');
                }

                &:hover, &:focus {
                    color: $black;
                }
            }

            hr {
                border-top: 1.2px solid $cyan;
            }
        }
    }
}

@import "calltoactiontile-mobile";