@include max-screen(xl) {
    .fasa-member-box {
        a.fasa-member-image, a.fasa-member-image:visited {
            height: 185px;
        }
    }
}

@include max-screen(lg) {
    .fasa-member-box {
        a.fasa-member-image, a.fasa-member-image:visited {
            height: 150px;
        }
    }
}

@include max-screen(md) {
    .fasa-member-box {
        a.fasa-member-image, a.fasa-member-image:visited {
            height: auto;
        }
    }
}
