.couponbanner-placeholder {
    display: block;
    width: 100%;
    height: 46px;
}

.couponbanner-display {
    background-color: #007cba;
    padding: 10px 0;
    top: inherit;
    left: 0;
    right: 0;
    z-index: 99;


    .couponbanner-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;

        a {
            color: $white;
            border-bottom: 2px solid $white;
            padding: 0 2px 2px 2px;

            &:hover {
                color: $blue;
                background-color: $white;
                text-decoration: none;
            }
        }

        span {
            color: $white;
            font-size: 18px;
            font-weight: 500;
        }

        button.close {
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            color: $white;
        }
    }
}
