.header-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    background: white;
}

.site-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    background: white;
}

header {
    &.mobile {
        display: none;
    }

    &.desktop {
        width: 100%;
        height: 100px;
        background-color: transparent;
        border-bottom: 1px solid transparent;
        transition: all 200ms ease-in-out;
        z-index: 11;
        // Sitecore "Edit Mode"
        body.edit-mode & {
            position: static;
        }

        .top-nav {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 100px;

            .burger-wrap {
                background-color: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90px;
                height: 100%;
                margin-right: 60px;
                border: none;

                @at-root header.desktop.fill button.burger-wrap {
                    background-color: $light-grey !important;
                }

                &:hover {
                    text-decoration: none;
                    outline: none;
                    cursor: pointer;
                }

                &:focus {
                    text-decoration: none;
                    cursor: pointer;
                }

                .hamburger {
                    padding: 5px;
                    transition: all 200ms ease-in-out;

                    .burger {
                        @at-root header.desktop.fill .burger {
                            stroke: $blue;
                        }
                    }

                    .label {
                        display: block;
                        font-size: 10px;
                        font-weight: $extra-bold;
                        letter-spacing: 2px;
                        color: $white;
                        text-transform: uppercase;
                        position: relative;
                        left: 1px;
                        top: 2px;

                        @at-root header.desktop.fill .label {
                            color: $blue !important;
                        }
                    }
                }

                &:hover {
                    .hamburger {
                        opacity: 0.65;
                    }
                }
            }

            .logo {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                img {
                    width: 243px;
                    // height: 60px;
                    transition: all 200ms ease-in-out;
                }

                .color {
                    display: none;
                }

                .white {
                    display: block;
                    width: 243px;
                }

                .inner-page &, &.color {
                    .white {
                        display: none;
                    }

                    .color {
                        display: block;
                        width: 243px;
                    }
                }
            }

            .utility-nav {
                @include list-reset;
                margin-left: auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                padding-right: 15px;

                li {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 100%;

                    a, a:visited, button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        //height: 100%;
                        padding: 10px;
                        background-color: transparent;
                        border: none;
                        position: relative;

                        label.marker {
                            position: absolute;
                            bottom: 4px;
                            left: 18px;
                            background-color: $yellow;
                            border-radius: 50%;
                            padding: 2px;
                            font-size: 12px;
                            font-weight: bold;
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                        }

                        &.closeUtilityNav {
                            float: right;
                            position: absolute;
                            top: 12px;
                            right: 12px;

                            img {
                                width: 10px;
                            }
                        }

                        &.search {
                            display: inline-block;
                        }

                        &:hover {
                            opacity: 0.65;
                            cursor: pointer;
                        }

                        @at-root header.desktop.fill .bell-icon {
                            fill: $blue;
                            transition: all 200ms ease-in-out;
                        }

                        @at-root header.desktop.fill .cart-icon {
                            fill: $blue;
                            transition: all 200ms ease-in-out;
                        }

                        @at-root header.desktop.fill .account-icon {
                            fill: $blue;
                            stroke: $blue;
                            transition: all 200ms ease-in-out;
                        }
                    }
                    // Dropdown -------------------------------- *
                    &.has-dropdown {
                        .dropdown {
                            display: none;
                            position: absolute; // Full-width
                            top: 100px;
                            z-index: 100;
                            width: 100%;
                            max-width: 300px;
                            padding: 0;
                            background-color: $white;
                            // border: 1px solid $light-grey;
                            border-top: 5px solid $blue;
                            cursor: default;
                            transition: all 200ms ease-in-out;

                            h3 {
                                margin: 20px 30px;
                                line-height: 1.2;
                            }

                            .summary {
                                display: block;
                                margin: 10px 30px;
                                line-height: 1.3;
                                color: $blue;
                                font-size: 13px;
                                margin-top: 0;

                                a {
                                    color: $blue;
                                    justify-content: unset;
                                    padding: 0 0 10px 0;
                                }
                            }

                            ul {
                                margin: 0;
                                padding: 0;
                                color: $dark-grey;
                                font-size: 13px;

                                li {
                                    display: block;
                                    background-color: $white;
                                    padding: 10px 15px 10px 30px;
                                    border-top: 1px solid $dark-grey;
                                    margin-bottom: 0;

                                    &:last-child {
                                        border-bottom: 1px solid $dark-grey;
                                    }

                                    span {
                                        display: block;
                                        margin: 0;
                                        //padding: 15px 30px;
                                        padding: 0;
                                        font-size: 16px;
                                        color: $black;
                                        width: 100%;
                                        height: 100%;

                                        &.isExpiredType {
                                            text-decoration: line-through;
                                        }

                                        &.isExpiredDate {
                                            color: red;
                                            font-weight: bold;
                                        }
                                    }

                                    a, a:visited {
                                        display: block;
                                        margin: 0;
                                        //padding: 15px 30px;
                                        padding: 0;
                                        font-size: 16px;
                                        color: $black;
                                        width: 100%;
                                        height: 100%;

                                        &:focus {
                                            background-color: $blue;
                                            color: $white;
                                            opacity: 1;
                                        }
                                        /*&:hover {
                                            background-color: white;
                                            text-decoration: none;
                                        }*/
                                    }

                                    &:hover:not(.staticSubUtility), &:focus:not(.staticSubUtility) {
                                        background-color: $blue;

                                        &.notification-item {
                                            color: $white;
                                        }

                                        a, a:visited {
                                            //background-color: $blue;
                                            color: $white;
                                            opacity: 1;

                                            &:hover, &:focus {
                                                text-decoration: none;
                                            }
                                        }

                                        .notif-item {
                                            .notif-close {
                                                svg {
                                                    stroke: $white;
                                                }
                                            }
                                        }
                                    }

                                    .notif-item {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: flex-start;

                                        .notif-close {
                                            display: inline-block;
                                            padding: 0 8px;
                                            flex: 0 0 auto;

                                            img, svg {
                                                width: 10px;
                                                stroke: $black;
                                                transition: all 200ms ease-in-out;
                                            }
                                        }
                                    }

                                    &:hover, &:focus {
                                        a, a:visited {
                                            //background-color: $blue;
                                            color: $white;
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                        /*&:hover, &:focus {
                            .dropdown {
                                display: block;
                            }
                        }*/
                    }
                }

                .account-wrap {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: 20px;

                    .account-icon-svg-desktop {
                        margin-right: 15px;
                    }

                    .username {
                        text-align: left;
                        margin-left: 5px;

                        .first-name, .last-name {
                            display: block;
                            font-size: 12px;
                            line-height: 14px;
                            font-weight: $bold;
                            letter-spacing: 1.4px;
                            color: $white;
                            text-transform: uppercase;

                            @at-root header.desktop.fill .first-name, header.desktop.fill .last-name {
                                color: $blue !important;
                            }
                        }
                    }
                }

                .search-wrap {
                    .CoveoSearchbox .magic-box .magic-box-input {
                        border-radius: 0;
                    }

                    .CoveoSearchbox {
                        background-color: $light-grey;
                    }

                    input {
                        width: 300px;
                        border: none;
                        //background-color: rgba($color: $ivory, $alpha: 0.5);
                        background-color: transparent;
                        padding: 7px 45px 7px 15px;
                        color: $white;

                        &:focus {
                            outline: none;
                        }

                        &::placeholder {
                            color: $white;
                        }

                        @at-root header.desktop.fill .search-wrap input {
                            color: $black !important;

                            &::placeholder {
                                color: $black;
                            }
                        }
                    }

                    a.search {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        left: -40px;
                        cursor: pointer;

                        .search-icon {
                            @at-root header.desktop.fill .search-icon {
                                fill: $black;
                            }
                        }
                    }
                    // Coveo Overrides
                    .CoveoSearchInterface {
                        background-color: transparent;
                        min-height: 0;

                        .CoveoSearchbox {
                            margin-bottom: 0;

                            .magic-box .magic-box-input {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }

        &.fill {
            background-color: $white;
            border-bottom: 1px solid $light-grey;
            z-index: 1040;

            .top-nav {
                transition: all 200ms ease-in-out;

                a.burger-wrap, a:visited.burger-wrap {
                    background-color: $light-grey;
                }

                .utility-nav {
                    li {
                        a, a:visited {
                            &.closeUtilityNav {
                                &:hover, &:focus {
                                    opacity: 0.6;
                                    background-color: $white;
                                }
                            }

                            &:hover, &:focus {
                                //background-color: $blue;
                                opacity: 1;
                            }
                        }

                        a.search {
                            &:hover, &:focus {
                                background-color: transparent;
                                opacity: 0.6;
                            }
                        }

                        &.bell {
                            a, a:visited {
                                &:hover, &:focus {
                                    .bell-icon {
                                        fill: $white;
                                    }
                                }
                            }
                        }

                        &.cart {
                            a, a:visited {
                                &:hover, &:focus {
                                    .cart-icon {
                                        fill: $blue;
                                        opacity: 0.6;
                                    }
                                }
                            }
                        }

                        &.account-wrap {
                            a, a:visited {
                                &:hover, &:focus {
                                    .account-icon {
                                        fill: $blue;
                                        opacity: 0.6;
                                    }
                                }
                            }

                            &:hover, &:focus {
                                a.account-menu-item, a.account-menu-item:visited {
                                    background-color: $blue;

                                    .account-icon {
                                        fill: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.shrink {
            height: 58px;

            .top-nav {
                height: 58px;

                a.burger-wrap, a:visited.burger-wrap {
                    margin-right: 25px;
                }

                .logo {
                    img {
                        width: 176px;
                        height: 42px;
                    }
                }

                .utility-nav {
                    li {
                        &.has-dropdown {
                            .dropdown {
                                top: 58px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.simple {
        img {
            margin: 30px 0 10px 0;
        }
    }
}

.subNav-Direct {
    margin-left: 5px;
}


// Navigation Overlay
.overlay {
    display: block;
    height: 100%;
    width: 600px;
    position: fixed;
    top: 0;
    z-index: 12;
    left: -1600px;
    background: $white;
    overflow-y: scroll;
    transition: 500ms;

    &.sub-item-open {
        overflow-y: hidden;
    }

    .overlay-content {
        position: relative;
        left: 0;
        width: 100%;
        text-align: center;
        display: block;
        height: auto;

        nav {
            display: block;

            .overlay-header {
                display: flex;
                justify-content: space-between;
                margin: 20px 0 70px 0;
                padding: 0 100px;

                .closeNav {
                    background-color: transparent;
                    border: none;

                    .close {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 26px;
                        }

                        span {
                            display: block;
                            font-size: 14px;
                            font-weight: $extra-bold;
                            color: $dark-grey;
                            text-transform: uppercase;
                            letter-spacing: 1.9px;
                            padding: 5px 0;
                        }
                    }

                    &:hover {
                        text-decoration: none;
                        opacity: 0.6;
                        outline: none;
                        cursor: pointer;
                    }

                    &:focus {
                        text-decoration: none;
                        opacity: 0.6;
                        cursor: pointer;
                    }
                }
            }

            ul {
                @include list-reset;

                &.main {
                    font-weight: $bold;
                    font-size: 20px;
                    line-height: 1.25;
                    padding-bottom: 20px;
                    border-bottom: 1px solid $dark-grey;
                }

                &.secondary {
                    font-weight: $bold;
                    font-size: 14px;
                    text-transform: uppercase;
                    letter-spacing: 1.4px;
                    line-height: 2;
                    padding: 20px 0;
                    margin-bottom: 60px;
                }

                li {
                    width: 100%;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    transition: all 200ms ease-in-out;

                    &.primary {
                        padding: 10px 100px;
                    }

                    &.subNav {
                        display: block;
                    }


                    a, button {
                        color: $black;
                        display: inline-block;
                        border: none;
                        background-color: transparent;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 1.25;
                        text-align: left;
                        padding-bottom: 1px;

                        &.subNav {
                            padding: 15px 100px;
                        }

                        &:hover {
                            text-decoration: none;
                            color: $dark-grey;
                            border-bottom: none;
                            padding-bottom: 1px;
                            outline: none;
                        }

                        &:focus {
                            text-decoration: none;
                            color: $dark-grey;
                            border-bottom: none;
                            padding-bottom: 1px;
                        }

                        &.subDirectLink {
                            padding: 10px 100px;
                            display: block;
                        }
                    }

                    .locked::after {
                        @include icon-lock;
                        font-size: 16px;
                        bottom: 1.2px;

                        &:hover, &:focus {
                            color: $white;
                        }
                    }

                    @media (any-hover:hover) { // No touch
                        &:hover, &:focus {
                            background-color: $blue;

                            button, a, a:visited {
                                display: block;
                                cursor: pointer;
                                /*width: 100%;
                                height: 100%;*/
                                color: $white;

                                &.closeSubNav {
                                    color: $black;
                                }
                            }

                            &.has-dropdown {
                                &:after {
                                    content: url('../../Images/Base/dist/icon-caret-right-white.svg');
                                    margin-left: 50px;
                                }
                            }
                        }
                    }

                    &.has-dropdown {
                        &:after {
                            content: url('../../Images/Base/dist/icon-caret-right-black.svg');
                            margin-left: 50px;
                            display: inline-block;
                            width: 7px;
                            height: 25px;
                        }

                        .sub-overlay {
                            display: block;
                            height: 100%;
                            width: 600px;
                            position: fixed;
                            z-index: 3;
                            top: 70px;
                            left: -1600px;
                            background: $white;
                            overflow-y: scroll;
                            transition: 300ms;

                            .closeSubNav {
                                display: block;
                                font-size: 24px;
                                text-transform: uppercase;
                                cursor: pointer;
                                margin: 30px 0;
                                padding: 0 100px;

                                &:before {
                                    content: url('../../Images/Base/dist/icon-arrow-left.svg');
                                    margin-right: 15px;
                                }
                            }

                            .sub-overlay-content {
                                width: 100%;
                                right: 0;
                                padding: 30px 0;
                                background-color: $light-grey;

                                .sub-overlay-header {
                                    padding: 0 100px;

                                    .parent-title {
                                        font-weight: $bold;
                                        font-size: 20px;
                                        line-height: 1.25;
                                        text-transform: uppercase;
                                    }
                                }

                                ul {
                                    font-weight: $regular;
                                    font-size: 20px;
                                    line-height: 1.25;
                                    margin: 20px 0 60px 0;

                                    li {
                                        padding: 15px 100px;
                                        color: $black;

                                        &.subNav {
                                            padding: 0;
                                        }

                                        button, a, a:visited {
                                            color: $black;
                                            font-weight: $regular;

                                            &.subNav {
                                                padding: 15px 100px;
                                            }
                                        }

                                        @media (any-hover:hover) { // No touch
                                            &:hover, &:focus {
                                                color: $white;

                                                button, a, a:visited {
                                                    color: $white;
                                                    padding-bottom: 1px;

                                                    &.subNav {
                                                        padding-bottom: 15px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


    }
}

@import "header-mobile";
