.article-link {
    margin-bottom: 20px;
    span {
        display: block;
        font-size: 14px;
        color: $grey;
    }
    a, a:visited {
        @include link;
    }
}

.updn-wrap {
    display: inline-flex;
    width: 100%;
    margin: 20px 0;
    justify-content: flex-end;
    .save-button {
        margin-left: 10px;
        font-size: 12px;
    }
}