.interstitialModal {
    /*display: block;*/

    .modal-header {
        border-bottom: none;
    }

    .modal-body {
        padding: 10px 20px;

        .alert-message {
            display: block;
            text-align: center;

            font: {
                weight: $bold;
                size: 14px;
            }

            line-height: 16px;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            margin-bottom: 10px;
        }

        p {
            line-height: 1.2;
            margin-bottom: 10px;
        }
    }

    .modal-footer {
        border-top: none;
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        .cta {
            &.cancel {
                @include secondary-button;
                font-size: 12px;
            }

            &.save {
                @include primary-button;
                font-size: 12px;
            }
        }
    }
}
