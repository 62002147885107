.livechatbutton {
    padding: 0 2px 2px 2px;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid #F3C300;
    border-left: 2px solid transparent;
    color: #4C4B4B;

    &:hover {
        text-decoration: none;
        background-color: #F8DF8D;
        color: inherit;
        border-top: 2px solid transparent;
        border-right: 2px solid transparent;
        border-bottom: 2px solid #F8DF8D;
        border-left: 2px solid transparent;
    }
}

.live-chat-button-container {
    margin-bottom: 25px;
}
