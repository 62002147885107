.splashmain {
    height: 100%;
    max-height: 726px;
    position: relative;
    overflow: hidden;
    display: block;

    img {
        width: 100%;
        //height: 726px;
    }

    &.row {
        margin: 0 auto;
    }

    .gradient-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent linear-gradient(0deg, transparent 0%, transparent 75%, rgba($color: $black, $alpha: 0.75) 100%);
    }

    .shapes-overlay {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('../../Images/Base/dist/splash-shapes.svg') no-repeat -360px -350px; // L/R, UP/DN
    }

    .splash-content {
        position: absolute;
        z-index: 3;
        //top: 62%;
        right: 0;
        bottom: 0;
        left: 0;
        padding-bottom: 20px;

        &.white-content {
            h1 {
                color: $white;
            }

            .cta {
                color: $white;
                border: 1px solid $white;

                &:after {
                    content: url('../../Images/Base/dist/icon-arrow-right-white.svg');
                }
            }
        }

        h1 {
            max-width: 35%;
            color: $black;
            line-height: 1.1;
        }

        .cta {
            @include cta-button-outline;
            display: inline-flex;
            color: $blue;
            border: 1px solid $blue;
            margin: 20px 0;
            max-width: 30%;

            &:after {
                content: url('../../Images/Base/dist/icon-arrow-right.svg');
            }

            &:hover, &:focus {
                color: $white;
                border: 1px solid $orange;
                background-color: $orange;

                &:after {
                    content: url('../../Images/Base/dist/icon-arrow-right-white.svg');
                }
            }
        }
    }
}

@import "superherobanner-mobile";