@include max-screen(md) {
    .eventsubmit {
        p {
            margin-bottom: 20px;
        }
    }

    .submit-eventwrap {
        .intro {
            margin-top: 30px;
        }

        .submit-eventdetails, .submit-contactdetails {
            .field-wrap {
                margin: 0 0 20px 0;
            }
        }
    }
}
