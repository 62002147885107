.search-frame {
    button.load-more {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        color: #000;
        border: 1px solid #f8df8d;
        background-color: #f8df8d;
        padding: 10px 15px;
        cursor: pointer;

        &.disabled {
            border: 1px solid #EDECEC;
            background-color: #EDECEC;
            color: #979797;
            cursor: initial;
        }
    }

    .search-result {
        margin: 40px 0;

        &.is-featured {
            background-color: rgba($color: $lighter-grey, $alpha: 1.0);
            padding: 20px;

            .date-wrap {
                .source {
                    background-color: $white;
                }
            }
        }

        &.asasponsored {
            background-color: rgba($color: $lighter-grey, $alpha: 1.0);
            padding: 20px 20px 0 0;

            .event-content.with-border {
                border: 0 !important;
            }

            .subhead:before {
                content: url(../../Images/Base/dist/asa-logo-sm.png);
                margin-right: 10px;
            }
        }

        .event-content {
            display: flex;
            align-items: flex-start;
            padding: 10px 0 30px 0;

            &.with-border {
                border-bottom: 1px solid $light-grey;
            }

            .event-date {
                .month {
                    display: block;
                    font-size: 10px;
                    font-weight: $bold;
                    letter-spacing: 1.7px;
                    line-height: 1;
                    text-transform: uppercase;
                    color: $black;
                }

                .day {
                    display: block;
                    width: 75px;
                    font-size: 40px;
                    font-weight: $light;
                    line-height: 1;
                    letter-spacing: 0;
                    color: $black;
                    position: relative;
                    left: -5px;
                }
            }

            .event-detail {
                h3 {
                    margin-top: 0;
                }
            }

            .event-date-multiple {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-right: 20px;
                margin-left: 20px;
                min-width: 150px;
            }

            .event-date-hyphen {
                font-size: 48px;
                font-weight: 100;
                line-height: 1.7;
            }

            .event-date-1, .event-date-2 {
                .month, .year {
                    display: block;
                    font-size: 12px;
                    font-weight: $bold;
                    letter-spacing: 1.7px;
                    line-height: 1;
                    text-transform: uppercase;
                    color: $black;
                }

                .day {
                    display: block;
                    font-size: 48px;
                    font-weight: $light;
                    line-height: 1;
                    letter-spacing: 0;
                    color: $black;
                    position: relative;
                    left: -5px;

                    &.multiple {
                        font-size: 48px;
                    }
                }
            }

            .event-date-2 {
                margin-left: 10px;
            }
        }

        .sponsored-tag {
            display: inline-block;
            background-color: $bright-green;
            font-size: 9px;
            color: $white;
            padding: 3px 10px;
            text-transform: uppercase;
        }

        .featured-tag {
            &:before {
                content: url('../../Images/Base/dist/asa-logo-sm.png');
                margin-right: 10px;
            }
        }

        .tag {
            display: inline-block;
            line-height: 1em;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 1.7px;
            font-weight: $bold;


            img {
                vertical-align: text-bottom;
                line-height: 1em;
                display: inline-block;
                margin-right: 10px;
            }
        }

        h3 {
            font-size: 22px;
            line-height: 1.3;
            margin: 8px 0;
        }

        .desc {
            color: $dark-grey;
        }

        .designations {
            margin: 20px 0 0;
            display: block;
            font-size: 10px;
            font-weight: 700;
            letter-spacing: 1.4px;
            text-transform: uppercase;
        }

        .date-wrap {
            display: flex;
            align-items: center;

            .source {
                display: block;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: $bold;
                letter-spacing: 1.7px;
                background-color: $lighter-grey;
                padding: 3px 8px;
                margin-right: 20px;
            }

            .date {
                display: block;
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 1.4px;
                font-weight: $bold;
                margin: 15px 0;
            }
        }

        .CoveoResultLink,
        a.CoveoResultLink,
        .CoveoResultLink:hover,
        .CoveoResultLink:visited,
        a.CoveoResultLink:hover,
        a.CoveoResultLink:visited,
        .CoveoResult a.CoveoResultLink:hover,
        .CoveoResult a.CoveoResultLink:visited {
            color: $black;
            font-weight: $bold;
        }
    }
}
