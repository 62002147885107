.mega-menu {
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin: 0;
    padding: 0;

    &.small {
        justify-content: flex-start;

        .mega-menu__item:not(:last-child) {
            margin-right: 1rem;
        }
    }

    &__item {
        margin: 0;
        position: relative;


        &.hide {
            display: none;
        }
    }

    &__link:visited {
        color: $white;
    }

    .collapsible-panel__item-links {
        color: $black;
    }

    &__link,
    .collapsible-toggle {
        white-space: nowrap;
    }

    &__link,
    .collapsible-toggle {
        padding: 1.8rem 1rem;
        display: inline-block;
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        color: $white;

        &:hover {
            color: $blue;
            background: $white;
            text-decoration: none;

            &:after {
                display: none;
            }
        }
    }

    .more-items-wrapper {
        position: fixed;
        background: $white;
        top: 146px;
        right: 0;
        display: none;
        z-index: 100;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        // min-height: 340px;
        overflow-y: scroll;
        width: 100%;

        &.active {
            display: flex;
        }

        .mega-menu__link,
        .collapsible-toggle {
            color: $blue;

            &:hover {
                color: $white;
                background: $blue;
            }
        }

        .mega-menu__link {
            width: 100%;
        }

        .mega-menu__item:not(:first-child) {
            border-top: 1px solid #ccc;
        }


        .collapsible-toggle {
            width: 100%;
            padding-right: 1.4em;

            &:after {
                right: 0.5em;
            }
        }
    }

    .collapsible-item {
        position: relative;

        &__featured-item {
            .collapsible-item__item-links {
                white-space: nowrap;
            }
        }

        &__subcategory-link {
            .collapsible-item__item-links {
                padding-left: 10px;
            }
        }

        &__category-link {
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            display: block;
            color: #1226aa;
            margin-top: 0;

            .collapsible-item__item-links {
                text-transform: uppercase;
                font-weight: bold;
                color: #1226aa;
            }
        }

        &__direct-link {
            .collapsible-item__item-links {
                color: #4C4B4B;
            }
        }

        &__item-links {
            &.locked::after {
                @include icon-lock;
            }

            &:hover {
                color: inherit;
            }

            &.category-item::after {
                font-weight: 700;
                font-size: px;
                line-height: 32px;
                text-transform: uppercase;
                margin-bottom: 1rem;
                display: inline-block;
                color: darkblue;
            }
        }

        &.active {
            .collapsible-panel {
                visibility: visible;
                opacity: 1;
                transition-delay: 0.2s;

                &__section-image {
                    transform: translateY(0);
                    transition: all 0.5s ease-out 0.2s;
                    opacity: 1;
                }
            }

            .collapsible-toggle {
                color: $blue;
                background: $white;
            }
        }
    }

    .collapsible-toggle,
    .collapsible-toggle-mobile {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            right: 0.2em;
            top: 50%;
            transform: translateY(-60%) rotate(45deg);
            display: block;
            width: 0.5em;
            height: 0.5em;
            border: 1px solid $white;
            border-left: 0;
            border-top: 0;
        }
    }

    .collapsible-panel {
        transition-delay: 0.2s;
        visibility: hidden;
        opacity: 0;
        width: 100vw;
        left: 0;
        position: fixed;
        top: 146px;
        margin-top: 0;
        list-style: none;
        min-height: 340px;
        font-size: 1.5rem;
        line-height: 1;
        display: flex;
        flex-direction: column;
        background: $white !important;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 100;

        &__title-wrapper {
            background: #f2f2f2;
            padding: 2.2rem 0;
        }

        &__nav-wrapper {
            padding: 3rem 0;
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 100%;

            .container,
            .row {
                height: 100%;
                flex: 1;
            }

            .container {
                display: flex;
                flex-direction: column;
                flex: 1;
            }
        }

        &__subtitle {
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            margin-bottom: 15px;
            display: inline-block;
            color: darkblue;
        }

        &__nav {
            list-style: none;
            padding: 0 2.4rem 0 0;
        }

        &__nav-items {
            display: flex;
        }

        &__list-wrapper {
            flex-basis: 33.3%;
        }

        &__section-image {
            max-width: 300px;
            transform: translateY(10%);
            transition: all 0s 0.2s;
            opacity: 0;

            a {
                height: 100%;
                display: block;
            }

            img {
                width: 100%;
            }
        }

        &__section-image-title {
            font-weight: bolder;
            color: $black;
            font-size: 16px;
            margin: 14px 0 0 0;
            display: block;
            transition: all 200ms ease-in-out;
        }

        &__section-image-description {
            color: $dark-grey;
            font-family: "Lato",sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.2;
            margin:14px 0 0 0;
        }

        &__section-image {
            > a {
                .collapsible-panel__section-image-title {
                    &:hover,
                    &:focus {
                        color: $dark-grey;
                    }
                
                }
                .collapsible-panel__section-image-description {
                    &:hover,
                    &:focus {
                        color: $dark-grey;
                    }
                }

                &:hover,
                &:focus {
                    color: inherit;
                }
            }
        }
    }

    .collapsible-panel li {
        margin-bottom: 15px;
    }

    .more-toggle {
        border: none;
        background: none;
        color: $white;
    }
}

.collapsible-toggle-mobile {
  font-size: 1.5rem;
}

.mega-menu-wrapper {
    left: 0;
    z-index: 5;
    width: 100%;
    top: 58px;
    background: $blue;
    z-index:  1039;

    .slice__content {
        padding: 0 15px;
    }

    .header-layout__navigation {
        width: 100%;
    }

    .site-nav {
        width: 100%;
    }
}

.mega-menu-wrapper-preview {
  left: 0;
  z-index: 5;
  width: 100%;
  top: 58px;
  background: $blue;
  z-index:  1040;

  .slice__content {
    padding: 0 15px;
  }

  .header-layout__navigation {
    width: 100%;
  }

  .site-nav {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .mega-menu {
    .collapsible-panel{
      min-height: 420px;
    }

    .collapsible-panel__nav-wrapper {
      padding: 2rem 0;
    }
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1190px) {
    .mega-menu {
        &__link,
        .collapsible-toggle {
            line-height: normal;
            white-space: normal;
        }

        .collapsible-toggle {
            display: flex;

            &:hover::after {
                display:block;
            }

            &::after {
                position: static;
                transform: rotate( 45deg );
                margin: 5px 0 0 10px;
                flex: 0 0 auto;
            }
        }

        &__item {
            flex: 0 1 0px;
            display: flex;
            flex-basis: auto;

            .collapsible-item {
                display: flex;
            }
        }
    }
    }

    @import "MegaMenuNav-mobile";
