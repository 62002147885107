.supporters--content {
    text-align: center;
    padding-top: 50px;

    .supporters--list > div {
        padding: 0 50px 50px 50px;
    }

    @media (min-width: 640px) {
        .supporters--list {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
    }
}
