// Temp Page Heading
h1.pageheading {
    margin-bottom: 30px;
}
.CoveoSearchInterface {

    .CoveoSearchbox {
        border: none;

        .CoveoSearchButton {
            position: absolute;
            right: 0;
            z-index: 2;
            float: none;
            border-radius: 0;
            border: none;
            background-color: transparent;
            background: url('../../Images/Base/dist/icon-search-black.svg') no-repeat center;

            svg {
                display: none;
            }

            &:hover, &:focus {
                opacity: 0.5;
            }
        }

        .magic-box {
            border: none !important;

            &.magic-box-notEmpty .magic-box-clear {
                width: 15px;
                left: 15px;
                bottom: 2px;
            }

            .magic-box-clear {
                z-index: 2;
                background: transparent !important;
            }

            .magic-box-clear-svg {
                color: $grey !important;
            }

            .magic-box-input {
                position: initial;

                input {
                    font: {
                        family: $lato;
                        size: 16px;
                        weight: $regular;
                    }

                    color: #444444;

                    &:focus {
                        outline: 1px solid $blue;
                        color: $blue;
                    }

                    border: 1px solid rgba($color: $light-grey, $alpha: 0.5); // Validation Border Placeholder
                    background-color: rgba($color: $light-grey, $alpha: 0.5);
                    padding: 10px;
                }
            }
        }
    }
}

@import "coveosearchinput-mobile";
