@include max-screen(md) {
    .featuredevent {
        .featuredevent-image {
            img {
                width: 100%;
            }
            .angle-overlay {
                background: transparent linear-gradient(-155deg, transparent 0%, transparent 90%, white 90%, white 100%);
            }
            .gradient-overlay {
                background: transparent linear-gradient(-125deg, transparent 0%, transparent 40%, $blue 100%);
            }
        }
        .featuredevent-details {
            .date {
                margin-right: 50px;
                .month {
                    
                }
                .day {
                    
                }
            }
            .event {
                
                .tag {
                    
                }
                .name {
                    font-size: 28px;
                }
                .whenwhere {
                    .dates {
    
                    }
                    .location {
                    
                    }
                }
            }
        }
    }
}

@include max-screen(sm) {
    .featuredevent {
        .featuredevent-image {
            img {
                width: 100%;
            }
            .angle-overlay {
                background: transparent linear-gradient(-155deg, transparent 0%, transparent 90%, white 90%, white 100%);
            }
            .gradient-overlay {
                background: transparent linear-gradient(-125deg, transparent 0%, transparent 40%, $blue 100%);
            }
        }
        .featuredevent-details {
            margin: 30px 0;
            .date {
                display: none;
                .month {
                    
                }
                .day {
                    
                }
            }
            .event {
                .tag {
                    margin-bottom: 10px;
                }
                .name {
                    font-size: 28px;
                    margin-bottom: 10px;
                }
                .whenwhere {
                    .dates {
    
                    }
                    .location {
                    
                    }
                }
            }
        }
    }
}