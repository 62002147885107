@include max-screen(lg) {
    header {
        &.desktop {
            .top-nav {
                .utility-nav {
                    .search-wrap {
                        input {
                            width: 150px;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen(md) {
    header {
        &.desktop {
            .top-nav {
                .burger-wrap {
                    margin-right: 25px;
                }

                .utility-nav {
                    .icon {
                        margin-left: 25px;
                    }

                    .account-wrap {
                        .username {
                            .signin {
                                white-space: nowrap;
                            }
                        }
                    }

                    .search-wrap {
                        margin-top: 28px;

                        .CoveoSearchInterface {
                            min-width: 200px;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen(md) {
    header {
        &.desktop {
            display: none;
        }

        &.mobile {
            display: block;
            z-index: 10;
            background-color: $white;
            width: 100%;

            .top-nav {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 70px;
                border-bottom: 1px solid $light-grey;

                .hamburger {
                    background-color: $light-grey;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 90px;
                    height: 100%;
                    cursor: pointer;

                    .openNav {
                        display: block;
                        position: relative;
                        z-index: 1;
                        pointer-events: none;

                        &.burger {
                            display: block;
                        }

                        &.no-burger {
                            display: none;
                        }
                    }

                    .closeNav {
                        display: none;
                        pointer-events: none;

                        &.x {
                            display: block;
                        }

                        &.no-x {
                            display: none;
                        }
                    }

                    .label {
                        display: block;
                        font-size: 10px;
                        font-weight: $extra-bold;
                        letter-spacing: 2px;
                        color: $blue;
                        text-transform: uppercase;
                        margin-top: 2px;
                    }
                }

                a, a:visited {
                    display: block;

                    .logo {
                        margin-left: 20px;

                        img {
                            width: 202px;
                            height: 50px;
                        }
                    }
                }
            }

            .utility-nav {
                @include list-reset;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: $blue;
                width: 100%;
                height: 50px;
                border-bottom: 1px solid rgba($color: $white, $alpha: 0.25);

                li {
                    display: inline-block;
                    /*display: flex;
                    justify-content: center;
                    align-items: center;*/
                    width: 100%;
                    height: 100%;
                    border-right: 1px solid rgba($color: $white, $alpha: 0.25);

                    &:last-child {
                        border-right: 0;
                    }

                    a, a:visited {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                    }

                    &.accordionItem {
                        .accordionItemHeading {
                            border: 0;
                            padding: 0;
                            margin: 0;
                            display: flex;
                            cursor: pointer;
                            transition: all 200ms ease-in-out;
                            position: relative;

                            label.marker {
                                position: absolute;
                                bottom: 18px;
                                left: 50%;
                                right: 0;
                                background-color: $yellow;
                                border-radius: 50%;
                                padding: 2px 6px;
                                font-size: 12px;
                                font-weight: bold;
                                width: 20px;
                                height: 20px;
                            }

                            &:focus {
                                outline: none;
                            }
                        }

                        .username {
                            display: none;
                        }
                    }

                    &.close {
                        padding: 0;
                        margin: 0;
                        position: relative;

                        &.accordionItem {
                            opacity: 1;

                            .accordionItemHeading {
                                background-color: transparent;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        .accordionItemContent {
                            height: 0px;
                            transform: scaleY(0);
                            opacity: 0;
                            display: none; // block
                        }

                        .username {
                            display: none;
                        }
                    }

                    &.open {
                        &.accordionItem {
                            opacity: 1;
                            position: relative;

                            button.accordionItemHeading {
                                background-color: transparent;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        .accordionItemContent {
                            transform: scaleY(1);
                            transform-origin: top;
                            opacity: 1;
                            transition: opacity 500ms ease-in-out;
                            position: fixed;
                            left: 0;
                            right: 0;
                            z-index: 2;
                            width: 100%;
                            height: 100%;
                            display: block;
                            overflow: scroll;
                            background-color: $white;

                            &.notification-dropdown {
                                height: auto;
                            }

                            .summary {
                                display: block;
                                margin: 10px 30px;
                                font-size: 13px;
                                line-height: 1.3;
                                color: $blue;
                            }

                            ul {
                                margin: 0;
                                padding: 0;

                                li {
                                    padding: 15px 30px;
                                    border-bottom: 1px solid $dark-grey;

                                    span, a, a:visited {
                                        display: flex;
                                        justify-content: flex-start;
                                        color: $black;
                                        font-size: 20px;

                                        &.isExpiredType {
                                            text-decoration: line-through;
                                        }

                                        &.isExpiredDate {
                                            color: red;
                                            font-weight: bold;
                                        }
                                    }
                                }

                                &.notifications-menu {
                                    li {
                                        border-bottom: 1px solid $light-grey;

                                        .notif-item {
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: flex-start;

                                            .notif-close {
                                                display: inline-block;
                                                margin: 0 0 0 10px;
                                                background: transparent;
                                                border: 0;
                                                cursor: pointer;

                                                img {
                                                    width: 25px;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.cart-menu {
                                    li {
                                        border-bottom: none;
                                    }
                                }

                                &.account-menu {
                                }
                            }

                            .search-overlay {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                background-color: $blue;
                                padding: 30px;
                                width: 100%;

                                .search-field {
                                    margin: 100px 0;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    width: 100%;
                                    position: relative;
                                    /* Coveo mobile search box overrides */
                                    .CoveoSearchInterface {
                                        width: 100%;
                                        background-color: transparent;

                                        .CoveoSearchbox .magic-box .magic-box-input {
                                            background-color: transparent;
                                        }
                                    }

                                    input {
                                        width: 100%;
                                        font-weight: $bold;
                                        font-size: 24px;
                                        color: $white;
                                        padding: 12px 75px 12px 0;
                                        background-color: transparent;
                                        border: none;
                                        border-bottom: 1px solid $white;

                                        &:focus {
                                            outline: none;
                                        }

                                        &::placeholder {
                                            color: $white;
                                            font-weight: $regular;
                                        }
                                    }

                                    a.search, a.CoveoSearchButton {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: auto;
                                        font-size: 16px;
                                        font-weight: $bold;
                                        color: $white;
                                        text-transform: uppercase;
                                        background-color: rgba($color: $white, $alpha: 0.2);
                                        padding: 0 20px;
                                        position: relative;
                                        height: 45px;

                                        svg {
                                            display: block;
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }

                        .account-wrap {
                            .username {
                                display: none;
                            }
                        }
                    }
                }

                .account-wrap {
                    .username {
                        display: none;
                    }
                }

                .search-wrap {
                    input.search {
                        display: none;
                    }
                }
            }
        }
    }

    // Navigation Overlay
    .overlay {
        width: 100%;

        .overlay-content {
            nav {
                .overlay-header {
                    display: none;
                }

                ul {
                    // &.main {
                    //     font-weight: $regular;
                    // }
                    //margin-top: 10px;
                    li {
                        padding: 10px 25px !important;

                        a {
                            &.subDirectLink {
                                padding: 10px 0;
                                display: block;
                            }
                        }

                        &.has-dropdown {
                            .sub-overlay {
                                width: 100%;
                                top: 70px;

                                .closeSubNav {
                                    padding: 0 25px;
                                    margin: 20px 0;
                                }

                                .sub-overlay-content {
                                    .sub-overlay-header {
                                        padding: 0 25px;
                                    }

                                    ul li {
                                        padding: 15px 25px;

                                        a {
                                            &.subNav {
                                                padding: 15px 25px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
