@include max-screen(xl) {
    .product-box {
        a.product-image, a.product-image:visited {
            height: 185px;
        }
    }
}

@include max-screen(lg) {
    .product-box {
        a.product-image, a.product-image:visited {
            height: 150px;
        }
    }
}

@include max-screen(md) {
    .product-box {
        a.product-image, a.product-image:visited {
            height: auto;
        }
    }
}
