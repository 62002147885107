.moreevent {
    margin: 30px 0;

    h3 {
        margin-bottom: 40px;
    }

    .moreevent-article {
        
        p {
            margin: 0 20px 40px 0;
        }

        a, a:visited {
            color: $black;

            &:hover, &:focus {
                color: $blue;
            }
        }
    }
}

@import "additionaleventinfo-mobile";
