﻿.news-search-interface {

    .CoveoSearchbox {
        margin-bottom: 30px;
    }

    .coveo-sort-container .CoveoSort {
        margin: 0;
    }

    .CoveoResultList {
        margin: 30px 0 !important;

        .coveo-result-list-container .coveo-list-layout.CoveoResult {
            padding: 0 !important;
        }
    }


}
