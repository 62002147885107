@include max-screen(xl) {
    .newsrollup .articlebox-image {
        height: 383px;
    }
    .newsrollup .article-list-wrap .article-list {
        height: 383px;
    }
}

@include max-screen(lg) {
    .newsrollup .articlebox-image {
        height: 325px;
    }
    .newsrollup .article-list-wrap .article-list {
        height: 325px;
    }
}

@include max-screen(md) {
    .newsrollup {
        margin: 50px 0 100px 0;

        h2 {
            margin-bottom: 30px;
        }

        .articlebox-image {
            height: auto; // Match Article List Height
            .left-side {
                border-bottom: 90px solid $white;
            }

            .right-side {
                border-right: 30vw solid transparent;
                border-bottom: 90px solid #fff;
            }
        }

        .articlebox-content {
            margin-bottom: 50px;
        }

        .article-list-wrap {
            display: inline-flex;
            flex-direction: column;

            .article-list {
                height: 100%; // Match Articlebox Image
                .article-item-wrap {
                    flex-direction: column;

                    .article-image {
                        width: 100%;
                        margin: 0;

                        img {
                            position: initial;
                            margin-bottom: 25px;
                        }

                        .sponsored-banner {
                            display: none;
                        }

                        &.sponsored {
                            .sponsored-banner {
                                display: inline-block;
                            }
                        }
                    }

                    .article-details {
                        width: 100%;
                        margin-bottom: 50px;

                        .date {
                            margin-bottom: 10px;
                        }

                        h4 {
                            font-size: 18px;
                            font-weight: $bold;
                            color: $black;
                            line-height: 1.2;
                        }
                    }
                }
            }

            .cta {
                @include cta-button-underline;
                margin-top: auto;
            }
        }
    }
}

@include max-screen(sm) {
    .newsrollup .articlebox-image {
        .left-side {
            border-bottom: 50px solid $white;
        }

        .right-side {
            border-right: 25vw solid transparent;
            border-bottom: 50px solid #fff;
        }
    }
}