.comm-prefs {
    display: flex;
    flex-direction: column;
    height: 100%;

    .list-wrap {
        h4 {
            margin: 0;
        }

        .solicitations-subtext {
            padding-left: 5px;
        }
        // Read-only (Data) View
        .prefs-list {
            list-style: none;
            list-style-position: outside;
            margin: 0;
            padding: 0;
            margin: 10px 0;

            li {
                background-color: $white;
                padding: 10px 30px 10px 15px;
                margin: 10px 0;
                display: inline-block;
                width: 100%;
                line-height: 1.2;
                font-weight: $bold;
                font-size: 14px;
                letter-spacing: 1.4px;
                text-transform: uppercase;
                display: flex;

                &.yes {
                    &:before {
                        content: url('../../Images/Base/dist/icon-checked-yellow.svg');
                        margin-right: 15px;
                    }
                }

                &.no {
                    background-color: rgba($color: $dark-grey, $alpha: 0.04);
                    padding-left: 47px; // Ensures alignment with the checkmark for .yes

                    &:before {
                    }
                }


                &.disabled {
                    color: gray;
                }
            }
        }

        &:last-child {
            margin-top: 25px;
        }
        // Edit View
        .prefs-list-form {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                .field-wrap {
                    margin-bottom: 5px;

                    input[type="checkbox"] {
                        margin: 0 10px 0 0;
                    }

                    label {
                        font-weight: $regular;
                    }

                    > .member-only-disabled {
                        color: gray;
                    }
                }
            }
        }
    }
}

//@import "solicitations-mobile";
