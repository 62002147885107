@include max-screen(sm) {
    .profile-picture {
        .picture-wrap {
            flex-direction: column;
            align-items: flex-start;
            img {
                margin-bottom: 10px;
            }
        }
    }
}