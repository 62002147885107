.eventcalendarsplash {
    margin-bottom: 50px;

    .articlebox-image {
        img {
            width: 100%;
        }

        width: 100%;
        background-color: $light-grey;
        position: relative;
        z-index: 0;

        .angle-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-155deg, transparent 0%, transparent 83%, white 83%, white 100%);
        }

        .gradient-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-125deg, transparent 0%, transparent 40%, $blue 100%);
        }
    }

    .articlebox-content-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        margin-left: 30px;

        .event-date-wrap {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 20px;
        }

        .event-date-1, .event-date-2 {
            margin-right: 10px;

            .month, .year {
                display: block;
                font-size: 12px;
                font-weight: $bold;
                letter-spacing: 1.7px;
                line-height: 1;
                text-transform: uppercase;
                color: $black;
            }

            .day {
                display: block;
                font-size: 80px;
                font-weight: $light;
                line-height: 1;
                letter-spacing: 0;
                color: $black;
                position: relative;
                left: -5px;

                &.multiple {
                    font-size: 48px;
                }
            }

            .year {
                margin-bottom: 10px;
            }
        }

        .event-date-hyphen {
            font-size: 80px;
            font-weight: $light;
            line-height: 1.2;
        }

        .event-date-2 {
            margin-left: 10px;
        }

        .articlebox-content {
            width: 100%;
            min-height: 200px;
            display: flex;
            flex-direction: column;

            h4 {
                font-size: 28px;
                line-height: 1.3;
                letter-spacing: 0.5px;
                font-weight: $extra-bold;
                color: $black;
                text-transform: uppercase;
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 0;
            }

            .subhead {
                width: 100%;
                height: 18px;
                margin-bottom: 15px;

                span {
                    display: block;
                    font-size: 12px;
                    font-weight: $extra-bold;
                    letter-spacing: 1.6px;
                    text-transform: uppercase;
                    color: $blue;

                    &.hide {
                        display: none;
                    }
                }
            }

            .location {
                display: inline-block;
                font-size: 18px;
                color: $dark-grey;
                margin-bottom: 5px;
            }
        }

        .cta {
            @include cta-button-underline;
            margin-top: auto;
        }
    }
}

@import "featuredevent-mobile.scss";
