.related-products {
    margin: 30px 0;
    h2 {
        margin: 30px 0;
    }
    .cta-wrap {
        width: 100%;
        margin: 50px 0 40px 0;
        .cta {
            @include cta-button-underline;
        }
    }
}

@import "relatedproducts-mobile";