.bottomcallout {
    margin: 20px 0px 20px 0px;
    border: {
        top: 1px solid $light-grey;
        right: 1px solid $light-grey;
        bottom: 2px solid $purple;
        left: 1px solid $light-grey;
    }

    .row {
        margin-bottom: 10px;
    }

    .articlebox-image {
        img {
            width: 100%;
        }

        width: 100%;
        background-color: $light-grey;
        position: relative;
        z-index: 0;
        height: 200px;
        overflow: hidden;
    }

    .articlebox-content-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: space-between;
        height: 100%;

        .articlebox-content {
            width: 100%;
            padding: 30px 30px 30px 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .subhead {
                width: 100%;
                height: 18px;

                span {
                    display: block;
                    font-size: 12px;
                    font-weight: $extra-bold;
                    letter-spacing: 1.6px;
                    text-transform: uppercase;
                    color: $purple;

                    &.hide {
                        display: none;
                    }

                    &.sponsored {
                        background-color: $blue;
                        padding: 3px 10px;
                        color: $white;
                        font-size: 9px;
                        font-weight: $bold;
                        text-transform: uppercase;
                        letter-spacing: 1.2px;
                        display: inline-block;
                    }
                }
            }

            .text-wrap {
                h3 {
                    font-size: 20px;
                    line-height: 1.3;
                    margin-bottom: 15px;
                }

                p {
                    font-size: 14px;
                    line-height: 1.3;
                    margin-bottom: 15px;
                }
            }

            .cta {
                @include cta-button-outline;
                margin: 0;
                border: none;
                padding: 0;
                display: inline;
                color: $black;

                &:hover, &:focus {
                    margin: 0;
                    border: none;
                    padding: 0;
                    color: $black;
                }
            }
        }
    }
}

// Element Query
@element .bottomcallout and (max-width: 600px) {
    :self {
        .row {
            flex-direction: column;

            .col-md-6, .col-md-4 {
                max-width: 100%;
            }
        }

        .articlebox-content-wrap .articlebox-content {
            padding: 30px;
        }

        .articlebox-content-wrap .articlebox-content .subhead {
            margin-bottom: 10px;
        }

        .articlebox-content-wrap .articlebox-content .text-wrap {
            p {
                font-size: 18px;
                margin-bottom: 30px;
            }
        }
    }
}


@import "bottomcallout-mobile.scss";