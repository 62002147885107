.rt-container {
	a, a:visited {
		@include link;
		color: $dark-grey;
		/* Remove left and right spacing for links */
		padding-left: 0;
		padding-right: 0;
		border-left: 0;
		border-right: 0;
		
		&:hover {
			padding-left: 0;
			padding-right: 0;
			border-left: 0;
			border-right: 0;
		}

		&.btn-primary {
			display: table !important;
		}
	}

	a:empty {
		visibility: hidden;
		display: inline-block;
		width: 0px;
		height: 0px;
		margin: 0px;
		padding: 0px;
		border-width: 0px;
	}
	&.external-links {
		h2 {
			a[name] {
				display: block;
				height: inherit;
				&::before {
					content: '';
					display: block;
					height: 4rem;
					margin-top: -4rem;
				}
			}
		}
	}
}

@import "richtext-mobile";