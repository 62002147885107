@include max-screen(xl) {
    .eventcalendarsplash {
        .articlebox-content-wrap {
            margin-left: 0;

            .event-date-wrap {
                margin-top: 0;
                margin-bottom: 15px;
            }

            .event-date-1, .event-date-2 {
                .day {
                    font-size: 70px;
                }
            }

            .event-date-hyphen {
                font-size: 70px;
            }

            .articlebox-content {
                min-height: 0;

                h4 {
                    margin-bottom: 10px;
                }

                p {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@include max-screen(md) {
    .eventcalendarsplash {
        margin-top: 30px;

        .articlebox-image {
            margin-bottom: 20px;
        }
    }
}
