.reshape_eventsrollup {
    margin-top: 30px;
}

.reshape_eventsrollup_sectionTitle {
    margin-bottom: 30px;
    font-family: "Lato",sans-serif;
    text-transform: uppercase;
}

.reshape_eventsrollup_featuredArticle {
    margin-bottom: 50px;
}

.reshape_eventsrollup_title {
    font-family: "Lato",sans-serif;
    margin-bottom: 15px;
    color: $dark-grey;
}

.reshape_eventsrollup_eventDate {
    font-weight: bold;
    color: $dark-grey;

    .dates {
        text-transform: uppercase;
        color: $dark-grey;
    }

    .location {
        margin-left: 10px;
        color: $dark-grey;
    }
}

.reshape_eventsrollup_description {
    line-height: 2em;
    color: $dark-grey;
}

.reshape_eventsrollup_imageWrap {
    aspect-ratio: 2;
    margin-bottom: 15px;

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.reshape_eventsrollup_subhead {
    text-transform: uppercase;
    color: $dark-grey;

    .cme-credits {
        margin-left: 10px;
    }
}

.reshape_eventsrollup_contentWrap {
    color: $dark-grey;
    line-height: 2em;
}

.reshape_eventsrollup_learnMore {
    a {
        text-decoration: none;
        position: relative;
        display: inline-block;
        padding-right: 30px;
        @include cta-button-underline;
        border-bottom: none !important;

        &:hover {
            text-decoration: underline;
        }
    }
}


.reshape_eventsrollup_listArticle {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
    padding: 24px;
}

.reshape_eventsrollup_listArticleTitle {
    margin-bottom: 10px;
    font-family: "Lato",sans-serif;

    a {
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }
}

.reshape_eventsrollup_listArticleDate {
    margin-bottom: 10px;
    color: $dark-grey;
    font-size: 16px;
    font-weight: bold;

    .dates {
        text-transform: uppercase;
    }

    .location {
        margin-left: 10px;
    }
}

.reshape_eventsrollup_listArticleContent {
    margin-bottom: 10px;

    .reshape_subhead {
        .cme-credits {
            margin-left: 10px;
        }
    }
}

.reshape_eventsrollup_listArticleImage {
    display: none;
    aspect-ratio: 2;
    margin-bottom: 15px;
    margin-top: auto;

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

@include min-screen(md) {
    .reshape_eventsrollup_featuredArticle {
        display: grid;
        grid-template-columns: calc(50% - 30px) 50%;
        grid-template-rows: auto;
        column-gap: 30px;

        .reshape_eventsrollup_title {
            grid-area: 1/1/2/3;
        }

        .reshape_eventsrollup_imageWrap {
            grid-area: 2/2/3/3;
        }

        .reshape_eventsrollup_contentWrap {
            grid-area: 2/1/3/2;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .reshape_eventsrollup_learnMore {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .reshape_eventsrollup_listArticleTitle {
        margin-bottom: 15px;
        height: 3em;
    }

    .reshape_eventsrollup_listArticleImage {
        display: block;
    }
}