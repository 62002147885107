/* 12.0.1 - Search */
@import "../Views/Feature/Search/ResultsList/resultslist";
@import "../Views/Feature/Search/DatePicker/_datepicker.scss";
@import "../Views/Feature/Search/SearchFrame/searchframe";
@import "../Views/Feature/Search/FASAMemberBox/_fasamemberbox.scss";

.coveo-facet-header-title {
    display: flex;
}

.coveo-title-amount {
    margin: 0 0 auto auto;
}

.tooltip.show {
    opacity: 1
}