@include max-screen(md) {
    .featured-article {
        .featured-image {
            margin-bottom: 20px;

            .left-side {
                border-bottom: 90px solid $white;
            }

            .right-side {
                border-right: 30vw solid transparent;
                border-bottom: 90px solid #fff;
            }
        }

        .featured-content {
            span {
                margin-bottom: 10px;
            }
        }
    }
}

@include max-screen(sm) {
    .featured-article .featured-image {
        .left-side {
            border-bottom: 50px solid $white;
        }

        .right-side {
            border-right: 25vw solid transparent;
            border-bottom: 50px solid #fff;
        }
    }
}
