@include min-screen(md) {
    .ad-slot.mobile {
        display: none;
    }
}

.ad-slot {
    .iframe-wrap {
        margin: 50px auto 50px auto;
        display: block;
    }
}

@import "adslot-mobile";