.quickview {
    .modal-header {
        justify-content: flex-end;
        border-bottom: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .modal-body {
        img {
            width: 100%;
            min-width: 310px;
            height: 100%;
            min-height: 180px;
        }
        h4 {
            line-height: 1.3;
        }
        padding: 0 60px;
        .cost-ribbon {
            position: absolute;
            bottom: 0;
            left: 15px;
            right: -33px;
            background-color: $ivory;
            padding: 10px 30px;
            table {
                display: flex;
                justify-content: flex-start;
                width: 100%;
                font-size: 14px;
                font-weight: $bold;
                letter-spacing: 1.4px;
                text-transform: uppercase;
                td {
                    padding-right: 20px;
                    &.on-sale {
                        text-decoration: line-through;
                    }
                    &.sale-price {
                        color: $red;
                    }
                }
            }
        }
        p {
            &:first-child {
                margin-top: 20px;
            }
        }
        .cta {
            @include cta;
        }
    }
    .modal-footer {
        border-top: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .cta-button {
            @include cta-button-outline;
        }
    }
}

@import "quickview-mobile";