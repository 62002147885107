@include max-screen(lg) {
    .quickview {
        .modal-body {
            img {
                min-width: 0;
                min-height: 0;
            }
            h4 {
                position: relative;
                margin-top: 15px;
            }
            .cost-ribbon {
                position: relative;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 15px -38px 0 -38px;
                padding: 10px 35px;
            }
        }
    }
}

@include max-screen(lg) {
    .quickview {
        .modal-body {
            padding: 0 20px;
        }
    }
}

@include max-screen(sm) {
    .quickview {
        .modal-body {
            .cost-ribbon {
                margin: 20px -36px 0 -36px;
                padding: 10px 36px;
            }
        }
        .modal-footer {
            .cta-button {
                width: 100%;
            }
        }
    }
}