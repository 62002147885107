﻿.cohorts {
    $cohorts: &;
    display: block;
    margin-bottom: 1rem;
    gap: 1rem;

    @at-root a#{&} {
        &:hover,
        &:focus {
            text-decoration: none;

            & #{$cohorts}__desc {
                text-decoration: underline;
            }
        }
    }

    @include min-screen(md) {
        display: flex;
        margin-bottom: 2rem;
    }

    &__content {
        margin: 0 0 10px;
        flex: 1 1 0px;
        padding: 40px 40px 40px 40px;
    }

    &__title {
        color: #0e55b2;
        margin: 0 0 10px;
        font-size: 32px;
        font-weight: 600;
        line-height: 130%;
        margin-bottom: 32px;
        text-decoration: none;

        @include min-screen(md) {
            font-size: 52px;
        }
    }

    &__intro {
        font-size: 18px;
        font-weight: 400;
        color: #4C4B4B;
        line-height: 165%;
        margin-bottom: 40px;
        text-decoration: none;
    }

    &__desc {
        font-size: 16px;
        color: #555555;
        font-weight: 400;
        margin-bottom: 32px;
        line-height: 130%;

        &::after {
            content: "";
            position: relative;
            display: inline-block;
            width: 10px;
            height: 10px;
            border-right: 2px solid currentColor;
            border-top: 2px solid currentColor;
            transform: rotate(45deg);
            top: 0px;
            margin-left: 10px;
        }
    }

    &__image-wrapper {
        flex: 1 1 0px;
        text-align: right;
        padding: 40px 40px 40px 40px;
    }

    &__image {
        width: 100%;
        height: auto;
    }
}
