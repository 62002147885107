.featured-article {
    margin: 50px 0 70px 0;

    .featured-image {
        img {
            width: 100%;
        }

        width: 100%;
        //height: 100%;
        /*background-color: $light-grey;*/
        position: relative;
        z-index: 0;
        /*.angle-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-155deg, transparent 0%, transparent 83%, white 83%, white 100%);
        }*/
        .left-side {
            border-left: calc(0vw + 1px) solid transparent;
            border-bottom: 120px solid $white;
            position: absolute;
            z-index: 2;
            bottom: 0;
            width: 0;
            height: 0;
            opacity: 1;
        }

        .right-side {
            /*border-right: 15vw solid transparent;*/
            /* right: 0; */
            left: 0;
            /*border-bottom: 120px solid $white;*/
            position: absolute;
            z-index: 2;
            bottom: 0;
            width: 0;
            height: 0;
            opacity: 1;
        }

        .gradient-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-125deg, transparent 0%, transparent 40%, $blue 100%);
        }
    }

    .featured-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        h2 {
            margin-top: auto;
        }

        span {
            margin-top: auto;
            display: block;
            font-weight: $bold;
        }

        .cta {
            margin-top: auto;
            @include cta-button-underline;
        }
    }
}


/*Style used when Featured Article is in a "small" placeholder*/
.col-md-4 {
    .featured-article {
        .row {
            .col-md-6 {
                .featured-image {
                    .right-side {
                        border-right: none;
                    }
                }
            }
        }
    }
}

@import "featuredarticle-mobile";
