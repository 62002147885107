@import "variables";
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// BUTTONS =============================================== *
@mixin primary-button {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: $bold;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: $black;
    border: 1px solid $light-yellow;
    background-color: $light-yellow;
    padding: 10px 15px;
    &:hover {
        text-decoration: none;
        background-color: $yellow;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    }
    /*&:focus {
        text-decoration: none;
        background-color: $yellow;
        box-shadow: inset 0px 0px 0px 4px $white;
        outline: 2px solid $black;
    }*/
    &:active {
        text-decoration: none;
        background-color: $yellow;
        box-shadow: none;
        outline: none;
    }
    &.add-button {
        &:before {
            content: url('../../Images/Base/dist/icon-plus-dark.svg');
            margin-right: 10px;
        }
    }
}

@mixin secondary-button {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: $bold;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: $black;
    background-color: $white;
    border: 1px solid $dark-grey;
    padding: 10px 15px;
    &:hover {
        text-decoration: none;
        background-color: $ivory;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    }
    /*&:focus {
        text-decoration: none;
        background-color: $white;
        box-shadow: inset 0px 0px 0px 4px $white;
        outline: 2px solid $black;
    }*/
    &:active {
        text-decoration: none;
        background-color: $ivory;
        border: 1px solid $dark-grey;
        box-shadow: none;
        outline: none;
    }
    &.close-button {
        &:before {
            content: url('../../Images/Base/dist/icon-close.svg');
            margin-right: 10px;
            position: relative;
            top: 2px;
        }
    }
}

@mixin edit-button($color) {
    display: flex;
    justify-content: center;
    width: 110px;
    font-size: 14px;
    font-weight: $bold;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: $black;
    background-color: $color;
    border: 1px solid $color;
    padding: 10px 15px;
    position: absolute;
    right: 15px; // consider row padding
    &:before {
        content: url('../../Images/Base/dist/icon-edit.svg');
        margin-right: 10px;
        display: inline-block;
        width: 12px;
        height: 21px;
    }
    &:hover, &:focus {
        text-decoration: none;
    }
    &.save {
        background-color: $light-yellow;
        border: 1px solid $light-yellow;
        &:before {
            content: none;
            margin-right: 0;
        }
    }
    &.close {
        background-color: $white;
        border: 1px solid $dark-grey;
        &:before {
            content: url('../../Images/Base/dist/icon-close.svg');
            margin-right: 10px;
            position: relative;
            top: 2px;
        }
    }
}

@mixin view-button($color) {
    display: flex;
    justify-content: center;
    width: auto;
    font-size: 14px;
    font-weight: $bold;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: $black;
    background-color: $color;
    border: 1px solid $color;
    padding: 10px 15px;
    position: absolute;
    right: 15px; // consider row padding
    &:before {
        content: url('../../Images/Base/dist/icon-view.svg');
        margin-right: 10px;
    }
    &:hover, &:focus {
        text-decoration: none;
    }
    &.close {
        background-color: $white;
        border: 1px solid $dark-grey;
        &:before {
            content: url('../../Images/Base/dist/icon-close.svg');
            margin-right: 10px;
            position: relative;
            top: 2px;
        }
    }
}

// LINKS ============================================== *
@mixin link {
    padding: 0 2px 2px 2px;
    border: {
        top: 2px solid transparent;
        right: 2px solid transparent;
        bottom: 2px solid $yellow;
        left: 2px solid transparent;
    }
    &:hover {
        text-decoration: none;
        background-color: $light-yellow;
        color: inherit;
        border: {
            top: 2px solid transparent;
            right: 2px solid transparent;
            bottom: 2px solid $light-yellow;
            left: 2px solid transparent;
        }
    }
    &:active, &:focus {
        background-color: transparent;
        border: {
            top: 2px solid $yellow;
            right: 2px solid $yellow;
            bottom: 2px solid $yellow;
            left: 2px solid $yellow;
        }
    }
}

@mixin headline-link {
    padding: 0 2px 2px 2px;
    border: {
        top: 2px solid transparent;
        right: 2px solid transparent;
        bottom: 2px solid transparent;
        left: 2px solid transparent;
    }
    &:hover {
        text-decoration: none;
        color: $blue;
        border: {
            top: 2px solid transparent;
            right: 2px solid transparent;
            bottom: 2px solid transparent;
            left: 2px solid transparent;
        }
    }
    &:active, &:focus {
        color: $black;
        border: {
            top: 2px solid $black;
            right: 2px solid $black;
            bottom: 2px solid $black;
            left: 2px solid $black;
        }
    }
}

// Font Awesome Icons ============================== *
@mixin icon-lock {
    font-family: 'Font Awesome 6 Free';
    content: "\f023";
    font-weight: 900;
    margin-left: 10px;
    position: relative;
}


// CTA ============================================== *
@mixin cta-button-underline {
    display: block;
    font-size: 14px;
    font-weight: $bold;
    letter-spacing: 1.4px;
    color: $black;
    text-transform: uppercase;
    padding: 20px 0;
    border-bottom: 2px solid $blue;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:after {
        content: url('../../Images/Base/dist/icon-arrow-right.svg');
        transition: all 200ms ease-in-out;
        padding-left: 20px;
        position: relative;
        top: 2px;
    }
    &:hover, &:focus {
        text-decoration: none;
        color: $black;
        &:after {
            padding-left: 30px;
            position: relative;
        }
    }   
    &:active {
        text-decoration: none;
        color: $blue;
    }
    &.back {
        justify-content: flex-start;
        &:after {
            content: none;
        }
        &:before {
            content: url('../../Images/Base/dist/icon-arrow-right.svg');
            transition: all 200ms ease-in-out;
            padding-left: 30px;
            position: relative;
            top: 0;
            left: 10px;
            transform: rotate(180deg);
        }
        &:hover, &:focus {
            text-decoration: none;
            color: $black;
            &:before {
                position: relative;
                left: 0;
            }
        }   
    }
}

@mixin cta-button-outline {
    display: block;
    font-size: 14px;
    font-weight: $bold;
    letter-spacing: 1.4px;
    color: $blue;
    text-transform: uppercase;
    padding: 15px 25px;
    border: 1px solid $blue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px;
    &:after {
        content: url('../../Images/Base/dist/icon-arrow-right.svg');
        transition: all 200ms ease-in-out;
        padding-left: 20px;
        position: relative;
        top: 1px;
        right: 5px;
    }
    &:hover, &:focus {
        text-decoration: none;
        &:after {
            position: relative;
            top: 1px;
            right: -5px;
        }
    }   
    &:active {
        text-decoration: none;
        color: $blue;
    }
}

@mixin cta {
    font-size: 14px;
    font-weight: $bold;
    letter-spacing: 1.35px;
    text-transform: uppercase;
    color: $black;
    display: block;
    margin: 30px 0 10px 0;
}


// LISTS ============================================== *
// Reset
@mixin list-reset {
    list-style: none;
    padding: 0;
    margin: 0;
}


// MEDIA QUERIES =============================================== *
// Min-width
@mixin min-screen($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (min-width: $value) {
        @content;
    }
}

    // If the key doesn't exist in the map
    @else {
        @warn "No value could be retrieved from `#{$breakpoint}`. "
            + "Make sure it is defined in `$breakpoints` map.";
    }
}

// Max-width
@mixin max-screen($breakpoint) {
    // Retrieves the value from the key
    $value: map-get($breakpoints, $breakpoint);

    // If the key exists in the map
    @if $value != null {
        // Prints a media query based on the value
        @media (max-width: $value) {
        @content;
    }
}

// If the key doesn't exist in the map
@else {
    @warn "No value could be retrieved from `#{$breakpoint}`. "
        + "Make sure it is defined in `$breakpoints` map.";
    }
}



