.imagewithoverlay {
    margin: 50px 0 50px 0;
    position: relative;
    .image {
        img {
            width: 100%;
        }

        width: 100%;
        //height: 100%;
        background-color: $light-grey;
        position: relative;
        z-index: 0;

        .angle-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-155deg, transparent 0%, transparent 90%, white 90%, white 100%);
        }
        .gradient-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(105deg, $cyan 0%, transparent 35%, rgba($color: $black, $alpha: 0.5) 60%);
        }
    }
    .overlay-caption {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        padding: 50px 20px;
        .caption-wrap {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            span {
                display: block;
                font-weight: $bold;
                font-size: 24px;
                line-height: 1.3;
                color: $white;
            }
        }
    }
}

@import "imagewithoverlay-mobile";
