.testimonial {
    padding: 50px 20px 50px;
    margin-bottom: 30px;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    border-radius: 4px;
}

.testimonial.bg_variant2 {
    background-color: #b6b8dd;
}

.testimonial.bg_variant3 {
    background-color: #f4f4f4;
}

.testimonial_image {
    height: 100%;
    border-radius: 100%;
    border: 6px solid #fff;
    overflow: hidden;
}

.testimonial_image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.testimonial_text {
    font-size: 18px;
    color: #000000;
    line-height: 165%;
}

.testimonial_text::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='14' viewBox='0 0 448 512'%3E%3Cpath d='M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;
    width: 100%;
    height: 30px;
    content: '';
    display: block;
}

.testimonial_text::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='14' viewBox='0 0 448 512'%3E%3Cpath d='M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    width: 100%;
    height: 30px;
    content: '';
    display: block;
}

.testimonial_data {
    text-align: center;
}

.testimonial_name {
    font-size: 38px;
    font-weight: 500;
    color: #0A1980;
    line-height: 130%;
    display: block;
}

.testimonial_credentials {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    line-height: 165%;
    display: block;
}

.testimonial_text,
.testimonial_data,
.testimonial_name,
.testimonial_credentials {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: auto;
}

.testimonialVertical {
    height: 100%;
}
.testimonialVertical .testimonial_imageWrap {
    width:77%;
    height: 250px;
}

@media screen and (min-width: 992px) {
    .testimonialHorizontal {
        display: grid;
        column-gap: 30px;
        grid-template-columns: auto auto;
        grid-template-rows: auto 1fr;
    }

    .testimonialHorizontal .testimonial_imageWrap {
        grid-area: 1/1/-1/2;
        width: 250px;
        height: 77%;
    }

    .testimonialHorizontal .testimonial_text {
        grid-area: 1/2/2/3;
    }

    .testimonialHorizontal .testimonial_data {
        text-align: left;
        grid-area: 2/2/3/3;
    }
}

@media screen and (min-width: 1200px) {
    .testimonial {
        padding: 50px 30px 50px;
    }
}
