@include max-screen(md) {
    .membership-application-wrapper {
        .step-2-container {
            .email-password-container {
                .field-wrap {

                    .show-password-wrapper {
                        float: left;
                        margin-top: 15px;

                        input.show-password {
                            width: auto;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}