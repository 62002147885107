.eventsplash {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    img {
        width: 100%;
    }

    
}

.eventsplash-callout {
    width: 100%;
    bottom: 0;
    background-color: $blue;
    border-bottom: 2px solid $white;

    .container {
        max-width: unset;
        padding-left: 0;
        padding-right: 0;
    }

    .content {
        padding: 30px 15px;
        margin-left: auto;
        margin-right: auto;

        a {
            transition: none;
            color: $white;
        }

        @media (min-width: 768px) {
            max-width: 768px;
        }

        @media (min-width: 1024px) {
            max-width: 1024px;
        }

        @media (min-width: 1190px) {
            max-width: 1200px;
        }

        @media (min-width: 1400px) {
            max-width: 1440px;
        }

        a:hover {
            text-decoration-color: white;
            -webkit-text-decoration-color: white;
        }

        h1 {
            color: $white;
        }

        p {
            color: $white;
            margin: 0;
        }

        .cta {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            a, a:visited {
                @include cta-button-outline;
                color: $black;
                border: 1px solid $white;
                background-color: $white;

                &:after {
                    content: url('../../Images/Base/dist/icon-arrow-right.svg');
                }
            }
        }
    }
}

@import "eventherobanner-mobile.scss";
