@include max-screen(sm) {
    .nextprevnav {
        ul {
            flex-direction: column;
            li {
                &.prev {
                    margin-bottom: 20px;
                }
            }
        }
    }
}