.membership-application-wrapper {
    .fellowships-container {
        .button {
            @include primary-button;
            display: inline-block;
        }

        .alt-button {
            @include secondary-button;
            display: inline-block;
            margin: 10px 0;
        }
    }

    .agreements-container {
        input[type="checkbox"] + label.member-join {
            display: inline;
            margin: 5px 0px;
        }
    }
}
