.thankyou {
    h1 {
        margin-bottom: 20px;
    }
    h3 {
        margin-bottom: 20px;
        line-height: 1.3;
    }
    .id {
        font-weight: $bold;
        margin-bottom: 20px;
        display: block;
    }
    p {
        margin-bottom: 20px;
    }
    .sidebar {
        background-color: $lighter-grey;
        padding: 40px 30px;
        margin-bottom: 100px;
        h4 {
            line-height: 1.2;
            margin-bottom: 20px;
        }
        .cta {
            @include primary-button;
            margin-bottom: 20px;
        }
        .divider {
            border-color: $lighter-grey;
        }
    }
    .order-summary {
        margin: 50px 0;
        padding: 30px 0;
        border-top: 1px solid $light-grey;
        .notes {
            margin-bottom: 10px;
            padding: 15px;
            .note {
                display: block;
                font-size: 14px;
                .label {
                    margin-right: 5px;
                    font-weight: $bold;
                }
            }
            &.light-grey {
                background-color: $lighter-grey;
                margin: 0 -15px;
                // padding-left: 15px;
                // padding-right: 15px;
            }
        }
        .item, .total {
            border-bottom: 1px solid $light-grey;
            padding: 20px 0;
            .desc {
                a, a:visited {
                    @include headline-link;
                }
                span {
                    display: block;
                    &.line1 {
                        font-weight: $bold;
                    }
                    &.line2 {
                        font-weight: $regular;
                    }
                }
            }
            .qty {
                span {
                    display: flex;
                    justify-content: flex-start;
                    font-weight: $regular;
                }
            }
            .cost {
                span {
                    display: flex;
                    justify-content: flex-end;
                    font-weight: $bold;
                }
            }
            &.light-grey {
                background-color: $lighter-grey;
                margin: 0 -15px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .total {
            border-bottom: none;
            .desc {
                span {
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }
            }
        }
        .payment-info {
            span {
                display: block;
                &.line2 {
                    margin-bottom: 50px;
                }
            }
        }
    }
}

@import "thankyou-mobile";