/* ******************************************************
   Main Import File
        - Base Imports (Varialbes/Utilities/Base styles)
        - Components (Navigation/Tools/Modules)
        - Misc. Imports (Print/admin/styleguide)
   ****************************************************** */
/* ================================================================
   Base Imports
   ================================================================ */
/* ================================================================
   Variables
   ================================================================ */
/* ================================================================
   PLACEHOLDERS
   ================================================================ */

/* ================================================================
   MIXINS
   ================================================================ */
/* ================================================================
   REM Mixin 
   ================================================================ */
/* ================================================================
   Misc Helpful Mixins
   ================================================================ */
/* ================================================================
   Outdated breakpoint mixin
   ================================================================ */
/* ================================================================
   Boilerplate - Base Styles 
   ================================================================ */
html {
  color: #000;
}

body {
  font-family: "Lato", sans-serif;
  line-height: 1.4;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

::selection {
  background: #1226aa;
  color: #fff;
  text-shadow: none;
}

img {
  max-width: 100%;
  height: auto;
}

.visuallyhidden,
.visually-hidden,
.visuallyHidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  border: 0;
  width: 1px;
  height: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus,
.visually-hidden.focusable:active,
.visually-hidden.focusable:focus,
.visuallyHidden.focusable:active,
.visuallyHidden.focusable:focus {
  position: static;
  overflow: visible;
  clip: auto;
  margin: 0;
  width: auto;
  height: auto;
}

.visuallyhidden.skip-link:focus,
.visuallyhidden.skip-link:active,
.visually-hidden.skip-link:focus,
.visually-hidden.skip-link:active,
.visuallyHidden.skip-link:focus,
.visuallyHidden.skip-link:active {
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  background: #f8df8d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

body span.gc-cs-link {
  color: inherit;
}

body span.gc-cs-link:hover {
  text-decoration: none;
}

.js-click-user *:focus,
.js-click-user *:active {
  outline: 0;
}

.svg-legend {
  position: absolute;
  top: -9999em;
  left: -9999em;
  width: 0;
  height: 0;
  overflow: hidden;
}

.svg-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  fill: currentColor;
  transition: all 0.3s ease-in-out;
}

/* ================================================================
   Keyframes
   ================================================================ */
/* Fade In */
@keyframes fadeIn {
  100% {
    opacity: 0;
  }

  0% {
    opacity: 1;
  }
}

/* Fade Out */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* ================================================================
   Typography Styles
   ================================================================ */
.lazy {
  opacity: 0;
  background-image: none !important;
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

/* ================================================================
   THEMES / SUB SITE STYLES
   ================================================================ */
/* ================================================================
   Component Imports - Comment/uncomment what you don't need/need.
   ================================================================ */
/* ================================================================
   Slices
   ================================================================ */


.slice.backgorundImage {
  background-size: cover;
  background-repeat: no-repeat;
}

.slice__content {
  padding: 2rem 15px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .slice__content {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .slice__content {
    max-width: 1024px;
  }
}

@media (min-width: 1190px) {
  .slice__content {
    max-width: 1200px;
  }
  .slice-width-01 .slice__content {
    max-width: 1275px;
  }
}

@media (min-width: 1400px) {
  .slice__content {
    max-width: 1528px;
  }
}

.slice__content .slice__content.slice__content--video {
  padding: 0;
}

.slice__title {
  color: inherit;
  font-weight: 400;
  font-size: 45px;
  line-height: 64px;
  margin-bottom: 40px;
}

@media (min-width: 1200px) {
  .slice__title {
    font-size: 60px;
  }
}

.slice--no-padding .slice__content {
  padding: 0;
}

.slice--no-padding-top .slice__content {
  padding-top: 0;
}

.slice--no-padding-bottom .slice__content {
  padding-bottom: 0;
}

.slice--centered {
    text-align: center;

    &.backgroundImage {
        background-size: cover;
    }
}

.slice--padded {
    padding: 30px;

    .slice__filling {
        padding: 20px;
    }
}


.microsite-content-wrapper .content-block {
  position: relative;
}

.microsite-content-wrapper .sociallinks {
  top: -40px;
}

/* SITE HEADER and FOOTER THEMEING */
.site--theme-color-01 [class*="site-themed-slice"] {
  background: #f2f2f2;
}
@media (min-width: 1200px) {
  .site--theme-color-01 .more-menu .collapsible-panel {
    background: #f2f2f2;
  }
}
@media (max-width: 1199px) {
  .site--theme-color-01 .more-menu {
    background: rgb(242, 242, 242);
    background: rgba(242, 242, 242, 0.9);
  }
}
.site--theme-color-01 .more-menu .collapsible-toggle:after,
.site--theme-color-01 .collapsible-toggle-mobile:after {
  border-color: #000;
}

.site--theme-color-02 [class*="site-themed-slice"] {
  background: #4b4c4c;
}
@media (min-width: 1200px) {
  .site--theme-color-02 .more-menu .collapsible-panel {
    background: #4b4c4c;
  }
}
@media (max-width: 1199px) {
  .site--theme-color-02 .more-menu {
    background: rgb(75, 76, 76);
    background: rgba(75, 76, 76, 0.9);
  }
}

.site--theme-color-03 [class*="site-themed-slice"] {
  background: #0a1980;
}
@media (min-width: 1200px) {
  .site--theme-color-03 .more-menu .collapsible-panel {
    background: #0a1980;
  }
}
@media (max-width: 1199px) {
  .site--theme-color-03 .more-menu {
    background: rgb(10, 25, 128);
    background: rgba(10, 25, 128, 0.9);
  }
}
.site--theme-color-03 a,
.site--theme-color-03 a:visited {
  color: #1226aa;
}

.site--theme-color-04 [class*="site-themed-slice"] {
  background: #b6b8dd;
}
@media (min-width: 1200px) {
  .site--theme-color-04 .more-menu .collapsible-panel {
    background: #b6b8dd;
  }
}
@media (max-width: 1199px) {
  .site--theme-color-04 .more-menu {
    background: rgb(182, 184, 221);
    background: rgba(182, 184, 221, 0.9);
  }
}
.site--theme-color-04 .more-menu .collapsible-toggle:after,
.site--theme-color-04 .collapsible-toggle-mobile:after {
  border-color: #000;
}

.site--theme-color-05 [class*="site-themed-slice"] {
  background: #ccdce7;
}
@media (min-width: 1200px) {
  .site--theme-color-05 .more-menu .collapsible-panel {
    background: #ccdce7;
  }
}
@media (max-width: 1199px) {
  .site--theme-color-05 .more-menu {
    background: rgb(204, 220, 231);
    background: rgba(204, 220, 231, 0.9);
  }
}
.site--theme-color-05 .more-menu .collapsible-toggle:after,
.site--theme-color-05 .collapsible-toggle-mobile:after {
  border-color: #000;
}

.site--theme-color-06 [class*="site-themed-slice"] {
  background: #005288;
}

.site--theme-color-06 a,
.site--theme-color-06 a:visited {
  color: #005288;
}

@media (min-width: 1200px) {
  .site--theme-color-06 .more-menu .collapsible-panel {
    background: #005288;
  }
}

@media (max-width: 1199px) {
  .site--theme-color-06 .more-menu {
    background: rgb(0, 82, 136);
    background: rgba(0, 82, 136, 0.9);
  }
}

.site--theme-color-07 [class*="site-themed-slice"] {
  background: #f8df8d;
}
@media (min-width: 1200px) {
  .site--theme-color-07 .more-menu .collapsible-panel {
    background: #f8df8d;
  }
}
@media (max-width: 1199px) {
  .site--theme-color-07 .more-menu {
    background: rgb(248, 223, 141);
    background: rgba(248, 223, 141, 0.9);
  }
}
.site--theme-color-07 .more-menu .collapsible-toggle:after,
.site--theme-color-07 .collapsible-toggle-mobile:after {
  border-color: #000;
}

.site--theme-color-08 [class*="site-themed-slice"] {
  background: #fac535;
}
@media (min-width: 1200px) {
  .site--theme-color-08 .more-menu .collapsible-panel {
    background: #fac535;
  }
}
@media (max-width: 1199px) {
  .site--theme-color-08 .more-menu {
    background: rgb(250, 197, 53);
    background: rgba(250, 197, 53, 0.9);
  }
}
.site--theme-color-08 .more-menu .collapsible-toggle:after,
.site--theme-color-08 .collapsible-toggle-mobile:after {
  border-color: #000;
}

.site--theme-color-09 [class*="site-themed-slice"] {
  background: #1226aa;
}
@media (min-width: 1200px) {
  .site--theme-color-09 .more-menu .collapsible-panel {
    background: #1226aa;
  }
}
@media (max-width: 1199px) {
  .site--theme-color-09 .more-menu {
    background: rgb(18, 38, 170);
    background: rgba(18, 38, 170, 0.9);
  }
}

.site--theme-color-02 [class*="site-themed-slice"],
.site--theme-color-03 [class*="site-themed-slice"],
.site--theme-color-06 [class*="site-themed-slice"],
.site--theme-color-09 [class*="site-themed-slice"] {
  color: #fff;
}

.site--theme-color-02 [class*="site-themed-slice"] a,
.site--theme-color-03 [class*="site-themed-slice"] a,
.site--theme-color-06 [class*="site-themed-slice"] a,
.site--theme-color-09 [class*="site-themed-slice"] a {
  color: #fff;
}

/* SLICE THEMEING */
.slice--theme-color-01 {
  background: #f2f2f2;
}

.slice--theme-color-02 {
  background: #4b4c4c;
}

.slice--theme-color-03 {
  background: #0a1980;
}

.slice--theme-color-04 {
  background: #b6b8dd;
}

.slice--theme-color-05 {
  background: #ccdce7;
}

.slice--theme-color-06 {
  background: #005288;
}

.slice--theme-color-07 {
  background: #f8df8d;
}

.slice--theme-color-08 {
  background: #fac535;
}

.slice--theme-color-09 {
  background: #1226aa;
}

.slice--theme-color-02,
.slice--theme-color-03,
.slice--theme-color-06,
.slice--theme-color-09 {
  color: #fff;
}

.slice--theme-color-02 a,
.slice--theme-color-03 a,
.slice--theme-color-06 a,
.slice--theme-color-09 a {
  color: #fff;
}

/* ================================================================
   LINKS
   ================================================================ */
.content-block a,
.content-block a:visited,
.banner a,
.banner a:visited {
  padding: 0 2px 2px 2px;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #f3c300;
  border-left: 2px solid transparent;
}

.banner .banner__content > a:not(.button) {
  display: block;
  border-bottom-color: transparent;
}

.banner .banner__content > a:not(.button):hover {
  background-color: transparent;
}

.banner a:hover,
.banner a:visited:hover,
.content-block a:hover,
.content-block a:visited:hover {
  text-decoration: none;
  background-color: #f8df8d;
  color: inherit;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #f8df8d;
  border-left: 2px solid transparent;
}

.content-block a.thumbnail:hover,
.content-block a.thumbnail:visited:hover {
  background: none;
}

/* ================================================================
   BUTTONS
   ================================================================ */
.button,
[class*="button--theme"]:not(.link-list__list),
a.button,
a[class*="button--theme"] {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #000;
  border: 1px solid transparent;
  background-color: #f8df8d;
  padding: 11px 15px;
  cursor: pointer;

  &:after {
    content: ">";
    display: inline-block;
    line-height: 1;
    margin: 0 0.5em;
    transition: transform 0.3s ease-in-out;
  }

  &:hover:after,
  &:focus:after {
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
}

a.remove__link__decoration {
    &:hover {
        text-decoration: none;
        color: #000;
    }
}

.button:hover,
[class*="button--theme"]:not(.link-list__list):hover,
.button:focus,
[class*="button--theme"]:not(.link-list__list):focus,
a.button:hover,
a[class*="button--theme"]:hover,
a.button:focus,
a[class*="button--theme"]:focus {
  border: 1px solid transparent;
  text-decoration: none;
  background-color: #f3c300;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
}

.button:active,
[class*="button--theme"]:not(.link-list__list):active,
a.button:active,
a[class*="button--theme"]:active {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
}

/* button themes global */
.site--theme-color-03 .button,
.site--theme-color-03 a.button {
  background: #f8df8d;
}

.site--theme-color-03 .button:hover,
.site--theme-color-03 a.button:hover,
.site--theme-color-03 .button:focus,
.site--theme-color-03 a.button:focus {
  background: #f3c300;
}

.site--theme-color-06 .button,
.site--theme-color-06 a.button {
  background: #005288;
  color: #fff;
}

.site--theme-color-06 .button:hover,
.site--theme-color-06 a.button:hover,
.site--theme-color-06 .button:focus,
.site--theme-color-06 a.button:focus {
  background: #f3c300;
}

.link-list__list.link--theme-01 a {
  border: 0 !important;

  &:after {
    content: ">>>";
  }
}

.link-list__list.link--theme-02 {
  border-left-color: #f3c300;

  a {
    border: 0 !important;
    color: #fff !important;
    text-transform: uppercase !important;

    &:hover,
    &:focus {
      background: #f3c300 !important;
    }
  }
}

/* ================================================================
   Header
   ================================================================ */
.header-layout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .header-layout__logo {
    max-width: 60%;
    padding-left: 12px;
  }
}

@media (min-width: 768px) {
  .site-themed-slice--header .slice__content {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .site-themed-slice--header .slice__content {
    max-width: 1024px;
  }
}

@media (min-width: 1190px) {
  .site-themed-slice--header .slice__content {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {
  .site-themed-slice--header .slice__content {
    max-width: 1528px;
  }
}

/*  ================================================================
    CONTENT BLOCK
    ================================================================ */
.content-block h2,
.content-block h3,
.content-block p,
.card-list p,
.card-list .card__title {
  color: inherit;
}

.content-block:last-child p:last-child,
.content-block:last-child ul:last-child,
.content-block:last-child ol:last-child,
.content-block:last-child dl:last-child {
  margin-bottom: 0;
}
.link-list:last-child ul:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .content-block,
  .content-block div,
  .content-block p,
  .content-block ol,
  .content-block ul,
  .banner__description,
  .link-list ul,
  .link-list__list a:not([class*="button--theme-color-"]) {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (min-width: 769px) {
  .content-block,
  .content-block div,
  .content-block p,
  .content-block ol,
  .content-block ul,
  .banner__description,
  .link-list ul,
  .link-list__list a:not([class*="button--theme-color-"]) {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 36px;
  }
  .slice-font-size-01 .content-block,
  .slice-font-size-01 .content-block div,
  .slice-font-size-01 .content-block p,
  .slice-font-size-01 .content-block ol,
  .slice-font-size-01 .content-block ul,
  .slice-font-size-01 .banner__description,
  .slice-font-size-01 .link-list ul,
  .slice-font-size-01 .link-list__list a:not([class*="button--theme-color-"]) {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 28px;
  }
}

/* ================================================================
   Link List
   ================================================================ */
.link-list {
  overflow: hidden;
  margin: 0 auto;
}

.link-list__list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: center;
  padding: 0;
  list-style: none;
  margin-bottom: 40px;
}

.link-list__list a:after {
  margin-right: 0;
}

.link-list--dividers {
  display: inline-block;
}

.link-list__list .link-list__item {
  padding: 0 0.5em;
}

.link-list__list.link-list--dividers .link-list__item {
  margin: 0.5em 0;
  padding: 0 1em;
  border-left: 1px solid currentColor;
}

.link-list--dividers .link-list__item:first-child {
  border-left: 0;
}

@media (max-width: 768px) {
  .link-list--dividers .link-list__item {
    border-left: 0;
  }
}

.link-list--dividers .link-list__link:after {
  margin-right: 0;
}

.link-list--stacked .link-list__list {
  display: block;
}

.link-list__list a:not([class*="button--theme-color-"]) {
  color: #000;
  padding: 0 2px 2px 2px;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #f3c300;
  border-left: 2px solid transparent;
  background: none;
  text-transform: none;
}

.link-list__list a:not([class*="button--theme-color-"]):hover {
  background-color: #f8df8d;
  color: inherit;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #f8df8d;
  border-left: 2px solid transparent;
  text-decoration: none;
}

.link-list__list a:not([class*="button--theme-color-"]):focus {
  background-color: transparent;
  border-top: 2px solid #f3c300;
  border-right: 2px solid #f3c300;
  border-bottom: 2px solid #f3c300;
  border-left: 2px solid #f3c300;
}

.link-list__list a:hover:after,
.link-list__list a:focus:after {
  -ms-transform: translateX(3px);
  transform: translateX(3px);
}

.link-list__list a[class*="button--theme-color-"]:after {
  display: none;
}

/* ================================================================
   Media Embeds
   ================================================================ */
.media-embed__content {
  display: inline-block;
  background: #777;
}

.media-embed__image {
  display: block;
  transition: all 0.3s ease-in-out;
}

/* ================================================================
   Misc. Imports
   ================================================================ */
.color-block {
  display: inline-block;
  margin: 5px;
  text-align: center;
  width: 120px;
  height: 120px;
  border-radius: 240px;
}

.color-block.color-primary {
  background: #1226aa;
}

.color-block.color-secondary {
  background: #f8df8d;
}

/* ================================================================
   Overwrite main site styles
   ================================================================ */
/* container */
@media (min-width: 1400px) {
  .container {
    max-width: 1528px;
  }
}
.microsite-content-wrapper > .container:first-child {
  position: relative;
}

/* header */
header.desktop.shrink .top-nav {
  height: 68px;
}

header.desktop.shrink {
  height: 68px;
}


header.desktop.shrink .top-nav .logo img {
  width: 207px;
  height: 50px;
}
/* links */
p a,
p a:visited {
  color: inherit;
}

/* Styles Temporary here - to be moved in another file when folder created */
.membersubmenu.microsite-membersubmenu {
  margin-top: 0;

  .button {
    padding: 0;
    margin-bottom: 20px;
    width: 100%;
    border-bottom: 3px solid #f3c300;

    &:after {
      display: none;
    }

    .submenu-item {
      margin: 0;
      padding: 0;
      background: none;
      line-height: 1.2;
      border: 0;
      color: inherit !important;

      > div:first-child {
        font-size: 14px;
        line-height: 1.3;
      }
    }
  }
}

/* FAER footer Styles Temporary here - to be moved in another file when folder created */
.faer-footer-content {
  margin-bottom: 40px;

  p {
    margin-bottom: 0;
  }

  .faer-logo-btn {
    .button {
      font-size: 24px;
      font-weight: 400;
      background: #f3c300 !important;
      color: #000 !important;
      margin-top: 40px;

      &:hover {
        background: #f8df8d !important;
        color: #000 !important;
      }
    }
  }

  .faer-footer-links {
    > div {
      margin-bottom: 40px;
    }

    h3 {
      margin: 0;
      text-transform: uppercase;
      font-weight: 400;

      a {
        border: 0;
        background: none;
        color: inherit;

        &:hover {
          background: none;
          border: 0;
          text-decoration: underline;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0;

        a {
          border: 0;
          background: none;
          color: inherit;

          &:hover {
            background: none;
            border: 0;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .faer-headquarters-title {
    margin: 0;
    font-weight: 400;
  }

  .social-links {
    list-style: none;
    padding: 0;
    margin: 40px 0 0 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;

    li {
      margin-right: 10px;

      a {
        border: 0;
        background: none;
        padding: 10px;
        border: 2px solid #005288;
        border-radius: 50%;
        display: block;
        line-height: 1;

        svg {
          width: 2rem;
          height: 2rem;
          fill: #005288;
        }
      }
    }
  }

  @media (min-width: 769px) {
    .faer-footer-links {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      > div {
        width: 50%;
        margin-bottom: 40px;
      }
    }
  }

  @media (max-width: 768px) {
    .faer-footer-links,
    .faer-logo-btn {
      margin-bottom: 40px;
    }
  }
}

.content-block .faer-footer-content {
  ul,
  p,
  div {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    ul,
    p,
    div {
      font-size: 16px;
    }
  }
}
