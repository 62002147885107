.sponsoredborder {
    border-top: 2px solid $bright-green;
    width: 100%;
    margin: 0 0 50px 0;

    &:last-child {
        margin-top: 100px;
    }

    .span-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            position: relative;
            bottom: 12px;
            display: inline-block;
            background-color: $bright-green;
            padding: 4px 15px;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: $extra-bold;
            letter-spacing: 0.5px;
            margin: 0 auto;
            color: $white;
        }
    }
}

@import "sponsoredcontent-mobile";
