.course-callouts {
    margin: 40px 0 15px 0;

    ul {
        @include list-reset;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 50px;

        li {
            margin: 0 40px 0 0;
            font-size: 14px;
            letter-spacing: 1.4px;
            color: $black;
            font-weight: $bold;
            text-transform: uppercase;
            display: flex;
            align-items: center;

            &.course-alert {
                &:before {
                    content: url('../../Images/Base/dist/icon-alert.png');
                    margin-right: 15px;
                }
            }

            &.course-location {
                &:before {
                    content: url('../../Images/Base/dist/icon-online.png');
                    margin-right: 15px;
                }
            }

            &.course-link {
                a {
                    color: black;
                    display: contents;
                }
            }
        }
    }

    .course-credits {
        font-size: 16px;
        letter-spacing: 1.4px;
        color: $black;
        font-weight: $bold;
        text-transform: uppercase;
    }

    .course-time-to-complete {
        font-size: 16px;
        letter-spacing: 1.4px;
        color: $black;
        text-transform: uppercase;
    }
}

@import "coursecallouts-mobile";
