.general-article {
    h3 {
        margin: 30px 0px;
    }

    p {
        margin: 24px 0px;
    }

    ul {
        margin: 0 0 42px 0px;
        li {
            margin-bottom: 20px;
        }
    }
}
