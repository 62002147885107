@include max-screen(xl) {
    .splashmain {
        max-height: 740px;

        .angle-overlay {
            background: transparent linear-gradient(-165deg, transparent 0%, transparent 55%, $blue 55%, $blue 100%);
            opacity: 1;
        }
    }
}


@include max-screen(lg) {
    .splashmain {
        height: 100%;
        max-height: none;
        position: relative;
        overflow: auto;
        /*img {
            height: auto;
        }*/
        .shapes-overlay {
            background: url('../../Images/Base/dist/splash-shapes.svg') no-repeat -560px -350px; // L/R, UP/DN
        }

        .angle-overlay {
            background: transparent linear-gradient(-172deg, transparent 0%, transparent 43%, $blue 43%, $blue 100%);
            opacity: 1;
        }

        .splash-content {
            position: relative;
            background-color: transparent;
            padding: 20px 0 20px 0;
            top: 0;
            background-color: $blue;

            h1 {
                width: 50%;
                color: $white;
                max-width: none;
            }

            .cta {
                max-width: none;
                color: $white;
                border: 1px solid $white;

                &:after {
                    content: url('../../Images/Base/dist/icon-arrow-right-white.svg');
                }
            }
        }

        .splash-menu {
            position: relative;
        }
    }
}


@include max-screen(md) {
    .splashmain {

        .shapes-overlay {
            background: url('../../Images/Base/dist/splash-shapes.svg') no-repeat -360px -350px; // L/R, UP/DN
        }

        .angle-overlay {
            background: transparent linear-gradient(-174deg, transparent 0%, transparent 48%, $blue 48%, $blue 100%);
        }

        .splash-content {
            h1 {
                width: 50%;
            }

            .cta {
            }
        }

        .splash-menu {
        }
    }
}


@include max-screen(sm) {
    .splashmain {

        .gradient-overlay {
            display: none;
        }

        .shapes-overlay {
            background: url('../../Images/Base/dist/splash-shapes.svg') no-repeat -560px -350px; // L/R, UP/DN
        }

        .angle-overlay {
            background: transparent linear-gradient(-170deg, transparent 0%, transparent 38%, $blue 38%, $blue 100%);
        }

        .splash-content {
            top: 0;
            //padding-bottom: 0;
            h1 {
                width: 80%;
                font-size: 32px;
            }
        }

        .splash-menu {
            width: 100%;
            height: auto;
            background-color: rgba($color: $dark-blue, $alpha: 1);
            background: none;

            ul {
                @include list-reset;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                margin: 0 -25px;
                height: auto;

                li {
                    margin-right: 0;
                    width: 100%;

                    a, a:visited {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 74px;
                        border-bottom: 1px solid rgba($color: $white, $alpha: 0.76);
                        padding: 0 20px;
                        background-color: rgba($color: $dark-blue, $alpha: 1);
                        font-size: 18px;
                        color: $white;
                        font-weight: $bold;

                        &:hover, &:focus {
                            text-decoration: none;
                            background-color: $orange;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen(xs) {
    .splashmain {
        .gradient-overlay {
            display: none;
        }

        .shapes-overlay {
            background: url('../../Images/Base/dist/splash-shapes.svg') no-repeat -560px -350px; // L/R, UP/DN
        }

        .angle-overlay {
            background: transparent linear-gradient(-170deg, transparent 0%, transparent 28%, $blue 28%, $blue 100%);
        }

        .splash-content {
            h1 {
                width: 100%;
                font-size: 32px;
            }
        }
    }
}
