.change-step-container {
    margin-top: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    button {
        @include primary-button;
        cursor: pointer;
        transition: all 200ms ease-in-out;

        &.disabled {
            border: 1px solid $light-grey;
            background-color: $light-grey;
            color: $grey;

            &:hover, &:focus {
                cursor: default;
                border: 1px solid $light-grey;
                background-color: $light-grey;
                color: $grey;
                box-shadow: none;
            }
        }
    }
}
