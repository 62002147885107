@include max-screen(md) {
	.rt-container {
		ul, ol {
			margin-bottom: 40px;
		}
		&.external-links {
			h2 {
				a[name] {
					display: block;
					height: inherit;
					&::before {
						content: '';
						display: block;
						height: 8rem;
						margin-top: -8rem;
					}
				}
			}
		}
	}
}
