/* ================================================================
   reshape-rssfeedS
   ================================================================ */
.reshaperssfeed {
    padding: 30px 0;
    background-color: #EDECEC;
}


.reshaperssfeed__row {
    background-color: #FFFFFF;
    padding: 30px;
    margin: auto;
    display: block;
    max-width: 100%;
}

.reshaperssfeed_sectionTitle {
    margin-bottom: 45px;
}

.reshaperssfeed_item {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 60px;
}

.reshaperssfeed_itemTitle {
    display: block;
    font-weight: 700;
}

.reshaperssfeed_itemTitle a {
    color: #333;
}

.reshaperssfeed_itemTitlea::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    bottom: -20px;
    z-index: 10;
    border-radius: 10px;
    transition: all 0.4s;
}

.reshaperssfeed_itemTitle a:hover {
    color: #333;
}

.reshaperssfeed_itemTitle a:hover::before {
    box-shadow: -2px 1px 18px 4px rgba(70, 70, 70, 0.12);
}

.reshaperssfeed_itemTag {
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 16px;
}

.reshaperssfeed_itemImage {
    margin-top: auto;
    width: 100%;
    aspect-ratio: 2;
    border-radius: 4px;
    overflow: hidden;
}

.reshaperssfeed_itemImage img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.reshaperssfeed_coverImageWrap {
    margin: auto;
}

.reshaperssfeed_coverImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.reshaperssfeed_listingWrap {
}

@include min-screen(md) {
    .reshaperssfeed__row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        max-width: 100%;
    }

    .reshaperssfeed_coverImageWrap {
        grid-area: 2/3/-1/4;
        margin: auto;
    }

    .reshaperssfeed_listingWrap {
        grid-area: 2/1/-1/3;
    }
}