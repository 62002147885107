/* Videos */
.video-responsive-container {
    width: 720px;
    max-width: 100%;
    margin: 0 auto;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.video__list .col-md-6 {
    margin-top: 30px;
}

.video__list .col-md-6:first-child {
    margin-top: 0px;
}

@media (min-width: 1024px) {
    .video__list .col-md-6:nth-child(2) {
        margin-top: 0px;
    }
}
