.featuredevent {
    margin: 50px 0;

    .featuredevent-image {
        img {
            width: 100%;
        }

        width: 100%;
        background-color: $light-grey;
        position: relative;
        z-index: 0;

        .angle-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-155deg, transparent 0%, transparent 92%, white 92%, white 100%);
        }

        .gradient-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-125deg, transparent 0%, transparent 40%, $blue 100%);
        }
    }

    .featuredevent-details {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 40px 0;

        .date {
            height: 100%;
            min-height: 115px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;
            margin-right: 100px;

            .month {
                display: block;
                font-size: 14px;
                font-weight: $bold;
                color: $black;
                letter-spacing: 1.5px;
                text-transform: uppercase;
                margin: 0;
                line-height: 1;
            }

            .day {
                display: block;
                font-size: 100px;
                font-weight: $light;
                color: $black;
                margin: 0;
                line-height: 1.2;
            }
        }

        .event {
            height: 100%;
            min-height: 115px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;

            .tag {
                display: block;
                font-size: 14px;
                font-weight: $extra-bold;
                color: $purple;
                letter-spacing: 1.5px;
                text-transform: uppercase;
            }

            .name {
                display: block;
                font-size: 28px;
                line-height: 1.3;
                letter-spacing: 0.5px;
                font-weight: $extra-bold;
                color: $black;
                text-transform: uppercase;
            }

            .whenwhere {
                .dates {
                    display: inline-block;
                    font-size: 18px;
                    color: $dark-grey;
                    margin-right: 10px;
                }

                .location {
                    display: inline-block;
                    font-size: 18px;
                    color: $dark-grey;
                    margin-left: 10px;
                }
            }
        }
    }
}

@import "eventheader-mobile";
