@include max-screen(bootstrap-md) {
    .col.columns-5 {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        flex-basis: unset;
    }

    .col.columns-6 {
        position: relative;
        width: 50%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        flex-basis: unset;
    }
}
