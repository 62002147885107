.listing {
    margin: 50px 0;

    .articlebox-image {
        margin-bottom: 30px;

        img {
            width: 100%;
        }

        width: 100%;
        //height: 100%;
        background-color: $light-grey;
        position: relative;
        z-index: 0;
        height: 258px;
        overflow: hidden;

        .sponsored-banner {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            background-color: $bright-green;
            padding: 3px 5px;
            color: $white;
            font-size: 9px;
            font-weight: $bold;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            text-align: center;
        }

        &.sponsored {
            .sponsored-banner {
                display: inline-block;
            }
        }
        /*.angle-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-155deg, transparent 0%, transparent 83%, white 83%, white 100%);
        }*/
        .left-side {
            border-left: calc(0vw + 1px) solid transparent;
            border-bottom: 80px solid $white;
            position: absolute;
            z-index: 2;
            bottom: 0;
            width: 0;
            height: 0;
            opacity: 1;
        }

        .right-side {
            border-right: 10vw solid transparent;
            /* right: 0; */
            left: 0;
            border-bottom: 80px solid $white;
            position: absolute;
            z-index: 2;
            bottom: 0;
            width: 0;
            height: 0;
            opacity: 1;
        }

        .gradient-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-125deg, transparent 0%, transparent 40%, $blue 100%);
        }
    }

    .articlebox-content {
        width: 100%;
        min-height: 160px;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;

        h4 {
            line-height: 1.2;
            margin-bottom: 20px;
        }

        .subhead {
            width: 100%;
            height: 18px;
            margin-bottom: 15px;

            span {
                display: block;
                font-size: 12px;
                font-weight: $extra-bold;
                letter-spacing: 1.6px;
                text-transform: uppercase;
                color: $blue;

                &.hide {
                    display: none;
                }
            }
        }

        .cta {
            @include cta-button-underline;
            margin-top: auto;
        }
    }
}

@import "listing-mobile";
