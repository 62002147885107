.eventsrollup {
    margin: 30px 0 100px 0;

    h2 {
        margin-bottom: 30px;
    }

    .articlebox-image {
        margin-bottom: 30px;

        img {
            width: 100%;
        }

        width: 100%;
        /*background-color: $light-grey;*/
        position: relative;
        z-index: 0;
        height: 463px; // Match Events List Height
        overflow: hidden;

        .gradient-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-70deg, transparent 0%, transparent 50%, $blue 85%);
        }

        .shapes-overlay {
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: url('../../Images/Base/dist/splash-shapes.svg') no-repeat -590px -260px; // L/R, UP/DN
        }
        /*.angle-overlay {
            position: absolute;
            z-index: 3;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-155deg, transparent 0%, transparent 83%, white 83%, white 100%);
        }*/
        /* Angle can be adjusted by left side and right side's vw's. They should add up to 100vw plus 1px. */
        .left-side {
            border-left: calc(0vw + 1px) solid transparent;
            border-bottom: 120px solid $white;
            position: absolute;
            z-index: 2;
            bottom: 0;
            width: 0;
            height: 0;
            opacity: 1;
        }

        .right-side {
            border-right: 15vw solid transparent;
            /* right: 0; */
            left: 0;
            border-bottom: 120px solid #fff;
            position: absolute;
            z-index: 2;
            bottom: 0;
            width: 0;
            height: 0;
            opacity: 1;
        }
    }

    .articlebox-content-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .event-date {
            margin-right: 20px;

            .month {
                display: block;
                font-size: 12px;
                font-weight: $bold;
                letter-spacing: 1.7px;
                line-height: 1;
                text-transform: uppercase;
                color: $black;
            }

            .day {
                display: block;
                font-size: 64px;
                font-weight: $light;
                line-height: 1;
                letter-spacing: 0;
                color: $black;
                position: relative;
                left: -5px;

                &.multiple {
                    font-size: 48px;
                }
            }
        }

        .articlebox-content {
            width: 100%;
            //min-height: 200px;
            display: flex;
            flex-direction: column;

            h3 {
                line-height: 1.2;
                //margin-bottom: 20px;
            }

            h4 {
                line-height: 1.2;
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 0;
            }

            .subhead {
                width: 100%;
                height: 18px;
                margin-bottom: 10px;

                span {
                    display: block;
                    font-size: 12px;
                    font-weight: $extra-bold;
                    letter-spacing: 1.6px;
                    text-transform: uppercase;
                    color: $blue;

                    &.hide {
                        display: none;
                    }

                    &.sponsored {
                        background-color: $bright-green;
                        padding: 3px 10px;
                        color: $white;
                        font-size: 9px;
                        font-weight: $bold;
                        text-transform: uppercase;
                        letter-spacing: 1.2px;
                        display: inline-block;
                    }
                }
            }

            .cta {
                @include cta-button-outline;
                margin: 0;
                border: none;
                padding: 0;
                display: inline;
                color: $black;

                &:hover, &:focus {
                    margin: 0;
                    border: none;
                    padding: 0;
                    color: $black;
                }
            }
        }
    }

    .events-list-wrap {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .events-list {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 463px; // Match Featured Image Height
            .articlebox-content-wrap {
                flex-direction: column;

                .articlebox-content {
                    .subhead {
                        margin: 10px 0;
                    }
                }
            }
        }

        .cta {
            @include cta-button-underline;
            margin-top: auto;
            margin-bottom: 0;
        }
    }
}

@import "eventsrollup-mobile";