/* ================================================================
   tiles
   ================================================================ */

.h3-tile {
    .h3-tile-header {
        margin: 8px 0px 8px
    }

    .h3-tile-description {
        margin-bottom: 12px;
        margin-top: 0px
    }

    .h3-tile-button {
        width: max-content;
    }

    .h3-tile-supported-by {
        margin: 4px 0px 28px 0px;
        font-size: 14px;
        font-weight: 600;
        padding: 0px;
        color: #096633
    }

    .member-exclusive-lock {
        margin: 4px 0px 12px 0px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1.4px;
        color: #000;
        text-transform: uppercase;
    }

    .member-exclusive-lock-image {
        height: 10px;
        width: 8px;
        vertical-align: middle;
        margin-bottom: 2px;
    }
}

.h4-tile {
    .h4-tile-header {
        margin: 8px 0px 8px
    }

    .h4-tile-description {
        margin-bottom: 12px;
        margin-top: 0px
    }

    .h4-tile-button {
        width: max-content;
    }

    .h4-tile-supported-by {
        margin: 4px 0px 28px 0px;
        font-size: 14px;
        font-weight: 600;
        padding: 0px;
        color: #096633
    }

    .member-exclusive-lock {
        margin: 4px 0px 12px 0px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1.4px;
        color: #000;
        text-transform: uppercase;
    }

    .h4-tile-cme-text {
        margin: 4px 0px 4px 0px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1.4px;
        color: #000;
        text-transform: uppercase;
    }


    .member-exclusive-lock-image {
        height: 10px;
        width: 8px;
        vertical-align: middle;
        margin-bottom: 2px;
    }
}