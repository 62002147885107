.membersubmenu {
    a, a:visited {
        text-decoration: none;

        .submenu-item {
            width: 100%;
            height: 60px;
            background-color: $light-yellow;
            border-bottom: 3px solid $yellow;
            margin-bottom: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-transform: uppercase;
            font-weight: $bold;
            font-size: 14px;
            line-height: 1.3;
            letter-spacing: 1.4px;
            color: $black;
            transition: all 200ms ease-in-out;
            padding: 10px 0 10px 30px;
        }

        &:hover, &:focus {
            text-decoration: none;

            .submenu-item {
                background-color: $yellow;
                color: $black;
            }
        }

        .locked::after {
            @include icon-lock;
            font-size: 14px;
            bottom: 2px;
            position: absolute;
        }

        .affordancesymbol {
            /*position: fixed;*/
            padding: 10px 20px 10px 10px;
            font-size: 30px;
            font-weight: normal;
        }
    }
}

@import "membersubmenu-mobile";