.sociallinks {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    margin: 40px 0;

    ul {
        @include list-reset;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0 15px;

        li {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    } 
}

.fa-facebook-f {
    color: $facebook-blue;
}

.fa-linkedin-in {
    color: $linked-in-blue;
}

.fa-twitter {
    color: $twitter-blue;
}

.fa-twitter-x {
    color: $twitter-X-black;
}

.fa-envelope, .fa-print {
    color: $icon-grey;
}

@import "socialsharing-mobile";
