.personify-control {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 30px;
    margin-top: 30px;
    p {
        text-transform: uppercase;
    }
}

.statePac {
    padding-bottom: 26px;
    padding-top: 26px;
}

@import "personifycontrol-mobile";