@include max-screen(md) {
    .account-name {
        .display-name {
            width: 100%;
            padding: 15px 0;
            padding-right: 50px;
            position: initial;
            span {
                font: {
                    family: $lato;
                    weight: $light;
                    size: 36px;
                }
                color: $blue;
                line-height: 39px;
                letter-spacing: 0.3px;
                display: inline-block;
            }
        }
        .edit-button {
            &.desktop {
                display: none;
            }
            &.mobile {
                display: flex;
                //position: initial;
                width: auto;
                &:before {
                    margin: 0;
                }
            }
        }
    }    
}