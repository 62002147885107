@include max-screen(xl) {
    .splash-menu {
    }
}


@include max-screen(lg) {
    .splash-menu {
        position: relative;
    }
}


@include max-screen(md) {
    .splash-menu {
    }
}


@include max-screen(sm) {
    .splash-menu {
        height: auto;
        background-color: $ivory;

        .container {
            border-bottom: unset;
            padding: 0;
        }

        ul {
            @include list-reset;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            height: auto;

            li {
                margin-right: 0;
                width: 100%;

                a, a:visited {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 74px;
                    border-bottom: 1px solid rgba($color: $white, $alpha: 0.76);
                    padding: 0 20px;
                    background-color: transparent;
                    font-size: 18px;
                    color: $black;
                    font-weight: $bold;
                    border-bottom: 2px solid $blue;

                    &:hover, &:focus {
                        text-decoration: none;
                        background-color: $light-purple;
                    }
                }
            }
        }
    }
}
