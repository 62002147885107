.panel {
    .readonly {
        .location {
            span {
                &:first-child {
                    display: block;
                }

                &:last-child {
                    display: inline-block;
                    margin-bottom: 15px;
                }

                &.primary-label, &.address-type-label, &.name-label {
                    display: block;
                }

                &.primary-label {
                    font-style: italic;
                    font-weight: bold;
                    color: $blue;
                }

                &.address-type-label {
                    text-transform: capitalize;
                    font-weight: bold;
                }
            }
        }
    }
}
.form-wrap {
    .field-wrap {
        label {
            display: block;
            font-weight: $regular;
        }
        input[type="text"] {
            width: 100%;
        }
        .save-button {
            display: inline-block;
        }
    }
    .save-button {
        margin-bottom: 15px;
    }
    .delete-button {
        margin-right: 0;
    }
}

.displayDistrict {
    display: inline-block;
}

.donotdisplayDistrict{
    display: none;
}

@import "contactinfo-mobile";