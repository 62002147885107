/* ================================================================
   reshape-heroS
   ================================================================ */
.reshape-hero__media {
    position: relative;
    max-width: 1070px;
    width: 100%;
    height: 0;
    padding-top: 56%;
    overflow: hidden;
    background: #fff;

    &.vertical-alignment {
        margin-left: auto;
        margin-right: auto;
        width: 65%;
    }
}

.reshape-hero__content.swap-divs {
    order: 1;
}

@media (min-width: 960px) {
    .reshape-hero__media {
        padding-top: 34%;
    }

    .vertical-alignment .reshape-hero__media {
        padding-top: 56%;
    }
}

@media (min-width: 1010px) {
    .reshape-hero__media {
        padding-top: 35.5%;
    }
}

@media (min-width: 1070px) {
    .reshape-hero__media {
        padding-top: 37%;
    }
}

@media (min-width: 1200px) {
    .reshape-hero__media {
        padding-top: 40%;
    }
}

.reshape-hero__media-img {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;

    &.edit-mode {
        position: relative;
        opacity: 1;
        width: 100%;
        height: 100%
    }
}

.reshape-hero__media-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
}


.reshape-hero__title {
    a {
        transition: none;

        &:hover {
            color: inherit;
        }
    }

    .reshape-hero__title-text {
        font-size: 40px;
        font-weight: 700;
        display: flex;
        justify-content: left;
        text-align: left;
        padding-bottom: 40px;
    }
}


@media (max-width: 959px) {
    .reshape-hero__title-text:first-child {    
        margin-top: 10px;
    }
}

.reshape-hero__category {
    display: block;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    color: $dark-grey;
}

.reshape-hero__description {
    margin-bottom: 40px;
    line-height: 2em;
    color: $dark-grey;

    p:last-child {
        margin-bottom: 0;
    }

    a {
        transition: none;

        &:hover {
            color: inherit;
            text-decoration: none;
        }
    }
}

@media (min-width: 60em) {
    .reshape-hero {
        display: -ms-flexbox;
        display: flex;
    }

    .reshape-hero__content {
        width: 460px;
    }

    .reshape-hero__media-bg {
        margin-left: 50px;
    }
}

/* Vertical Alignment reshape-hero */
.vertical-alignment .reshape-hero__media {
    max-width: 100%;
}

@media (min-width: 60em) {
    .vertical-alignment.reshape-hero {
        display: block;
    }

    .vertical-alignment .reshape-hero__content {
        width: 100%;
    }
}

@include max-screen(sm) {
    .reshape-hero__title-text {
        font-size: 30px;
    }
}