@include max-screen(md) {
    .imagewithoverlay {
        .overlay-caption {
            padding: 25px 20px;
            .caption-wrap {
                span {
                    font-size: 21px;
                }
            }
        }
    }
}
