.vid-container {
    padding-bottom: 56.25%;
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        border: 0;
    }
}
