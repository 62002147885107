.featuredresultstemplate {
    background-color: $blue;
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    border: {
        top: 1px solid $blue;
        right: 1px solid $blue;
        bottom: 2px solid $blue;
        left: 1px solid $blue;
    }

    transition: all 200ms ease-in-out;

    &:hover, &:focus {
        //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        border: {
            top: 1px solid $cyan;
            right: 1px solid $cyan;
            bottom: 2px solid $cyan;
            left: 1px solid $cyan;
        }
        opacity: 0.85;
    }

    .articlebox-image {
        img {
            width: 100%;
        }

        width: 100%;
        position: relative;
        z-index: 0;
        height: 160px;
        overflow: hidden;

        .shapes-overlay {
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: url('../../Images/Base/dist/splash-shapes-alt2.svg') no-repeat -80px -180px; // L/R, UP/DN
        }
    }

    a, a:visited {
        display: block;

        h4 {
            margin: 20px 0;
            line-height: 1.3;
            transition: all 200ms ease-in-out;
        }

        &:hover, &:focus {
            text-decoration: none;

            h4 {
                color: $blue;
            }
        }
    }


    .articlebox-content-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: space-between;
        height: 100%;

        .articlebox-content {
            padding: 30px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .subhead {
                width: 100%;
                height: 18px;

                span {
                    display: block;
                    font-size: 12px;
                    font-weight: $extra-bold;
                    letter-spacing: 1.6px;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                    color: $light-cyan;

                    &.hide {
                        display: none;
                    }

                    &.sponsored {
                        background-color: $blue;
                        padding: 3px 10px;
                        color: $white;
                        font-size: 9px;
                        font-weight: $bold;
                        text-transform: uppercase;
                        letter-spacing: 1.2px;
                        display: inline-block;
                    }
                }
            }

            .text-wrap {
                margin-bottom: auto;
                margin-top: 20px;
                h4 {
                    color: $white;
                    line-height: 1.3;
                    margin-bottom: 20px;
                }

                p {
                    color: $white;
                    line-height: 1.3;
                    margin-bottom: 20px;
                }
            }

            .cta {
                @include cta-button-outline;
                margin: 0;
                border: none;
                padding: 0;
                display: inline;
                color: $light-cyan;

                &:hover, &:focus {
                    margin: 0;
                    border: none;
                    padding: 0;
                    color: $light-cyan;
                }

                &:after {
                    content: url('../../Images/Base/dist/icon-arrow-right-white.svg');
                }
            }
        }
    }
}

@import "featuredresultstemplate-mobile.scss";
