@include max-screen(sm) {
    .progress-bar {

        a, a:visited {

            li {
                border: {
                    top: 1px solid $light-grey;
                    right: 1px solid $light-grey;
                    bottom: 3px solid $black;
                    left: 1px solid $light-grey;
                }

                .step, .step-desc {
                    display: none;
                }
            }

            &.active {
            }

            &.disabled {
                li {

                    .step, .step-desc {
                        display: none;
                    }
                }
            }
        }
    }
}
