.account {
    margin: 50px 0;

    .accordionItem.open button.accordionItemHeading {
        margin-bottom: 0;
    }
    // div[class*="-panel"] { // Contains "-panel"
    //     .panel {
    //         &:first-of-type {
    //             margin: 0 0 20px 0;
    //         }
    //     }
    // }
    .panel {
        background-color: rgba($color: $grey, $alpha: 0.06);
        width: 100%;
        padding: 20px 25px;
        // margin-top: 15px;
        margin: 0 0 20px 0;

        .header.row {
            margin-bottom: 10px;
        }

        .readonly .row {
            margin-bottom: 10px;

            span {
                margin-right: 5px;

                &.company-name {
                    display: block;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        h4 {
            display: inline-block;
            margin-bottom: 15px;
        }

        .info {
            &:after {
                content: url('../../Images/Base/dist/icon-info.svg');
                position: relative;
                top: 2px;
                width: 16px;
                display: inline-block;
                margin-left: 5px;
            }
        }

        form {
            margin-bottom: 20px;
        }

        label {
            font-weight: $bold;
            display: inline-block;

            &.hide-address-notice {
                display: none;
            }
        }

        #member-status {
            margin-right: 5px;
        }

        .bold {
            font-weight: $bold;
        }

        input {
            background-color: $ivory;
            // &[readonly] {
            //     color: $black;
            //     background-color: transparent;
            // }
        }

        select {
            height: 42px; // Match other inputs
            width: 170px; // to allow end date to not fall to second line medical licensure panel
        }

        a.link, a.link:visited {
            @include link;
            font-size: 14px;
            font-weight: $bold;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            display: inline;
            margin-right: 10px;
        }

        span.label {
            font-size: 14px;
            letter-spacing: 1.4px;
            font-weight: $bold;
            text-transform: uppercase;
        }

        .password-description {
            margin-top: 15px;
        }
    }

    .validation-input {
        position: relative;

        &.has-error {
            select, input[type="text"], input[type="date"] {
                border: 1px solid $red;
                color: $red;
            }

            &.unused {
                select, input[type="text"], input[type="date"] {
                    border: none;
                    color: $dark-grey;
                }
            }
        }

        .has-error {
            width: auto;
            max-width: 100%;
            font-size: 12px;
            line-height: 1.3;
            color: $white;
            font-weight: $bold;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            margin: 10px 10px 10px 0;
            position: absolute;
            z-index: 1;
            top: 32px;
            left: 0;
            display: block;
            background-color: $red;
            padding: 4px 5px;

            &.unused {
                color: $red;
                background-color: transparent;
            }
        }
    }

    .edit {
        display: none;
        margin: 20px 0;
        padding: 30px;
        background-color: $white;
        border: 1px solid $grey;
    }

    h4 {
        margin-right: 90px;
    }

    .field-wrap {
        width: 100%;
        margin-bottom: 20px;

        input[type="text"], input[type="tel"], input[type="date"], input[type="password"], select {
            height: 42px;
        }

        input[type="text"] {
            width: 100%;
        }

        input[type="checkbox"] {
            &:nth-of-type(1) {
                margin-left: 0;
            }
        }

        input[type="checkbox"] + label.my-account {
            font-size: 16px;
        }

        input[type="checkbox"] + label, input[type="radio"] + label {
            display: inline;
            font-size: 14px;
            margin-right: 20px;

            my-account {
                font-size: 16px;
            }

            &:first-of-type {
                margin-right: 0;
            }
        }

        select {
            width: 100%;
            //max-width: 400px;
        }

        textarea {
            width: 100%;
            //height: 300px;
        }
        // Credentials Picker (Account)
        .Select-value-icon, .Select-clear {
            font-size: 21px;
            color: $grey;
            margin: 0 8px;
            position: relative;
            top: 2px;
            cursor: pointer;
        }

        .Select-input {
            input {
                width: 150px !important;
                margin: 5px 10px 5px 0;
            }
        }
    }

    .inline-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        select {
            width: 100%;
            min-width: 300px;
        }

        .save-button {
            width: auto;
            margin-left: 20px;
        }
    }

    p.legend {
        text-align: right;
        font-size: 12px;
        color: $grey;
        position: absolute;
        right: 15px;
        top: 0;
    }

    .edit-button {
        @include edit-button($white);
        cursor: pointer;
        z-index: 1;

        &.mobile {
            display: none;
        }

        &.desktop {
            display: flex;
        }
    }

    .view-button {
        @include view-button($white);

        &.mobile {
            display: none;
        }

        &.desktop {
            display: flex;
        }
    }

    .ui-is-disabled {
        .save-button {
            // loading spinner
            &:before {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                border: 3px solid lighten($grey, 25%);
                border-top: 3px solid lighten($light-grey, 15%);
                border-radius: 100%;
                transition: all 200ms ease-in-out;
                animation: loading 2s 0.25s linear infinite;
                display: none;

                @keyframes loading {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(360deg);
                    }
                }
            }

            &.show-spinner {
                background-color: $light-grey;
                border: 1px solid rgba($color: $grey, $alpha: 0.3);
                color: $light-grey;
                pointer-events: none;

                &:before {
                    display: inline;
                }
            }
        }
    }

    .save-button-wrapper {
        margin-top: 20px;
    }

    .save-button {
        @include primary-button;
        //padding: 10px 36px;
        width: 100%;
        //max-width: 400px;
        transition: all 200ms ease-in-out;
        // hide spinner by default
        &:before {
            display: none;
        }

        &.align-right {
            float: right;
            width: auto;
        }

        &.saved {
            background-color: $grey;
            border: 1px solid $grey;
            color: $white;
            pointer-events: none;
            padding: 10px 15px;

            &:before {
                content: url('../../Images/Base/dist/icon-checked.svg');
                margin-right: 10px;
                position: relative;
                top: 2px;
            }
        }

        &.disabled {
            background-color: $light-grey;
            border: 1px solid rgba($color: $grey, $alpha: 0.3);
            color: $white;
            pointer-events: none;
        }
    }

    .delete-button {
        @include secondary-button;
        margin-right: 15px;

        &.align-right {
            float: right;
        }

        &.disabled {
            background-color: $light-grey;
            border: 1px solid rgba($color: $grey, $alpha: 0.3);
            color: $white;
            pointer-events: none;
        }
    }

    .show-button {
        @include secondary-button;
        width: 82px;
    }

    .button {
        @include primary-button;
        display: inline-flex;
    }

    .add-button {
        @include primary-button;
        display: inline-flex;

        &.disabled {
            background-color: rgba($color: $grey, $alpha: 0.3);
            border: 1px solid rgba($color: $grey, $alpha: 0.3);
            color: $white;
            pointer-events: none;

            &:before {
                content: url('../../Images/Base/dist/icon-plus.svg');
                margin-right: 10px;
            }
        }
    }

    .close-button {
        @include secondary-button;
        display: inline-flex;
        position: absolute;
        right: 15px;

        &.mobile {
            display: none;
        }

        &.desktop {
            display: flex;
        }
    }
}

hr.divider {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid $light-grey;
    margin: 20px 0;
    &.mobile {
        display: none;
    }
}

// Boostrap Tooltip Override
.bs-tooltip-right {
    margin-left: 5px;
}

.tooltip-inner {
    font-family: $lato;
    font-size: 12px;
    text-align: left;
    padding: 1rem 0.75rem;
    color: $dark-grey;
    background-color: $white;
    box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.3);
    ul {
        font-size: 12px;
        text-align: left;
        margin: 10px 0;
        padding-left: 20px;
    }
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: white;
}

.warningMessage {
    color: #000000;
}

@import "common-mobile";
