﻿.hero-render {
    padding: 2rem 1rem;
    position: relative;

    @include min-screen(md) {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
    }

    &__title {
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        margin: 0 0 2rem;

        @include min-screen(md) {
            font-size: 120px;
            line-height: 1;
            flex: 1 0 auto;
        }
    }

    &__subtitle {
        display: none;
        color: $blue;
        font-weight: 900;
        font-size: 200px;
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        line-height: normal;
        margin: 0;

        @include min-screen(md) {
            display: block;
        }
    }

    &__img-wrapper {
        text-align: center;

        @include min-screen(md) {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex: 1 1 auto;
        }
    }

    &__img {
        @include min-screen(md) {
            margin-left: 10%;
        }
    }
}
