.breadcrumbs {
    margin: 40px 155px 40px 0px;
    ul {
        @include list-reset;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        li {
            a, a:visited {
                margin: 0 10px;
                font-size: 14px;
                letter-spacing: 1.4px;
                color: $black;
                font-weight: $bold;
                text-transform: uppercase;
            }
            &:before {
                content: "|";
            }
            &:first-child {
                a, a:visited {
                    margin-left: 0;
                }
                &:before {
                    content: " ";
                }
            }
        }
    }
}

@import "breadcrumbs-mobile";