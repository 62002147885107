.article-stats-top-field {
    padding: 0px;
    margin: 0px;
    padding-top: 5%;
    color: #595757;
}

.article-stats {
    padding: 0px;
    margin: 0px;
    color: #595757;
}