.account-name {
    display: block;  
    .name-wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .display-name {
        width: 100%;
        padding: 15px 0;
        position: relative;
        border-bottom: 1px solid $blue;
        span {
            margin-right: 5px;
            font: {
                family: $lato;
                weight: $light;
                size: 36px;
            }
            color: $blue;
            line-height: 39px;
            letter-spacing: 0.3px;
            &#last-name,
            // &#suffix,
            &#credential-1,
            &#credential-2 {
                &:after {
                    content: ",";
                }
            }
        }
    }
    .edit {
        display: none;
        margin: 20px 0;
        padding: 30px;
        border: 1px solid $grey;
        .field-wrap {
            width: 100%;
            margin-bottom: 20px;
            input[type="text"] {
                width: 100%;
            }
            input[type="checkbox"] {
                &:nth-of-type(1) {
                    margin-left: 0;
                }
            }
            select {
                height: 40px;
            }
        }
    }
    .edit-button {
        @include edit-button($light-grey);
        &.mobile {
            display: none;
        }
        &.desktop {
            display: flex;
            margin-top: 10px;
        }
    }
}

@import "name-mobile";