/* CARD LIST */
@media (min-width: 48em) {
    .card-list {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .card-list .card {
        width: 50%;
    }
}

@media (min-width: 64em) {
    .card-list[data-items="4"] .card {
        width: 25%;
    }
}

@media (max-width: 1023px) {
    .card-list .card {
        padding-bottom: 3.5rem;
    }
}

.card-list--rounded .card__media {
    overflow: hidden;
    border-radius: 50%;
}

.card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.card__media {
    -ms-flex-order: -1;
    order: -1;
    background: #999;
    width: 216px;
    height: 216px;
    overflow: hidden;
}

.card__media-img {
    transition: all 0.3s ease-in-out;
    width: auto !important;
    max-width: 500px;
    height: 216px !important;
    transform: translateX(-25%);
}

.card__media,
.card__header,
.card__content {
    max-width: 216px;
}
