@include max-screen(lg) {
    .account {
        .panel {
            .taxonomy-list {
                column-count: 3;
            }
        }
    }
}

@include max-screen(md) {
    .account {
        .save-button {
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
            &.align-right {
                width: 100%;
            }
        }
        .delete-button {
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
        }
        .panel {
            input, select {
                margin-bottom: 15px;
            }
            input[type="text"], input[type="password"], select {
                width: 100%;
            }
            .taxonomy-list {
                column-count: 2;
            }
        }
        .edit .inline-wrap {
            select {
                min-width: 150px;
            }
        }
        .edit .taxonomy-list-form {
            column-count: 2;
        }
    }
    hr.divider {
        margin: 30px 0 35px 0;
        &.mobile {
            display: block;
        }
    }
}

@include max-screen(sm) {
    .account {
        margin-top: 50px;
        .panel {
            .taxonomy-list {
                column-count: 1;
            }
        }
        .edit .inline-wrap {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            select {
                min-width: 200px;
            }
            .save-button {
                margin: 0;
            }
        }
        .edit .taxonomy-list-form {
            column-count: 1;
            margin-bottom: 20px;
        }
        .save-button {
            &.align-right {
                float: none;
            }
        }
        .delete-button {
            &.align-right {
                float: none;
            }
        }
        .edit-button {
            &.desktop {
                display: none;
            }
            &.mobile {
                display: inline-flex;
                width: auto;
                padding: 5px 10px;
                &:before {
                    margin: 0;
                }
            }
        }
        .view-button {
            &.desktop {
                display: none;
            }
            &.mobile {
                display: inline-flex;
                width: auto;
                padding: 5px 10px;
                &:before {
                    margin: 0;
                }
            }
        }
        .close-button {
            &.desktop {
                display: none;
            }
            &.mobile {
                display: inline-flex;
                width: auto;
                padding: 5px 10px;
                &:before {
                    margin: 0;
                }
            }
        }
    }
}

@media (max-width: 1399px) {
    .panel {
        .edit .col-md-auto {
        padding-bottom: 20px;
        }
    }
}