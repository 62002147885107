.eventslisting {
    margin: 50px 0 0 0;

    .articlebox-image {
        margin-bottom: 30px;

        img {
            width: 100%;
        }

        width: 100%;
        //height: 100%;
        background-color: transparent;
        position: relative;
        z-index: 0;

        .angle-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-155deg, transparent 0%, transparent 83%, white 83%, white 100%);
        }

        .gradient-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-125deg, transparent 0%, transparent 40%, $blue 100%);
        }
    }

    .articlebox-content-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .event-date {
            margin-right: 20px;
            min-width: 100px;
            .month {
                display: block;
                font-size: 12px;
                font-weight: $bold;
                letter-spacing: 1.7px;
                line-height: 1;
                text-transform: uppercase;
                color: $black;
            }
            .day {
                display: block;
                font-size: 80px;
                font-weight: $light;
                line-height: 1;
                letter-spacing: 0;
                color: $black;
                position: relative;
                left: -5px;

                &.multiple {
                    font-size: 48px;
                }
            }
        }

        .articlebox-content {
            width: 100%;
            min-height: 200px;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            h4 {
                line-height: 1.2;
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 0;
            }

            .subhead {
                width: 100%;
                height: 18px;
                margin-bottom: 15px;

                span {
                    display: block;
                    font-size: 12px;
                    font-weight: $extra-bold;
                    letter-spacing: 1.6px;
                    text-transform: uppercase;
                    color: $blue;

                    &.hide {
                        display: none;
                    }
                }
            }
        }
    }

    .cta {
        @include cta-button-underline;
        //margin-top: auto;
        margin-bottom: 100px;
    }

    .CoveoResultList {
        margin: 0 !important;
    }
}

@import "eventslisting-mobile";
