.coi {
    &.container {
        margin-top: 30px;
        margin-bottom: 80px;

        h1 {
            margin-bottom: 30px;
        }

        li {
            padding-top: 5px;
            padding-bottom: 5px;

            a {
                font-size: 17px;
            }
        }
    }
}



@import "conflictofinterestlanding-mobile";