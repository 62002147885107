﻿
.coupon-panel {
    background-color: rgba(151,151,151,0.06);
    width: 100%;
    padding: 20px 25px;
    margin: 0 0 20px 0;
    font-weight: bold;

    .container {
        .row {
            background-color: white !important;
            font-weight: normal !important;

            .col-md-sm {
                margin-left: 5px !important;               
            }
        }

        margin-top: 15px;
    }
}
