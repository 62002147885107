@include max-screen(lg) {
    .eventslisting {
        .articlebox-content-wrap {
            .articlebox-content {
                min-height: 225px;
            }
        }
    }
}

@include max-screen(md) {
    .eventslisting {
        .articlebox-content-wrap {
            .articlebox-content {
                min-height: 0;
            }
        }
    }
}