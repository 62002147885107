.contentFeed {
    row-gap: 30px;
    padding-bottom: 60px;
}

.contentFeedItemWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.contentFeedItem {
    margin: 24px 9px 24px 9px;
    padding: 36px;
    border: 1px solid #C5C5C5;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contentFeedTitle {
    color: #000;
    display: block;
    font-weight: 600;
    font-size: clamp(20px, 3vw, 28px);
    line-height: 1.2;
    margin-bottom: 16px;
}

.contentFeedLink {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.contentFeedTitle:hover {
    color: #333;
}

.contentFeedTag {
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: .16em;
    text-decoration-color: #574f9a;
    margin-top: auto;
    margin-bottom: auto;
}

.contentFeedItem {
    .contentFeedImage {
        min-width: 100%;
        border-radius: 4px;
        margin-top: auto;
        aspect-ratio: 16 / 9;
    }
}

.contentFeedItem iframe {
    min-width: 100%;
    border-radius: 4px;
    margin-top: auto;
    vertical-align: middle;
    border-style: none;
    overflow-clip-margin: content-box;
    overflow: clip;
    aspect-ratio: 16 / 9;
    max-height: 54%;
}

.contentFeedDescription {
    a {
        transition: none;

        &:hover {
            color: inherit;
            text-decoration: none;
        }
    }
}

@media (max-width: 1189px) {
    .contentFeedItem {
        iframe {
            max-height: 67%;
        }
    }
}

@media (max-width: 768px) {
    .contentFeed {
        flex-direction: column;
    }

    .contentFeedItem {
        min-width: 100%;
    }
}