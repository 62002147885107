.submit-eventwrap {
    .intro {
        margin-top: 50px;
    }

    .divider {
        border-top: 1px solid $light-grey;
        width: 100%;
        height: 1px;
        margin: 0 15px 50px 15px;
    }

    h2 {
        margin-bottom: 30px;
    }

    .submit-eventdetails, .submit-contactdetails {
        margin-bottom: 50px;

        input[type="text"], textarea, select {
            width: 100%;
        }

        .field-wrap {
            margin: 0 30px 20px 0;
        }
    }

    .event-submission-errors {
        color: red;
        margin-bottom: 20px;
        display: none;
    }

    .event-submission-required-label:after {
        content: "*";
        color: red;
    }
}
.submit-buttonwrap {
    margin: 0 0 100px 0;

    .event-submission-processing {
        margin-bottom: 20px;
        display: none;
    }

    button.disabled {
        @include primary-button;
        background-color: $light-grey;
        border: 1px solid rgba($color: $grey, $alpha: 0.3);
        color: $light-grey;
        pointer-events: none;

        // loading spinner
        &:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border: 3px solid lighten($grey, 25%);
            border-top: 3px solid lighten($light-grey, 15%);
            border-radius: 100%;
            transition: all 200ms ease-in-out;
            animation: loading 2s 0.25s linear infinite;
            display: inline;

            @keyframes loading {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(360deg);
                }
            }
        }
    }


    input[type="submit"][disabled] {
        @include primary-button;
        background-color: $light-grey;
        border: 1px solid rgba($color: $grey, $alpha: 0.3);
        color: $grey;
        pointer-events: none;
    }

    input[type="submit"] {
        @include primary-button;
    }
}

.eventsubmit {
    margin: 50px 0;
    background-color: $light-grey;
    padding: 30px;

    p {
        margin-bottom: 0;
    }

    .cta {
        @include primary-button;
    }
}

.field-validation-error {
    color: red;
}

@import "eventsubmit-mobile";
