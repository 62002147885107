.notification-dropdown {
    transition: height 0.5s ease;

    ul {
        overflow-y: scroll;
        max-height: 200px;

        li {
            height: auto;
        }
    }

    .notification-item {
        position: relative;
        transition: width 0.5s ease;

        &.is-pending-delete {
            .notif-swiper {
                width: 100%;
            }
        }
    }

    .notif-swiper {
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 100%;
        background-color: $grey;
        transition: width 0.5s ease;
    }

    .utility-notifications-button{
        &.active {
            transform: rotate(180deg)
        }
    } 
    
    .utility-notifications-icon {
        box-sizing: border-box;
        position: relative;
        display: block;
        width: 30px;
        height: 30px;
        border: 2px solid black;
        border-radius: 100px;
        font-size: 0;

        &::after {
            content: "";
            display: block;
            box-sizing: border-box;
            position: absolute;
            width: 10px;
            height: 10px;
            border-bottom: 2px solid;
            border-right: 2px solid;
            left: 50%;
            top: calc(50% - 2px);
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
}
