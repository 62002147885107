﻿.membereducation {
    background-color: $lighter-grey;
    padding: 50px 0;
    margin: 50px 0;

    ul {
        @include list-reset;
        margin-bottom: 50px;

        li {
            margin: 10px 0px;
        }
    }

    span {
        display: block;
        margin-bottom: 20px;
    }

    .link {
        @include link;
        border-left: 0px solid transparent;
        padding: 0 2px 2px 0px;
    }

    .cta {
        @include cta-button-underline;
        display: inline-block;
    }
}
