﻿.donor_list {
    margin-top: -50px;
    margin-left: 80px;

    ul {
        padding: 0;
        margin: 0;
        list-style: none !important;     
        li
        {
            font-size:20px;
            color:black;
        }
    }
}

.empty_donor_list {
    margin-top: -50px;
    margin-left: 325px;
    color: red;
    font-size:20px;
}

@import "_faerindividualdonor-mobile";