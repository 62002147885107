@mixin animate($name, $duration, $iteration, $direction, $timing) {
    -webkit-animation-duration: $duration;
       -moz-animation-duration: $duration;
         -o-animation-duration: $duration;
            animation-duration: $duration;

    -webkit-animation-fill-mode: $direction;
       -moz-animation-fill-mode: $direction;
         -o-animation-fill-mode: $direction;
            animation-fill-mode: $direction;

    -webkit-animation-iteration-count: $iteration;
       -moz-animation-iteration-count: $iteration;
         -o-animation-iteration-count: $iteration;
            animation-iteration-count: $iteration;

    -webkit-animation-timing-function: $timing;
       -moz-animation-timing-function: $timing;
         -o-animation-timing-function: $timing;
            animation-timing-function: $timing;

    -webkit-animation-name: $name;
       -moz-animation-name: $name;
         -o-animation-name: $name;
            animation-name: $name;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @-o-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@include keyframes(placeholderShimmer) {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

@mixin placeholderShimmer {
    @include animate(placeholderShimmer, 1s, infinite, forward, linear);
}

.shimmer {
    background: #ced4da;
    background-image: linear-gradient(to right, #ced4da 0%, #ededed 20%, #ced4da 40%, #ced4da 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    @include placeholderShimmer;
}

box.shimmer, .lineDivShimmer {
    display: table-cell;
    vertical-align: middle;
    width: auto;
}

box.shimmer {
    height: 100px;
    width: 15%;
}

div.shimmerContainer {
    display: table;
    width: 100%;
    padding-bottom: 20px;
}

div.lineDivShimmer {
    display: inline-flex;
    flex-direction: column;
    margin-left: 25px;
    margin-top: 15px;
    width: 90%;
    vertical-align: top;
}

lines.shimmer {
    height: 10px;
    margin-top: 10px;
    width: 100%;
}

photo.shimmer {
    display: block !important;
    width: 325px;
    height: 100px;
    margin-top: 15px;
}