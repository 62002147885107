@include max-screen(md) {
    .eventmeetingdetail {
        margin-bottom: 50px;
        .featuredevent-details {
            .date {
                margin-right: 50px;
            }
            .event {

                .name {
                    font-size: 28px;
                }
            }
        }
    }

    .eventcontactdetail {
        padding: 0;
        background-color: $white;
        margin-top: 0;
        margin-bottom: 100px;
    }
}

@include max-screen(sm) {
    .eventmeetingdetail {
        .featuredevent-details {
            margin: 30px 0;

            .date {
                display: none;
            }

            .event {
                .tag {
                    margin-bottom: 10px;
                }

                .name {
                    font-size: 28px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
