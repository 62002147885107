@include max-screen(md) {
    .education {
        a, a:visited {
            display: block;
            margin-bottom: 30px;
        }
        .product-box {
            display: block;
        }
        .cta-wrap {                                                                                                                                                                                                                                                                                                                                                                                                                                                               
            a, a:visited {
                margin: 20px 15px 20px 0;
                &:hover, &:focus {
                    margin: 20px 15px 20px 0;
                }
            }
        }
    }
}
