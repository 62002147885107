@include max-screen(md) {
    .eventsplash {
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: $blue;
    }

    .eventsplash-callout {
        position: initial;

        .content {
            padding: 30px 25px;

            a {
                transition: none;
                color: $white;
            }

            a:hover {
                text-decoration-color: white;
                -webkit-text-decoration-color: white;
            }

            p {
                margin-bottom: 10px;
            }

            .cta {
                justify-content: flex-start;
                align-items: flex-start;

                a, a:visited {
                    margin: 10px 0 0 0;
                }
            }
        }
    }
}
