/* Time To Plugin basic styles width some adjustment - spacings, backgrounds and colors */
figure, figcaption {
    display: block;
}

.transition {
    -webkit-transition: top 400ms linear;
    -moz-transition: top 400ms linear;
    -ms-transition: top 400ms linear;
    -o-transition: top 400ms linear;
    transition: top 400ms linear;
}

.timeTo {
    font-family: Tahoma, Verdana, Aial, sans-serif;
    font-size: 28px;
    line-height: 107%;
    font-weight: bold;
    height: 32px;
}

.timeTo span {
    vertical-align: top;
    font-size: 16px;
}

.timeTo.timeTo-white div {
    color: #fff;
}

.timeTo.timeTo-black div {
    color: white;
    background: #45484d; /* Old browsers */
    background: -moz-linear-gradient(top, #45484d 0%, #000000 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#45484d), color-stop(100%,#000000)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #45484d 0%,#000000 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #45484d 0%,#000000 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #45484d 0%,#000000 100%); /* IE10+ */
    background: linear-gradient(to bottom, #45484d 0%,#000000 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45484d', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

.timeTo.timeTo-black .timeTo-alert {
    background: #a74444; /* Old browsers */
    background: -moz-linear-gradient(top, #a74444 0%, #3f0000 67%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#a74444), color-stop(67%,#3f0000)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #a74444 0%,#3f0000 67%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #a74444 0%,#3f0000 67%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #a74444 0%,#3f0000 67%); /* IE10+ */
    background: linear-gradient(to bottom, #a74444 0%,#3f0000 67%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a74444', endColorstr='#3f0000',GradientType=0 ); /* IE6-9 */
}

.timeTo.timeTo-white .timeTo-alert {
    color: #F3C300;
}

.timeTo figure {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.timeTo figcaption {
    text-align: center;
    /*font-size: 12px;*/
    line-height: 80%;
    font-weight: normal;
    color: #888;
}

.timeTo div {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 11px;
    height: 20px;
    overflow: hidden;
}

.timeTo div.first {
}

.timeTo ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    left: 3px;
}

.timeTo ul li {
    margin: 0;
    padding: 1px 1px;
    list-style: none;
    line-height: 1;
    font-size: 1rem;
}

/* TimeTo Plugin Custom Styles to hide hours */
#countdown > div:nth-child(1),
#countdown > div:nth-child(2),
#countdown > span:nth-child(3) {
    display: none;
}

/* TimeTo Plugin Custom Styles to hide minutes if the time is less then 60 seconds */
#countdown.hide-minutes > div:nth-child(4),
#countdown.hide-minutes > div:nth-child(5),
#countdown.hide-minutes > span:nth-child(6) {
    display: none;
}

/* Sticky Advertisement Wrapper and TimeTo Plugin Custom */
.sticky-ad-container {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    background: rgba(10, 25, 128, .9);
    color: #fff;
    text-align: center;
}

.sticky-ad-content {
    position: relative;
}

.sticky-ad-content img {
    display: block;
    width: 728px;
    max-width: 100%;
    margin: 50px auto 10px;
}

@media(max-width: 730px) {
    .sticky-ad-content img {
        width: 300px;
    }
}

#countdown {
    position: absolute;
    top: 0;
    right: 40px;
}

#close {
    position: absolute;
    top: 5px;
    right: 0;
    background: none;
    border: 0;
    color: #fff;
}

@media(min-width: 1200px) {
    .sticky-ad-container {
        img {
            margin: 10px auto;
        }
    }

    .sticky-ad-content {
        padding: 0 100px;
    }

    #lhnHocButton {
        bottom: 0 !important;
    }
}
