@include max-screen(md) {
    .CoveoSearchInterface .coveo-facet-column {
        display: none !important;
    }

    .accordion-wrap {
        &.mobile {
            display: block;

            .accordionItem {
                .accordionItemHeading {
                    font: {
                        family: $lato;
                        weight: $bold;
                        size: 24px;
                    }                 
                    border-bottom: 1px solid $light-grey;  
                    margin: 10px 0;
                }
            }
        }
    }
}
