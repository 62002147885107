.newsrollup {
    margin: 50px 0;

    h2 {
        margin-bottom: 30px;
    }

    .articlebox-image {
        margin-bottom: 30px;

        img {
            width: 100%;
        }

        width: 100%;
        background-color: transparent;
        position: relative;
        z-index: 0;
        height: 414px; // Match Article List Height
        overflow: hidden;

        .gradient-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-70deg, transparent 0%, transparent 50%, $light-purple 85%);
        }

        .shapes-overlay {
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: url('../../Images/Base/dist/splash-shapes.svg') no-repeat -590px -260px; // L/R, UP/DN
        }
        /*.angle-overlay {
            position: absolute;
            z-index: 3;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(-155deg, transparent 0%, transparent 89%, white 89%, white 100%);
        }*/
        /* Angle can be adjusted by left side and right side's vw's. They should add up to 100vw plus 1px. */
        .left-side {
            border-left: calc(0vw + 1px) solid transparent;
            border-bottom: 120px solid $white;
            position: absolute;
            z-index: 2;
            bottom: 0;
            width: 0;
            height: 0;
            opacity: 1;
        }

        .right-side {
            border-right: 15vw solid transparent;
            /* right: 0; */
            left: 0;
            border-bottom: 120px solid #fff;
            position: absolute;
            z-index: 2;
            bottom: 0;
            width: 0;
            height: 0;
            opacity: 1;
        }
    }

    .articlebox-content {
        width: 100%;
        display: flex;
        flex-direction: column;

        h4 {
            line-height: 1.2;
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 0;
        }

        .subhead {
            width: 100%;
            height: 18px;
            margin-bottom: 15px;

            span {
                display: block;
                font-size: 12px;
                font-weight: $extra-bold;
                letter-spacing: 1.6px;
                text-transform: uppercase;
                color: $blue;

                &.hide {
                    display: none;
                }
            }
        }
    }

    .article-list-wrap {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .article-list {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            a, a:visited {
                display: block;

                &:hover, &:focus {
                    text-decoration: none;

                    h4 {
                        text-decoration: underline;
                    }
                }
            }

            .article-item-wrap {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                min-height: 100px;
                padding: 14px 0px;

                .article-image {
                    position: relative;
                    width: 170px;
                    display: block;
                    margin-right: 35px;
                    margin-left: 20px;

                    img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                    }

                    .sponsored-banner {
                        display: none;
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        background-color: $bright-green;
                        padding: 3px 5px;
                        color: $white;
                        font-size: 9px;
                        font-weight: $bold;
                        text-transform: uppercase;
                        letter-spacing: 1.2px;
                        text-align: center;
                    }

                    &.sponsored {
                        .sponsored-banner {
                            display: inline-block;
                        }
                    }
                }

                .article-details {
                    width: 210px;

                    .date {
                        display: block;
                        font-size: 12px;
                        font-weight: $bold;
                        color: $blue;
                        text-transform: uppercase;
                        letter-spacing: 1.7px;
                        margin-bottom: 3px;
                    }

                    $font-size: 18px;
                    $line-height: 1.2;
                    $lines-to-show: 3;

                    h4 {
                        color: $black;
                        display: block;
                        display: -webkit-box;
                        font-size: $font-size;
                        font-weight: $bold;
                        height: $font-size*$line-height*$lines-to-show;
                        -webkit-line-clamp: $lines-to-show;
                        line-height: $line-height;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        /* autoprefixer:off */
                        -webkit-box-orient: vertical;
                        /* autoprefixer: on */
                    }
                }
            }
        }

        .cta {
            @include cta-button-underline;
            justify-content: flex-end;
        }
    }
}

@import "newsrollup-mobile";