.coi-form {
    textarea {
        height: 10em;
        width: 50em;
        margin-top: 5px;
    }

    .panel {
        background-color: rgba($color: $grey, $alpha: 0.06);
        width: 100%;
        padding: 20px 25px;
        margin: 0 0 20px 0;
    }

    .coi-frame {
        margin: 20px 0;
        padding: 30px;
        background-color: #fff;
        border: 1px solid #979797;

        .read-only {
            .question-wrapper {
                .question-label {
                    p {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .question-wrapper {
            padding: 10px;
            margin: 20px;

            .description-label {
                margin-top: 20px;
                color: black;
            }

            .answer {
                font-style: italic;
            }

            .question-label {
                p {
                    margin-bottom: 20px;
                    font-size: 17px;
                    color: black;
                }

                &.signed-date {
                    font-style: italic;
                    font-size: 20px;
                }
            }
        }

        .save-button {
            margin-top: 30px;
            width: 20%;
        }
    }
}

@import "conflictofinterestform-mobile";