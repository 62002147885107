.article-details--content h2 {
    font-size: 42px;
    margin-bottom: 2rem;
}

.article-details--summary {
    margin: 30px 0px;
}

.row.article-details--two-columns {
    margin: 1rem -15px;
}

.article-details--two-columns .rt-container {
    padding: 2rem 0 0;
    font-size: 21px;
    line-height: 42px;
    max-width: 580px;
}

@include min-screen(md) {
    .row.article-details--two-columns {
        margin: 1rem -15px 3rem;
    }

    .article-details--two-columns .rt-container {
        padding: 2rem 2rem 0;
    }
}

.article-details--two-columns .rt-container p {
    font-size: 21px;
    line-height: 42px;
}

.general-article {
    h3 {
        margin: 30px 0px;
    }

    p {
        margin: 24px 0px;
    }

    ul {
        margin: 0 0 42px 0px;

        li {
            margin-bottom: 20px;

            &:empty {
                margin: 0 !important;
                padding: 0 !important;
            }
        }
    }
}

@import "micrositearticle-mobile";